import Blocks from "emg-ui-kit/components/Blocks";
import Select from "emg-ui-kit/components/Select";
import TextField from "emg-ui-kit/components/TextField";
import { Field, FormikProvider, useFormik } from "formik";
import React from "react";

import ColorPicker from "../../common/ColorPicker";
import { FormProps } from "../../common/models";
import OrderSavingButtons from "../../common/OrderSavingButtons";
import { chartColors } from "../../common/texts";
import { convertToSelectOptions } from "../../common/utils";
import yup from "../../common/yup";
import Form from "../Form";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  getDeepValidationProps,
  getValidationProps,
  MAX_TIMING,
} from "../util";
import { THEMES, THEMES_FULL } from "./constants";

type Item = {
  title: string;
  lastValue: string;
  delta: string;
  values: string;
  color: string;
};

function createItem(index: number): Item {
  return {
    title: "",
    lastValue: "",
    delta: "",
    values: "",
    color: chartColors[index],
  };
}

function initItems(count = 2) {
  return Array.from(Array(count), (_, idx) => createItem(idx));
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    name: (initialFormData?.name ?? "") as string,
    theme: (initialFormData?.theme?.id in THEMES
      ? initialFormData?.theme?.id
      : "") as string,
    leftCaption: (initialFormData?.leftCaption ?? "") as string,
    rightCaption: (initialFormData?.rightCaption ?? "") as string,
    source: (initialFormData?.source ?? "") as string,
    timing: (initialFormData?.timing ?? 30) as number,
    items: (initialFormData?.blocks ?? initItems()) as Item[],
  };
}

type Values = ReturnType<typeof getInitialValues>;

function prepareData(values: Values) {
  return {
    theme: THEMES_FULL[values.theme],
    name: values.name,
    leftCaption: values.leftCaption,
    rightCaption: values.rightCaption,
    source: values.source,
    timing: values.timing,
    blocks: values.items,
  };
}

function prepareDataForPreview(values: Values) {
  const previewData = prepareData(values);
  const separator = "|";
  previewData.blocks = previewData.blocks.map((block) => ({
    ...block,
    values: block.values.split(separator).reduce((acc, val, idx) => {
      const isFirst = idx === 0;
      const isNotCorrect = !val.length || isNaN(+val);
      return `${acc}${isFirst ? "" : separator}${isNotCorrect ? 0 : val}`;
    }, ""),
  }));
  return previewData;
}

const validationSchema = yup.object().shape({
  name: yup.string().matches(CLIP_NAME_REGEXP),
  theme: yup.string().required(),
  timing: yup.number().positive().max(MAX_TIMING),
  items: yup.array().of(
    yup.object().shape({
      title: yup.string().required(),
      values: yup.string().matches(/^(-?\d+(\.\d+)?\|)+(-?\d+(\.\d+)?)$/),
      color: yup.string().required(),
    })
  ),
});

function ChartLinearDoubleForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validationSchema,
  });

  usePreview(
    channel,
    template,
    formik.values.timing,
    formik.values,
    prepareDataForPreview
  );

  const buttonProps = {
    isValid: formik.isValid,
    isSubmitting: formik.isSubmitting,
    prepareData,
    values: formik.values,
    onSubmit,
    onSaveDraft,
    onDeleteDraft
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="name"
          {...getValidationProps("name", formik.touched, formik.errors)}
        />
        <Field
          as={Select}
          name="theme"
          label="Тема оформления"
          options={convertToSelectOptions(THEMES)}
          {...getValidationProps("theme", formik.touched, formik.errors)}
          required
        />
        <Field as={TextField} label="Нижняя подпись слева" name="leftCaption" />
        <Field
          as={TextField}
          label="Нижняя подпись справа"
          name="rightCaption"
        />
        <Field as={TextField} label="Источник" name="source" />
        <Field
          as={TextField}
          label="Хронометраж (сек)"
          name="timing"
          type="number"
          onChange={(event: any) =>
            formik.setFieldValue("timing", +event.target.value)
          }
          {...getValidationProps("timing", formik.touched, formik.errors)}
          required
        />
        <Blocks
          items={formik.values.items}
          updateItems={(items) => formik.setFieldValue("items", items)}
          defaultItemConstructor={createItem}
          blockTitle="График"
        >
          {(item, index, updateItem) => (
            <>
              <Field
                as={TextField}
                placeholder="Заголовок"
                name={`items[${index}].title`}
                {...getDeepValidationProps(
                  `items[${index}].title`,
                  formik.touched,
                  formik.errors
                )}
              />
              <Field
                as={TextField}
                placeholder="Последнее значение"
                name={`items[${index}].lastValue`}
              />
              <Field
                as={TextField}
                placeholder="Дельта"
                name={`items[${index}].delta`}
              />
              <Field
                as={TextField}
                placeholder="Значения через символ |"
                name={`items[${index}].values`}
                onChange={(event: any) => {
                  const values = event.target.value;
                  if (/[^\-.|0-9]/.test(values)) return;
                  updateItem({ values });
                }}
                {...getDeepValidationProps(
                  `items[${index}].values`,
                  formik.touched,
                  formik.errors
                )}
              />
              <ColorPicker
                color={item.color}
                updateColor={(color) => updateItem({ color })}
                colors={chartColors}
                label="Цвет"
              />
            </>
          )}
        </Blocks>
        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(ChartLinearDoubleForm);
