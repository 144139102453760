import Blocks from "emg-ui-kit/components/Blocks";
import ImageInput from "emg-ui-kit/components/ImageInput";
import { FormImage } from "emg-ui-kit/components/ImageInput";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { Field, Form, FormikErrors, FormikProvider, useFormik } from "formik";
import React from "react";

import { FormProps } from "../../common/models";
import OrderSavingButtons from "../../common/OrderSavingButtons";
import { useIsDesktop } from "../../common/utils";
import yup from "../../common/yup";
import usePreview from "../usePreview";
import { CLIP_NAME_REGEXP, getValidationProps, IMAGE_TYPES } from "../util";

type Item = {
  image?: FormImage;
};

function createItem(): Item {
  return { image: undefined };
}

function initItems(count = 3) {
  return Array.from(Array(count), createItem);
}

const FIXED_TIMING = 15;
const IMAGE_ASPECT = 1600 / 490;

const itemSchema = yup.object().shape({
  image: yup.mixed().required().aspect(IMAGE_ASPECT),
});
const validationSchema = yup.object().shape({
  clipName: yup.string().matches(CLIP_NAME_REGEXP),
  number: yup
    .string()
    .matches(/^-?\d+(\.\d+)?$/)
    .required(),
  description: yup.string().required(),
  mainImage: yup.mixed().required(),
  items: yup.array().of(itemSchema),
});

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    number: (initialFormData?.number?.toString() ?? "") as string,
    units: (initialFormData?.units ?? "") as string,
    caption: (initialFormData?.caption ?? "") as string,
    description: (initialFormData?.description ?? "") as string,
    source: (initialFormData?.source ?? "") as string,
    mainImage: initialFormData?.mainImage as FormImage | undefined,
    items: (initialFormData?.blocks ?? initItems()) as Item[],
    format: (initialFormData?.format ?? "default") as "default" | "premiere",
  };
}

function prepareData(values: ReturnType<typeof getInitialValues>) {
  const data: Record<string, unknown> = { ...values };
  data.number = +values.number;
  data.blocks = values.items;
  delete data.items;
  return data;
}

function NumberForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validationSchema,
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    isValid,
    isSubmitting,
  } = formik;

  const buttonProps = {
    isSubmitting,
    isValid,
    prepareData,
    values,
    onSaveDraft,
    onDeleteDraft
  }

  usePreview(channel, template, FIXED_TIMING, values, prepareData);

  const isDesktopOrLaptop = useIsDesktop();

  return (
    <FormikProvider value={formik}>
      <Form>
        <TextField
          label="Название ролика"
          name="clipName"
          value={values.clipName}
          onChange={handleChange}
          onBlur={handleBlur}
          {...getValidationProps("clipName", touched, errors)}
        />
        <Field
          as={Select}
          name="format"
          label="Формат"
          options={[
            { id: "default", name: "По умолчанию" },
            { id: "premiere", name: "Premiere" },
          ]}
        />
        <TextField
          label="Цифра"
          name="number"
          value={values.number}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          isValid={!touched.number || !errors.number}
          validationMessage={errors.number}
        />
        <TextField
          label="Единица измерения"
          name="units"
          value={values.units}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <TextField
          label="Подпись"
          name="caption"
          value={values.caption}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <TextArea
          label="Пояснение"
          name="description"
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
          isValid={!touched.description || !errors.description}
          validationMessage={errors.description}
          required
        />
        <TextField
          label="Источник"
          name="source"
          value={values.source}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <ImageInput
          title="Основное фото"
          imageTypes={IMAGE_TYPES}
          image={values.mainImage}
          updateImage={(image) => setFieldValue("mainImage", image)}
          style={isDesktopOrLaptop ? { marginLeft: 210 } : {}}
          isValid={!touched.mainImage || !errors.mainImage}
          validationMessage={errors.mainImage}
        />
        <Blocks
          items={values.items}
          updateItems={(items) => setFieldValue("items", items)}
          defaultItemConstructor={createItem}
          blockTitle="Фотография"
        >
          {(item, index, updateItem) => (
            <ImageInput
              imageTypes={IMAGE_TYPES}
              image={item.image}
              updateImage={(image) => {
                setFieldTouched(`items.${index}.image`);
                updateItem({ image });
              }}
              isValid={
                !touched.items?.[index]?.image ||
                !(errors.items?.[index] as FormikErrors<Item>)?.image
              }
              validationMessage={
                (errors.items?.[index] as FormikErrors<Item>)?.image
              }
              aspect={IMAGE_ASPECT}
            />
          )}
        </Blocks>
        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(NumberForm);
