import Button from "emg-ui-kit/components/Button";
import TextField from "emg-ui-kit/components/TextField";
import Title from "emg-ui-kit/components/Title";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import BackButton from "../../common/BackButton";
import PageContainer from "../../common/PageContainer";
import {
  allTemplates,
  booleanTypes,
  channels,
  covidInfoTypes,
} from "../../common/texts";
import { dateFormatter, useIsDesktop } from "../../common/utils";
import {
  atmoInfoBlockTypes,
  atmoNumberBlockTypes,
  sunriseSunset,
  timeOfDayTypes,
} from "../../order/atmosphere/constants";
import { findIconName } from "../../order/atmosphere/utils";
import { Item } from "../../order/map/types";
import { blockTypes } from "../../order/map/util";
import { SITES } from "../../order/r24/constants";
import { selectIsAdmin, selectUserInfo } from "../../redux/auth/selectors";
import { cancelTaskByIdThunk, getTaskByIdThunk } from "../../redux/tasks";
import { selectTaskById } from "../../redux/tasks/selectors";
import routes from "../../routes";
import StatusBadge from "../StatusBadge";
import styles from "../TaskListPage/TaskList.module.css";
import { useTaskTrackingById } from "../useTasksTracking";
import DownloadButton from "./DownloadButton";
import MapButtonBlock from "./MapButtonBlock";
import VideoPreview from "./VideoPreview";

function InfoRow({ title, value }: { title: string; value: any }) {
  return (
    <div className={styles.row}>
      <div className={styles.infoTitle}>{title}:</div>
      <div
        className={styles.infoDescription}
        style={{ whiteSpace: "pre-line" }}
      >
        {value}
      </div>
    </div>
  );
}

function ColorBox({ color }: { color: string }) {
  return <div style={{ background: color, width: 20, height: 20 }} />;
}

function ImageRow({ title, src }: { title: string; src: string }) {
  return (
    <div>
      <div className={styles.infoTitle}>{title}:</div>
      <img
        alt={title}
        src={src[0] === "u" ? "/" + src : src}
        style={{ objectFit: "contain", maxHeight: 350, maxWidth: "100%" }}
      />
    </div>
  );
}

interface TaskDataViewProps {
  template: string;
  data: Record<string, any>;
}

function TaskDataView({ template, data }: TaskDataViewProps) {
  switch (template) {
    case "geo":
      return (
        <>
          <InfoRow title="Название ролика" value={data.clipName} />
          <InfoRow title="Гео" value={data.geo} />
          <InfoRow title="Хронометраж (сек.)" value={data.timing} />
        </>
      );
    case "titer":
      return (
        <>
          <InfoRow title="Название ролика" value={data.clipName} />
          <InfoRow title="Имя" value={data.name} />

          <InfoRow title="Должность" value={data.position} />
          <InfoRow title="Хронометраж (сек.)" value={data.timing} />
        </>
      );
    case "important_hour":
      return (
        <>
          <InfoRow title="Название ролика" value={data.clipName} />

          <InfoRow title="Заголовок" value={data.title} />
          <InfoRow title="Подзаголовок" value={data.subtitle} />

          <InfoRow title="Оформление" value={data.design} />
          <InfoRow title="Хронометраж (сек.)" value={data.totalTiming} />

          {(data.pages as Record<string, any>[]).map((page, idx) => (
            <details key={idx}>
              <summary>Страница №{idx + 1}</summary>
              <InfoRow title="Текст" value={page.texts.join("\n")} />

              <InfoRow title="Тезис" value={page.thesises.join("\n")} />
            </details>
          ))}
        </>
      );
    case "affiche":
      return (
        <>
          <InfoRow title="Название ролика" value={data.clipName} />
          <InfoRow title="Оформление" value={data.design} />
          <InfoRow title="Заголовок" value={data.title} />
          <InfoRow title="Хронометраж (сек.)" value={data.totalTiming} />
          {(data.blocks as Record<string, any>[]).map((block, idx) => (
            <details key={idx}>
              <summary>Фото блок №{idx + 1}</summary>
              <ImageRow title="Фото" src={block.image} />
              {block.titer && <InfoRow title="Титр" value={block.titer} />}
              {block.source && (
                <InfoRow title="Источник" value={block.source} />
              )}
            </details>
          ))}
        </>
      );
    case "reference":
      return (
        <>
          <InfoRow title="Название ролика" value={data.clipName} />
          <InfoRow title="Тип заголовка" value={data.titleType} />

          <InfoRow title="Источник информации" value={data.source} />
          <InfoRow title="Хронометраж (сек.)" value={data.totalTiming} />

          {data.titles &&
            (data.titles as Record<string, any>[]).map((item, idx) => (
              <details key={idx}>
                <summary>Блок заголовков №{idx + 1}</summary>
                <InfoRow title="Текст" value={item.text} />
              </details>
            ))}
          {data.subtitles &&
            (data.subtitles as Record<string, any>[]).map((item, idx) => (
              <details key={idx}>
                <summary>Блок подзаголовков №{idx + 1}</summary>
                <InfoRow title="Текст" value={item.text} />
              </details>
            ))}

          {(data.textItems as Record<string, any>[]).map((item, idx) => (
            <details key={idx}>
              <summary>Текстовый блок №{idx + 1}</summary>
              <InfoRow title="Текст" value={item.texts.join("\n")} />
            </details>
          ))}
          {(data.blocks as Record<string, any>[]).map((block, idx) => (
            <details key={idx}>
              <summary>Фото блок №{idx + 1}</summary>
              <ImageRow title="Фото" src={block.image} />
              {block.imageSource && (
                <>
                  <InfoRow title="Источник фото" value={block.imageSource} />
                  <InfoRow
                    title="Цвет источника"
                    value={block.imageSourceColor}
                  />
                </>
              )}
            </details>
          ))}
        </>
      );
    case "wheeze":
      return (
        <>
          <InfoRow title="Название ролика" value={data.clipName} />
          <InfoRow title="Цветовая схема" value={data.colorScheme} />
          <InfoRow title="Вид связи" value={data.communicationType} />
          <InfoRow title="Имя" value={data.name} />
          <InfoRow title="Должность" value={data.position} />
          <ImageRow title="Изображение" src={data.image} />
        </>
      );
    case "wheeze_specrep":
      return (
        <>
          <InfoRow title="Название ролика" value={data.clipName} />
          <InfoRow title="Оформление" value={data.design} />
          <InfoRow title="Вид связи" value={data.communicationType} />
          {data.image && <ImageRow title="Изображение" src={data.image} />}
          <InfoRow title="Имя" value={data.name} />
          <InfoRow title="Должность" value={data.position} />
          <InfoRow title="Хронометраж (сек.)" value={data.timing} />
        </>
      );
    case "quote":
      return (
        <>
          <InfoRow title="Название ролика" value={data.clipName} />
          <InfoRow title="Хронометраж (сек.)" value={data.timing} />
          <InfoRow title="Имя" value={data.name} />
          <InfoRow title="Должность" value={data.position} />
          <InfoRow
            title="Цитата"
            value={(data.blocks as Record<string, any>[])
              .map(({ quote }) => quote)
              .join(" ")}
          />
          {data.audio && (
            <InfoRow
              title="Аудиодорожка"
              value={<a href={data.audio}>Аудиофайл</a>}
            />
          )}
          {data.image && <ImageRow title="Изображение" src={data.image} />}
        </>
      );
    case "slideshow":
      return (
        <>
          <InfoRow title="Название ролика" value={data.clipName} />
          <InfoRow title="Хронометраж (сек.)" value={data.totalTiming} />
          {data.comment && <InfoRow title="Комментарий" value={data.comment} />}
          {data.source && <InfoRow title="Источник" value={data.source} />}
          {(data.blocks as Record<string, any>[]).map((block, idx) => (
            <details key={idx}>
              <summary>Блок №{idx + 1}</summary>
              <ImageRow title="Изображение" src={block.image} />
              {block.comment && (
                <InfoRow title="Комментарий" value={block.comment} />
              )}
              {block.source && (
                <InfoRow title="Источник" value={block.source} />
              )}
            </details>
          ))}
        </>
      );
    case "covidinfo": {
      const formatter = new Intl.NumberFormat("ru");
      return (
        <>
          <InfoRow title="Название ролика" value={data.clipName} />
          <InfoRow title="Тип" value={covidInfoTypes[data.type]} />
          <InfoRow title="Источник" value={data.source} />
          <details>
            <summary>Выявлены</summary>
            <InfoRow title="Всего" value={formatter.format(data.cases.total)} />
            <InfoRow
              title="Динамика"
              value={formatter.format(data.cases.delta)}
            />
          </details>
          <details>
            <summary>Выздоровели</summary>
            <InfoRow
              title="Всего"
              value={formatter.format(data.recovered.total)}
            />
            <InfoRow
              title="Динамика"
              value={formatter.format(data.recovered.delta)}
            />
          </details>
          <details>
            <summary>Умерли</summary>
            <InfoRow
              title="Всего"
              value={formatter.format(data.deaths.total)}
            />
            <InfoRow
              title="Динамика"
              value={formatter.format(data.deaths.delta)}
            />
          </details>
        </>
      );
    }
    case "covidglobe":
      return (
        <>
          <InfoRow title="Название ролика" value={data.clipName} />
          <InfoRow
            title="Дата"
            value={new Date(Date.parse(data.date)).toLocaleString()}
          />
          {data.countries.map((country: Record<string, any>, idx: number) => (
            <>
              <details>
                <summary>Страна {idx + 1}</summary>
                <section style={{ marginLeft: "2rem" }}>
                  <InfoRow title="Название" value={country.name} />
                  <Title
                    text="Выявлены"
                    style={{ fontSize: 20, marginBottom: "0.5rem" }}
                  />
                  <InfoRow title="Всего" value={country.cases.total} />
                  <InfoRow title="Динамика" value={country.cases.delta} />
                  <Title
                    text="Умерли"
                    style={{ fontSize: 20, marginBottom: "0.5rem" }}
                  />
                  <InfoRow title="Всего" value={country.deaths.total} />
                  <InfoRow title="Динамика" value={country.deaths.delta} />
                  <Title
                    text="Выздоровели"
                    style={{ fontSize: 20, marginBottom: "0.5rem" }}
                  />
                  <InfoRow title="Всего" value={country.recovered.total} />
                  <InfoRow title="Динамика" value={country.recovered.delta} />
                </section>
              </details>
            </>
          ))}
          <details>
            <summary>Мир</summary>
            <section style={{ marginLeft: "2rem" }}>
              <Title
                text="Выявлены"
                style={{ fontSize: 20, marginBottom: "0.5rem" }}
              />
              <InfoRow title="Всего" value={data.world.cases.total} />
              <InfoRow title="Динамика" value={data.world.cases.delta} />
              <Title
                text="Умерли"
                style={{ fontSize: 20, marginBottom: "0.5rem" }}
              />
              <InfoRow title="Всего" value={data.world.deaths.total} />
              <InfoRow title="Динамика" value={data.world.deaths.delta} />
              <Title
                text="Выздоровели"
                style={{ fontSize: 20, marginBottom: "0.5rem" }}
              />
              <InfoRow title="Всего" value={data.world.recovered.total} />
              <InfoRow title="Динамика" value={data.world.recovered.delta} />
            </section>
          </details>
        </>
      );
    case "atmosphere":
      return (
        <>
          <InfoRow title="Название ролика" value={data.clipName} />
          <InfoRow title="Ручная сборка" value={data.manual ? "Да" : "Нет"} />
          {(data.blocks as Record<string, any>[]).map((block, idx) => (
            <details key={idx}>
              <summary>Блок №{idx + 1}</summary>
              <InfoRow
                title="Тип блока"
                value={atmoNumberBlockTypes[block.blockType]}
              />
              <InfoRow title="Хронометраж (сек.)" value={block.timing} />
              {block.source && (
                <InfoRow title="Источник" value={block.source} />
              )}

              {block.location ? (
                <>
                  <InfoRow title="Локация" value={block.location.name} />
                  <InfoRow title="Погода" value={block.weather} />
                  <InfoRow
                    title="Время суток"
                    value={timeOfDayTypes[block.timeOfDay]}
                  />
                </>
              ) : block.map ? (
                <InfoRow title="2D карта" value={block.map.name} />
              ) : null}

              {(() => {
                switch (block.blockType) {
                  case "text":
                    return (
                      <>
                        <InfoRow
                          title="Первая строка"
                          value={block.firstLine}
                        />
                        {block.secondLine && (
                          <InfoRow
                            title="Вторая строка"
                            value={block.secondLine}
                          />
                        )}
                        {block.thirdLine && (
                          <>
                            <InfoRow
                              title="Третья строка"
                              value={block.thirdLine}
                            />
                            <InfoRow
                              title="Цвет третей строки"
                              value={<ColorBox color={block.thirdLineColor} />}
                            />
                          </>
                        )}
                      </>
                    );
                  case "feelsLike":
                    return (
                      <InfoRow title="Температура" value={block.temperature} />
                    );
                  case "pressure":
                    return <InfoRow title="Давление" value={block.pressure} />;
                  case "forecast":
                    return (
                      <>
                        <InfoRow
                          title="Время прогноза"
                          value={block.forecastTime}
                        />
                        <InfoRow
                          title="Температура"
                          value={block.temperature}
                        />
                        <InfoRow
                          title="Подпись"
                          value={block.forecastDescription}
                        />
                      </>
                    );
                  case "wind":
                    return (
                      <>
                        <InfoRow
                          title="Направление ветра"
                          value={block.windDirection}
                        />
                        <InfoRow
                          title="Скорость ветра"
                          value={block.windSpeed}
                        />
                      </>
                    );
                  default:
                    return null;
                }
              })()}
            </details>
          ))}
        </>
      );
    case "atmosphere_info":
      return (
        <>
          <InfoRow title="Название ролика" value={data.clipName} />
          <InfoRow title="Ручная сборка" value={data.manual ? "Да" : "Нет"} />
          <InfoRow
            title="Включить звук"
            value={data.enableSound ? "Да" : "Нет"}
          />
          {(data.blocks as Record<string, any>[]).map((block, idx) => (
            <details key={idx}>
              <summary>Блок №{idx + 1}</summary>
              <InfoRow
                title="Тип блока"
                value={atmoInfoBlockTypes[block.blockType]}
              />
              <InfoRow title="Хронометраж (сек.)" value={block.timing} />
              {block.source && (
                <InfoRow title="Источник" value={block.source} />
              )}

              {block.location ? (
                <>
                  <InfoRow title="Локация" value={block.location.name} />
                  <InfoRow title="Погода" value={block.weather} />
                  <InfoRow
                    title="Время суток"
                    value={timeOfDayTypes[block.timeOfDay]}
                  />
                  {block.videoEffect && (
                    <InfoRow
                      title="Видеоэффект"
                      value={block.videoEffect.name}
                    />
                  )}
                </>
              ) : block.map ? (
                <InfoRow title="2D карта" value={block.map.name} />
              ) : null}

              {(() => {
                switch (block.blockType) {
                  case "two-lines":
                    return (
                      <>
                        <InfoRow
                          title="Первая строка"
                          value={block.firstLine}
                        />
                        {block.secondLine && (
                          <InfoRow
                            title="Вторая строка"
                            value={block.secondLine}
                          />
                        )}
                        {block.icon && (
                          <InfoRow
                            title="Иконка"
                            value={findIconName(
                              block.icon.split("_")[0],
                              block.icon.split("_")[1]
                            )}
                          />
                        )}
                      </>
                    );
                  case "three-lines":
                    return (
                      <>
                        <InfoRow
                          title="Первая строка"
                          value={block.firstLine}
                        />
                        {block.secondLine && (
                          <InfoRow
                            title="Вторая строка"
                            value={block.secondLine}
                          />
                        )}
                        {block.thirdLine && (
                          <InfoRow
                            title="Третья строка"
                            value={block.thirdLine}
                          />
                        )}
                        {block.icon && (
                          <InfoRow
                            title="Иконка"
                            value={findIconName(
                              block.icon.split("_")[0],
                              block.icon.split("_")[1]
                            )}
                          />
                        )}
                        {block.accent && (
                          <InfoRow title="Акцент" value={block.accent} />
                        )}
                      </>
                    );
                  case "infographics":
                    return <InfoRow title="Название" value={block.clip} />;
                  case "sunrise-sunset":
                    return (
                      <>
                        <InfoRow
                          title="Тип"
                          value={sunriseSunset[block.type]}
                        />
                        <InfoRow title="Время" value={block.time} />
                        {block.additionalField === "one-line" && (
                          <InfoRow
                            title="Первая строка"
                            value={block.firstLine}
                          />
                        )}
                        {block.additionalField === "change" && (
                          <>
                            <InfoRow
                              title="Первая строка"
                              value={block.firstLine}
                            />
                            <InfoRow
                              title="Вторая строка"
                              value={block.secondLine}
                            />
                            <InfoRow
                              title="Третья строка"
                              value={block.thirdLine}
                            />
                          </>
                        )}
                      </>
                    );
                  default:
                    return null;
                }
              })()}
            </details>
          ))}
        </>
      );
    case "meme":
      return (
        <>
          <InfoRow title="Название ролика" value={data.clipName} />
          <InfoRow title="Комментарий" value={data.comment} />
          <ImageRow title="Фотография" src={data.image} />
        </>
      );
    case "number":
      return (
        <>
          <InfoRow title="Название ролика" value={data.clipName} />
          <InfoRow title="Цифра" value={data.number} />
          {data.units && (
            <InfoRow title="Единица измерения" value={data.units} />
          )}
          {data.caption && <InfoRow title="Подпись" value={data.caption} />}
          <InfoRow title="Пояснение" value={data.description} />
          {data.source && <InfoRow title="Источник" value={data.source} />}
          <ImageRow title="Основное фото" src={data.mainImage} />
          {(data.blocks as Record<string, any>[]).map((block, idx) => (
            <details key={idx}>
              <summary>Изображение №{idx + 1}</summary>
              <ImageRow title="Фото" src={block.image} />
            </details>
          ))}
        </>
      );
    case "rslideshow":
      return (
        <>
          <InfoRow title="Тема" value={data.theme.name} />
          <InfoRow title="Хронометраж (сек.)" value={data.totalTiming} />
          {data.title && <InfoRow title="Заголовок" value={data.title} />}
          {data.subtitle && (
            <InfoRow title="Подзаголовок" value={data.subtitle} />
          )}
          {data.source && <InfoRow title="Источник" value={data.source} />}
          {(data.blocks as Record<string, any>[]).map((block, idx) => (
            <details key={idx}>
              <summary>Блок №{idx + 1}</summary>
              <ImageRow title="Изображение" src={block.image} />
            </details>
          ))}
        </>
      );
    case "vipquote":
      return (
        <>
          {data.title && <InfoRow title="Заголовок" value={data.title} />}
          <InfoRow title="Тема" value={data.theme.name} />
          <InfoRow title="Хронометраж (сек.)" value={data.totalTiming} />
          {data.doc && <InfoRow title="Документ" value={data.doc} />}
          {data.source && <InfoRow title="Источник" value={data.source} />}
          <ImageRow title="Фото" src={data.photoUrl} />
          <InfoRow
            title="Цитата"
            value={data.blocks.map((item: any) => item.text).join("\n")}
          />
        </>
      );
    case "statement":
      return (
        <>
          {data.title && <InfoRow title="Заголовок" value={data.title} />}
          <InfoRow title="Тема" value={data.theme.name} />
          <InfoRow title="Хронометраж (сек.)" value={data.totalTiming} />
          {data.doc && <InfoRow title="Документ" value={data.doc} />}
          {data.source && <InfoRow title="Источник" value={data.source} />}
          {data.image && <ImageRow title="Изображение" src={data.image} />}
          {(data.blocks as Record<string, any>[]).map((block, idx) => (
            <details key={idx}>
              <summary>Блок №{idx + 1}</summary>
              <InfoRow title="Цитата" value={block.quote} />
            </details>
          ))}
        </>
      );
    case "miniwheeze":
      return (
        <>
          {data.title && <InfoRow title="Заголовок" value={data.title} />}
          <InfoRow title="Тип фона" value={data.background} />
          <InfoRow title="Автор" value={data.author} />
          {data.position && <InfoRow title="Должность" value={data.position} />}
          {data.image && <ImageRow title="Изображение" src={data.image} />}
        </>
      );
    case "chart_linear":
      return (
        <>
          <InfoRow title="Тема" value={data.theme.name} />
          {data.title && <InfoRow title="Заголовок" value={data.title} />}
          {data.subtitle && (
            <InfoRow title="Подзаголовок" value={data.subtitle} />
          )}
          {data.lastValue && (
            <InfoRow title="Последнее значение" value={data.lastValue} />
          )}
          {data.delta && <InfoRow title="Дельта" value={data.delta} />}
          {data.leftCaption && (
            <InfoRow title="Подпись слева" value={data.leftCaption} />
          )}
          {data.rightCaption && (
            <InfoRow title="Подпись справа" value={data.rightCaption} />
          )}
          {data.source && <InfoRow title="Источник" value={data.source} />}
          <InfoRow title="Хронометраж (сек.)" value={data.timing} />
          {(data.blocks as Record<string, any>[]).map((block, idx) => (
            <details key={idx}>
              <summary>График №{idx + 1}</summary>
              <InfoRow title="Значения" value={block.values} />
              <InfoRow title="Легенда" value={block.legend} />
              <InfoRow title="Цвет" value={<ColorBox color={block.color} />} />
            </details>
          ))}
        </>
      );
    case "chart_linear_double":
      return (
        <>
          <InfoRow title="Тема" value={data.theme.name} />
          {data.leftCaption && (
            <InfoRow title="Подпись слева" value={data.leftCaption} />
          )}
          {data.rightCaption && (
            <InfoRow title="Подпись справа" value={data.rightCaption} />
          )}
          {data.source && <InfoRow title="Источник" value={data.source} />}
          <InfoRow title="Хронометраж (сек.)" value={data.timing} />
          {(data.blocks as Record<string, any>[]).map((block, idx) => (
            <details key={idx}>
              <summary>График №{idx + 1}</summary>
              <InfoRow title="Заголовок" value={block.title} />
              <InfoRow title="Последнее значение" value={block.lastValue} />
              <InfoRow title="Дельта" value={block.delta} />
              <InfoRow title="Значения" value={block.values} />
              <InfoRow title="Цвет" value={<ColorBox color={block.color} />} />
            </details>
          ))}
        </>
      );
    case "chart_pie":
      return (
        <>
          <InfoRow title="Тема" value={data.theme.name} />
          {data.title && <InfoRow title="Заголовок" value={data.title} />}
          {data.subtitle && (
            <InfoRow title="Подзаголовок" value={data.subtitle} />
          )}
          {data.source && <InfoRow title="Источник" value={data.source} />}
          <InfoRow title="Хронометраж (сек.)" value={data.timing} />
          {(data.blocks as Record<string, any>[]).map((block, idx) => (
            <details key={idx}>
              <summary>Блок №{idx + 1}</summary>
              <InfoRow title="Значение" value={block.value} />
              <InfoRow title="Легенда" value={block.legend} />
              <InfoRow title="Цвет" value={<ColorBox color={block.color} />} />
            </details>
          ))}
        </>
      );
    case "chart_pie_large":
      return (
        <>
          <InfoRow title="Тема" value={data.theme.name} />
          {data.title && <InfoRow title="Заголовок" value={data.title} />}
          {data.subtitle && (
            <InfoRow title="Подзаголовок" value={data.subtitle} />
          )}
          {data.source && <InfoRow title="Источник" value={data.source} />}
          <InfoRow title="Хронометраж (сек.)" value={data.timing} />
          <InfoRow
            title="Центральное значение"
            value={data.hasCentralValue ? "Да" : "Нет"}
          />
          {(data.blocks as Record<string, any>[]).map((block, idx) => (
            <details key={idx}>
              <summary>Блок №{idx + 1}</summary>
              <InfoRow title="Значение" value={block.value} />
              <InfoRow title="Легенда" value={block.legend} />
              <InfoRow title="Цвет" value={<ColorBox color={block.color} />} />
            </details>
          ))}
        </>
      );
    case "chart_columns":
      return (
        <>
          <InfoRow title="Тема" value={data.theme.name} />
          {data.title && <InfoRow title="Заголовок" value={data.title} />}
          {data.subtitle && (
            <InfoRow title="Подзаголовок" value={data.subtitle} />
          )}
          {data.leftCaption && (
            <InfoRow title="Подпись слева" value={data.leftCaption} />
          )}
          {data.rightCaption && (
            <InfoRow title="Подпись справа" value={data.rightCaption} />
          )}
          {data.source && <InfoRow title="Источник" value={data.source} />}
          <InfoRow title="Хронометраж (сек.)" value={data.timing} />
          {(data.legend as any[]).map((legend, idx) => (
            <>
              <InfoRow title={`Легенда ${idx + 1}`} value={legend.title} />
              <InfoRow
                title={`Цвет  ${idx + 1}`}
                value={<ColorBox color={legend.color} />}
              />
            </>
          ))}
          {(data.blocks as Record<string, any>[]).map((block, idx) => (
            <details key={idx}>
              <summary>Блок №{idx + 1}</summary>
              <InfoRow title="Название" value={block.title} />
              {(block.values as any[]).map((value, idx) => (
                <InfoRow title={`Значение ${idx + 1}`} value={value} />
              ))}
            </details>
          ))}
        </>
      );
    case "chart_rows":
      return (
        <>
          <InfoRow title="Тема" value={data.theme.name} />
          {data.title && <InfoRow title="Заголовок" value={data.title} />}
          {data.subtitle && (
            <InfoRow title="Подзаголовок" value={data.subtitle} />
          )}
          {data.source && <InfoRow title="Источник" value={data.source} />}
          <InfoRow title="Хронометраж (сек.)" value={data.timing} />
          {(data.legend as any[]).map((legend, idx) => (
            <>
              <InfoRow title={`Легенда ${idx + 1}`} value={legend.title} />
              <InfoRow
                title={`Цвет  ${idx + 1}`}
                value={<ColorBox color={legend.color} />}
              />
            </>
          ))}
          {(data.blocks as Record<string, any>[]).map((block, idx) => (
            <details key={idx}>
              <summary>Блок №{idx + 1}</summary>
              <InfoRow title="Название" value={block.title} />
              {(block.values as any[]).map((value, idx) => (
                <InfoRow title={`Значение ${idx + 1}`} value={value} />
              ))}
            </details>
          ))}
        </>
      );
    case "chart_table":
      return (
        <>
          <InfoRow title="Тема" value={data.theme.name} />
          {data.title && <InfoRow title="Заголовок" value={data.title} />}
          {data.source && <InfoRow title="Источник" value={data.source} />}
          <InfoRow title="Хронометраж (сек.)" value={data.timing} />
          <InfoRow title="Размер шрифта" value={data.fontSize} />
          <InfoRow title="Таблица" value="" />
          <table style={{ borderCollapse: "collapse" }}>
            <tbody>
              {data.tableData.data.map((row: string[], rowIndex: number) => (
                <tr key={rowIndex}>
                  {row.map((col, colIndex) => (
                    <td
                      key={colIndex}
                      style={{ border: "1px solid", padding: 10 }}
                    >
                      {col}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      );
    case "site_quote":
      return (
        <>
          <InfoRow title="Тема" value={data.theme.name} />
          <ImageRow title="Изображение" src={data.image} />
          <InfoRow title="Сайт" value={SITES[data.site]} />
          {data.site === "custom" && (
            <InfoRow title="Другой сайт" value={data.customSite} />
          )}
          {data.source && <InfoRow title="Источник" value={data.source} />}
          <InfoRow
            title="Текст"
            value={data.blocks
              .map(({ text }: { text: string }) => text)
              .join("\n")}
          />
          <InfoRow title="Хронометраж (сек.)" value={data.totalTiming} />
        </>
      );
    case "person_quote":
      return (
        <>
          <InfoRow title="Тема" value={data.theme.name} />
          {data.image && <ImageRow title="Фото" src={data.image} />}
          <InfoRow title="Автор" value={data.author} />
          {data.position && <InfoRow title="Должность" value={data.position} />}
          <InfoRow
            title="Цитата"
            value={data.blocks.map((item: any) => item.text).join("\n")}
          />
          <InfoRow title="Сайт" value={SITES[data.site] ?? "-"} />
          {data.site === "custom" && (
            <InfoRow title="Другой сайт" value={data.customSite} />
          )}
          {data.source && <InfoRow title="Источник" value={data.source} />}
          <InfoRow title="Хронометраж (сек.)" value={data.totalTiming} />
        </>
      );
    case "map":
      return (
        <>
          {data.blocks.map((item: Item, index: number) => (
            <details open>
              <summary>Блок {index + 1}</summary>
              <div>
                <InfoRow title="Тип блока" value={blockTypes[item.type]} />
                <InfoRow title="Хронометраж (сек.)" value={item.timing} />
                {item.type === "country" && (
                  <InfoRow
                    title="Страны"
                    value={item.countries
                      .map(
                        (country) =>
                          `${country.name} [${country.color.toUpperCase()}]`
                      )
                      .join("\n")}
                  />
                )}
                {item.type === "point" && (
                  <>
                    <InfoRow
                      title="Точки"
                      value={item.points
                        .map(
                          (point) =>
                            `${point.name} [${point.coords.lat}, ${point.coords.long}]`
                        )
                        .join("\n")}
                    />
                    <InfoRow
                      title="Линии соединения точек"
                      value={booleanTypes[String(item.connectLines)]}
                    />
                    <InfoRow
                      title="Цвет плашки"
                      value={`[${item.backgroundColor.toUpperCase()}]`}
                    />
                    <InfoRow
                      title="Цвет маркера"
                      value={`[${item.markerColor.toUpperCase()}]`}
                    />
                  </>
                )}
              </div>
            </details>
          ))}
        </>
      );
    default:
      return null;
  }
}

const padMonthOrDay = (value: number) => {
  return value.toString().padStart(2, "0");
};

export default function TaskView() {
  const { id } = useParams<{ id: string }>();
  const task = useSelector(selectTaskById(id));
  const userInfo = useSelector(selectUserInfo);
  const isAdmin = useSelector(selectIsAdmin);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!task) {
      dispatch(getTaskByIdThunk(id));
    }
  }, [dispatch, id, task]);

  useTaskTrackingById(id);

  const [copied, setCopied] = useState(false);
  const timeoutHandle = useRef<number>();

  useEffect(() => () => window.clearTimeout(timeoutHandle.current), []);

  const isR24 = task?.channel === "r24";

  const getR24Outpath = () => {
    if (!task || !userInfo) return "";

    const date = new Date(task.creationDate);
    const year = date.getFullYear();
    const month = padMonthOrDay(date.getMonth() + 1);
    const day = padMonthOrDay(date.getDate());
    const formattedDate = `${year}.${month}.${day}`;

    if (userInfo.groups.includes("r24")) {
      return `//172.27.68.118/Oformlenie/_CloudRenderVIZ/${formattedDate}/${task.filename}.mov`;
    } else {
      return `${task.outpath}/dates/${formattedDate}/${task.filename}.mov`;
    }
  };

  const outpath = !task
    ? ""
    : task.template === "map"
    ? task.outpath
    : isR24
    ? getR24Outpath()
    : `${task.outpath}/${task.filename}.mov`;

  const copyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(outpath);
      setCopied(true);
      timeoutHandle.current = window.setTimeout(() => setCopied(false), 3000);
    }
  };

  const history = useHistory();

  const redo = () => {
    history.push(`${routes.tasksNew}?redoId=${id}`);
  };

  const cancel = () => {
    if (task) {
      dispatch(cancelTaskByIdThunk(id));
    }
  };

  const isDesktop = useIsDesktop();

  if (!task) return null;
  return (
    <PageContainer>
      <BackButton to={routes.tasksList} />
      <Title text={allTemplates[task.template]} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 30,
        }}
      >
        <StatusBadge
          status={task.status}
          progress={task.progress}
          renderProgress={task.renderProgress}
        />
        <div className={styles.time}>
          {dateFormatter.format(Date.parse(task.creationDate))}
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.infoTitle}>Канал:</div>
        <div className={styles.infoDescription}>{channels[task.channel]}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.infoTitle}>Постановщик задачи:</div>
        <div className={styles.infoDescription}>{task.email}</div>
      </div>

      {task.status === "created" && (
        <div className={styles.row}>
          <div className={styles.infoTitle}>Номер в очереди:</div>
          <div className={styles.infoDescription}>{task.queueNumber}</div>
        </div>
      )}

      {task.status === "failed" && task.message && (
        <div className={styles.row} style={{ flexDirection: "column" }}>
          <div className={styles.infoTitle} style={{ color: "var(--red)" }}>
            Причина отмены:
          </div>
          <div
            className={styles.infoDescription}
            style={{
              textAlign: "left",
              marginTop: 5,
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
            }}
          >
            {task.message.replace(/\\r/g, "\n")}
          </div>
        </div>
      )}

      {task.status === "done" && (
        <div style={{ marginTop: 18 }}>
          <div className={styles.infoTitle} style={{ marginBottom: 9 }}>
            Путь к готовой графике:
          </div>
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 10 }}
          >
            <TextField
              name="filename"
              value={outpath}
              onChange={() => {}}
              style={{ flex: 2, margin: 0, marginRight: 10 }}
            />
            <Button
              title={copied ? "Скопировано" : "Скопировать"}
              status={copied ? "done" : "enabled"}
              onClick={copyToClipboard}
            />
          </div>
        </div>
      )}

      <hr />
      <TaskDataView template={task.template} data={task.data} />
      <br />
      {task.previewObjectStorageKey && <VideoPreview taskId={task._id} />}
      {task.channel === "maps" && isAdmin ? (
        <MapButtonBlock id={id} />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: isDesktop ? "row" : "column",
          }}
        >
          {task.status === "created" && (
            <Button
              title="Отменить"
              color="red"
              onClick={cancel}
              status={task.isCanceling ? "loading" : "enabled"}
              style={{ marginBottom: 10 }}
            />
          )}
          {task.objectStorageKey && <DownloadButton taskId={task._id} />}
          <Button
            title="Повторить заказ"
            onClick={redo}
            style={{ marginBottom: 10 }}
          />
        </div>
      )}
    </PageContainer>
  );
}
