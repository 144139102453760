import Select from "emg-ui-kit/components/Select";
import { useFormikContext } from "formik";
import React from "react";

import { AtmosphereFormParams } from "../../../../common/ApiService";
import PreviewImage from "../../../../common/PreviewImage";
import { getItemFieldName, ValidationPropsUtils } from "../../../util";
import Details from "../../components/Details";
import {
  atmoInfoBlockOptions,
  threeLinesColors,
  twoLinesColors,
} from "../../constants";
import {
  AtmoInfoItem as Item,
  AtmoInfoItem,
  ExtendedItemBase,
} from "../../types";
import { getSunriseSunsetLocations } from "../../utils";
import InfographicsBlock from "./InfographicsBlock";
import SunriseSunsetBlock from "./SunriseSunsetBlock";
import ThreeLinesBlock from "./ThreeLinesBlock";
import TwoLinesBlock from "./TwoLinesBlock";

function getPreviewImage(item: Item) {
  let imageSrc = `/images/atmosphere_info/${item.blockType}`;
  if ("additionalField" in item && item.additionalField !== "none") {
    imageSrc += `+${item.additionalField}`;
  }
  imageSrc += ".png";
  return imageSrc;
}

interface Props {
  item: AtmoInfoItem;
  index: number;
  formParams?: AtmosphereFormParams;
}

export default function AtmoInfoBlock({ item, index, formParams }: Props) {
  const formik = useFormikContext();

  const changeBlockType = (blockType: string) => {
    let nextItem: AtmoInfoItem;

    const base = {
      timing: blockType === "sunrise-sunset" ? "5" : item.timing,
      source: item.source,
    };
    let extendedBase: ExtendedItemBase = {
      ...base,
      mapVariant: "3D",
      location: "",
      weather: "",
      timeOfDay: "",
      videoEffect: "empty",
    };
    if ("mapVariant" in item && item.mapVariant === "2D") {
      extendedBase = {
        ...extendedBase,
        mapVariant: item.mapVariant,
        map: item.map,
      };
    } else if ("mapVariant" in item && item.mapVariant === "3D") {
      extendedBase = {
        ...extendedBase,
        mapVariant: item.mapVariant,
        location: item.location,
        weather: item.weather,
        timeOfDay: item.timeOfDay,
      };
    }

    if ("videoEffect" in item) {
      extendedBase.videoEffect = item.videoEffect;
    }

    switch (blockType) {
      case "two-lines":
        nextItem = {
          ...extendedBase,
          blockType: "two-lines",
          firstLine: ("firstLine" in item && item.firstLine) || "",
          secondLine: ("secondLine" in item && item.secondLine) || "",
          secondLineColor: twoLinesColors[0],
          additionalField: "none",
        };
        break;
      case "three-lines":
        nextItem = {
          ...extendedBase,
          blockType: "three-lines",
          firstLine: ("firstLine" in item && item.firstLine) || "",
          secondLine: ("secondLine" in item && item.secondLine) || "",
          thirdLine: ("thirdLine" in item && item.thirdLine) || "",
          firstLineColor: threeLinesColors[0],
          secondLineColor: threeLinesColors[0],
          thirdLineColor: threeLinesColors[0],
          additionalField: "none",
        };
        break;
      case "infographics":
        nextItem = {
          timing: "0",
          source: "",
          blockType: "infographics",
          clip: "",
        };
        break;
      case "sunrise-sunset":
        const locations = getSunriseSunsetLocations(formParams!);
        const sunriseLocation = locations.sunrise[base.timing];
        const [weather] = Object.keys(sunriseLocation.availableVariants);
        const [timeOfDay] = sunriseLocation.availableVariants[weather];
        nextItem = {
          ...base,
          mapVariant: "3D",
          location: sunriseLocation.id,
          weather,
          timeOfDay,
          blockType: "sunrise-sunset",
          type: "sunrise",
          time: "__:__",
          additionalField: "none",
        };
        break;
      default:
        return item;
    }

    formik.setFieldValue(`items.${index}`, nextItem);
  };

  const blockProps = { index, formParams };

  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors
  );

  const blockTypeName = getItemFieldName(index, "blockType");

  return (
    <>
      <Select
        placeholder="Тип блока"
        name={blockTypeName}
        options={atmoInfoBlockOptions}
        value={item.blockType}
        onChange={(event) => changeBlockType(event.target.value)}
        onBlur={formik.handleBlur}
        {...validationUtils.getDeepProps(blockTypeName)}
      />
      {item.blockType !== "" && (
        <>
          {item.blockType !== "infographics" && (
            <PreviewImage src={getPreviewImage(item)} />
          )}
          <Details>
            {(() => {
              switch (item.blockType) {
                case "two-lines":
                  return <TwoLinesBlock item={item} {...blockProps} />;
                case "three-lines":
                  return <ThreeLinesBlock item={item} {...blockProps} />;
                case "sunrise-sunset":
                  return <SunriseSunsetBlock item={item} {...blockProps} />;
                case "infographics":
                  return <InfographicsBlock item={item} {...blockProps} />;
              }
            })()}
          </Details>
        </>
      )}
    </>
  );
}
