import Blocks from "emg-ui-kit/components/Blocks";
import ImageInput from "emg-ui-kit/components/ImageInput";
import { FormImage } from "emg-ui-kit/components/ImageInput";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { FormikProvider, useFormik, Field } from "formik";
import React from "react";

import { FormProps } from "../../common/models";
import OrderSavingButtons from "../../common/OrderSavingButtons";
import { sources } from "../../common/texts";
import { convertToSelectOptions } from "../../common/utils";
import Form from "../Form";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  IMAGE_TYPES,
  MAX_TIMING,
  removeEmptyProps,
  validateAspect,
  validateIncorrectFormat,
  validateMax,
  validateNotEmpty,
  validateNumber,
  validatePositive,
  validateTimingSum,
  ValidationPropsUtils,
} from "../util";
import { THEMES, THEMES_FULL } from "./constants";

type Item = {
  timing: number | string;
  image?: FormImage;
};

function createItem(): Item {
  return { timing: 0, image: undefined };
}

function initItems(count = 1) {
  return Array.from(Array(count), createItem);
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    name: (initialFormData?.name ?? "") as string,
    theme: (initialFormData?.theme?.id in THEMES
      ? initialFormData?.theme?.id
      : "") as string,
    title: (initialFormData?.title ?? "") as string,
    subtitle: (initialFormData?.subtitle ?? "") as string,
    timing: (initialFormData?.equalTimings
      ? initialFormData.totalTiming
      : 30) as number | string,
    timingType: (initialFormData?.equalTimings === false
      ? "separate"
      : "total") as string,
    source: (initialFormData?.source ?? "") as string,
    items: (initialFormData?.blocks ?? initItems()) as Item[],
  };
}

type Values = ReturnType<typeof getInitialValues>;

function calcTotalTiming(values: Values) {
  return values.timingType === "total"
    ? +values.timing
    : values.items.reduce((acc, item) => acc + +item.timing, 0);
}

const IMAGE_ASPECT = 1740 / 840;

function validate(values: Values) {
  const errors = {
    name: values.name
      ? validateIncorrectFormat(values.name, CLIP_NAME_REGEXP)
      : undefined,
    theme: validateNotEmpty(values.theme),
    timing:
      values.timingType === "total"
        ? validateNumber(values.timing) ??
        validatePositive(+values.timing) ??
        validateMax(+values.timing, MAX_TIMING)
        : undefined,
    items: values.items.map((item) => ({
      image:
        validateNotEmpty(item.image) ??
        validateAspect(item.image!, IMAGE_ASPECT),
      timing:
        values.timingType === "separate"
          ? validateNumber(item.timing) ??
          validatePositive(+item.timing) ??
          validateTimingSum(calcTotalTiming(values), MAX_TIMING)
          : undefined,
    })),
  };
  return removeEmptyProps(errors);
}

function prepareData(values: Values) {
  return {
    theme: THEMES_FULL[values.theme],
    name: values.name,
    title: values.title,
    subtitle: values.subtitle,
    source: values.source,
    equalTimings: values.timingType === "total",
    totalTiming: calcTotalTiming(values),
    blocks: values.items,
  };
}

function RSlideShowForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });

  usePreview(
    channel,
    template,
    calcTotalTiming(formik.values),
    formik.values,
    prepareData
  );

  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors
  );

  const buttonProps = {
    isValid: formik.isValid,
    isSubmitting: formik.isSubmitting,
    prepareData,
    values: formik.values,
    onSaveDraft,
    onDeleteDraft
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="name"
          {...validationUtils.getProps("name")}
        />
        <Field
          as={Select}
          name="theme"
          label="Тема оформления"
          options={convertToSelectOptions(THEMES)}
          {...validationUtils.getProps("theme")}
          required
        />
        <Field as={TextArea} label="Заголовок" name="title" rows={2} />
        <Field as={TextField} label="Подзаголовок" name="subtitle" />
        <Field
          as={Select}
          label="Хронометраж"
          name="timingType"
          options={[
            { id: "total", name: "Общий" },
            { id: "separate", name: "Для каждого слайда" },
          ]}
        />
        {formik.values.timingType === "total" && (
          <Field
            as={TextField}
            label="Общий хронометраж (сек.)"
            name="timing"
            type="number"
            {...validationUtils.getProps("timing")}
            required
          />
        )}
        <Field
          as={TextField}
          label="Источник"
          name="source"
          dataList={sources}
        />
        <Blocks
          items={formik.values.items}
          updateItems={(items) => formik.setFieldValue("items", items)}
          defaultItemConstructor={createItem}
          canChangeLength
        >
          {(item, index) => (
            <>
              <ImageInput
                aspect={IMAGE_ASPECT}
                imageTypes={IMAGE_TYPES}
                image={item.image}
                updateImage={(image) => {
                  formik.setFieldTouched(`items[${index}].image`);
                  formik.setFieldValue(`items[${index}].image`, image);
                }}
                {...validationUtils.getDeepProps(`items[${index}].image`)}
              />
              {formik.values.timingType === "separate" && (
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <div style={{ marginRight: 20 }}>Хронометраж (сек.)</div>
                  <Field
                    as={TextField}
                    name={`items[${index}].timing`}
                    type="number"
                    {...validationUtils.getDeepProps(`items[${index}].timing`)}
                    style={{ width: "100%" }}
                  />
                </div>
              )}
            </>
          )}
        </Blocks>
        <br />

        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(RSlideShowForm);
