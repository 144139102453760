import Title from "emg-ui-kit/components/Title";
import TrashButton from "emg-ui-kit/components/TrashButton";
import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import PageContainer from "../../common/PageContainer";
import { Order, Table } from "../../common/Table";
import { Task } from "../../common/models";
import { findThemeName, templates } from "../../common/texts";
import { channels } from "../../common/texts";
import { dateFormatter } from "../../common/utils";
import { selectUserInfo } from "../../redux/auth/selectors";
import { getDraftsThunk } from "../../redux/drafts";
import { selectDraftsByPage } from "../../redux/drafts/selectors";
import routes from "../../routes";
import { AppDispatch } from "../../store";
import Pagination from "../TaskTablePage/Pagination";
import { restoreFilterParams, saveFilterParams } from "../tasksUtils";
import { FilterParams } from "../types";
import useDeleteDraft from "../useDeleteDraft";
import styles from "./../TaskTablePage/TaskTable.module.css";

const getTemplate = (task: Task) =>
  templates[task.channel]?.[task.template] ?? "-";
const getTheme = (task: Task) =>
  task.data.theme?.name ??
  findThemeName(task.data.theme, task.data.background) ??
  "-";
const getDate = (task: Task) =>
  dateFormatter.format(Date.parse(task.creationDate));

const getChannel = (task: Task) => {
  return channels[task.channel];
};

const getClipName = (task: Task) => task.data.clipName || task.data.name || "-";

export default function DraftTablePage() {
  const dispatch = useDispatch<AppDispatch>();
  const [params, setParams] = useState(restoreFilterParams);
  const { drafts, totalPages } = useSelector(
    selectDraftsByPage(params.page, params.perPage)
  );
  const userInfo = useSelector(selectUserInfo)!;
  const history = useHistory();

  const changeParams = useCallback((params: FilterParams) => {
    saveFilterParams(params);
    setParams(params);
  }, []);

  const changeParam = <T extends keyof FilterParams>(
    param: T,
    value: FilterParams[T]
  ) => {
    const nextParams =
      param === "page"
        ? { ...params, [param]: value }
        : { ...params, [param]: value, page: 1 };
    changeParams(nextParams);
  };

  const { handleDeleteDraft, isDisabled } = useDeleteDraft(() =>
    dispatch(getDraftsThunk(params))
  );

  const handleOpenDraft = (task: Task) => {
    history.push(`${routes.draft}?redoId=${task._id}&isDraft=true`);
  };

  useEffect(() => {
    dispatch(getDraftsThunk(params));
  }, [dispatch, params]);

  const isR24 = userInfo.groups.includes("r24");

  return (
    <PageContainer className={styles.pageContainer}>
      <section className={styles.workspace}>
        <Title text="Черновики" />

        <Table
          items={drafts}
          displayProps={[
            {
              name: "Шаблон",
              selector: getTemplate,
              className: styles.taskTitle,
              currentSort: true,
            },
            ...(isR24
              ? [
                  { name: "Оформление", selector: getTheme },
                  { name: "Название", selector: "data.name" },
                ]
              : [{ name: "Название ролика", selector: getClipName }]),
            {
              name: "Проект",
              selector: getChannel,
              className: styles.dateCell,
            },
            {
              name: "Дата создания",
              selector: getDate,
              className: styles.dateCell,
              currentSort: true,
            },
            {
              name: "",
              selector: (task) => (
                <TrashButton
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDeleteDraft(task._id);
                  }}
                  status={isDisabled ? "disabled" : "enabled"}
                />
              ),
              headerClassName: styles.buttonHeader,
            },
          ]}
          onItemClick={handleOpenDraft}
          order={params.order as Order}
          changeOrder={(value) => changeParam("order", value)}
          idPropName="_id"
        />
        <Pagination
          tasks={drafts}
          page={params.page}
          perPage={params.perPage}
          totalPages={totalPages}
          changePage={(value) => changeParam("page", value)}
        />
      </section>
    </PageContainer>
  );
}
