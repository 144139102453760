import Blocks from "emg-ui-kit/components/Blocks";
import { HtmlEditor2 } from "emg-ui-kit/components/HtmlEditor2";
import ImageInput from "emg-ui-kit/components/ImageInput";
import { FormImage } from "emg-ui-kit/components/ImageInput";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { Field, FormikErrors, FormikProvider, useFormik } from "formik";
import { nanoid } from "nanoid";
import React from "react";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { convertHtmlforUnity } from "../../common/TagConverter";
import { FormProps } from "../../common/models";
import Form from "../Form";
import messages from "../messages";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  getValidationProps,
  IMAGE_TYPES,
  MAX_TIMING,
  pick,
  removeEmptyProps,
  validateIncorrectFormat,
  validateMax,
  validateNotEmpty,
  validatePositive,
  validateTimingSum,
  validateAspect,
} from "../util";
import { TimingField } from "./TimingField";

type SourceColor = "light" | "dark";
type TitleType = "news" | "red";

const IMAGE_ASPECT = 1.046 / 1;
const TEXTEDIT_TOOLTIP =
  "Жирный: <b></b><br>Курсив: <i></i> <br>Размер текста: <size=50></size><br>Красный цвет: <color=#DD2A49></color><br>Серый цвет: <color=#252525></color>";
const FONT_SIZES = [
  13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  32, 33, 34, 36, 38, 40, 42, 44, 46,
];

interface ImageItem {
  timing: number;
  image?: FormImage;
  imageSource: string;
  imageSourceColor: SourceColor;
}

interface TextItem {
  key: string;
  timing: number;
  texts: string[];
  editorsTexts: string[];
  interval: number;
  intervals: number[];
}

interface Titles {
  timing: number;
  text: string[];
  interval: number;
}

function createItem(): ImageItem {
  return {
    timing: 0,
    image: undefined,
    imageSource: "",
    imageSourceColor: "light",
  };
}

function createTextItem(): TextItem {
  return {
    key: nanoid(),
    timing: 0,
    texts: [""],
    editorsTexts: [""],
    interval: 0,
    intervals: [0],
  };
}

function initTextItems(count = 1) {
  return Array.from(Array(count), createTextItem);
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    titleType: (initialFormData?.titleType ?? "news") as TitleType,
    source: (initialFormData?.source ?? "") as string,
    timingType: initialFormData?.equalTimings ? "total" : "separate",
    timing: (initialFormData?.equalTimings
      ? initialFormData.totalTiming
      : 0) as number,
    titles: (initialFormData?.titles?.map((block: any) => {
      return block.timing ? block : { ...block, timing: 0, interval: 0 };
    }) ?? [{ text: "", timing: 0, interval: 0 }]) as {
      text: string;
      timing: number;
      interval: number;
    }[],
    subtitles: (initialFormData?.subtitles?.map((block: any) => {
      return block.timing ? block : { ...block, timing: 0 };
    }) ?? [{ text: "", timing: 0 }]) as { text: string; timing: number }[],
    textItems: (initialFormData?.textItems?.map((item: any) => {
      return item.timing ? item : { ...item, timing: 0 };
    }) ?? initTextItems()) as TextItem[],
    items: (initialFormData?.blocks?.map((block: any) => {
      return block.timing ? block : { ...block, timing: 0 };
    }) ?? []) as ImageItem[],
    format: (initialFormData?.format ?? "default") as "default" | "premiere",
  };
}

type Values = ReturnType<typeof getInitialValues>;

function validate(values: Values) {
  const errors = {
    clipName: values.clipName
      ? validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP)
      : undefined,
    timing:
      values.timingType === "total"
        ? validatePositive(values.timing) ??
          validateMax(values.timing, MAX_TIMING)
        : undefined,
    titles: values.titles.map((item) => ({
      text: validateNotEmpty(item.text),
      timing:
        values.timingType === "separate"
          ? validatePositive(item.timing) ??
            validateTimingSum(calcTotalTiming(values), MAX_TIMING)
          : undefined,
    })),
    subtitles:
      values.titleType === "red"
        ? values.subtitles.map((item) => ({
            text: undefined,
            timing: undefined,
          }))
        : values.subtitles.map((item) => ({
            text: undefined,
            timing:
              values.timingType === "separate"
                ? validatePositive(item.timing) ??
                  validateTimingSum(calcTotalTiming(values), MAX_TIMING)
                : undefined,
          })),
    textItems: values.textItems.map((item) => ({
      texts: item.texts.map((value) => {
        const div = document.createElement("div");
        div.innerHTML = value;
        return div.textContent?.match(/\S/) ? undefined : messages.empty;
      }),
      timing:
        values.timingType === "separate"
          ? validatePositive(item.timing) ??
            validateTimingSum(calcTotalTiming(values), MAX_TIMING)
          : undefined,
    })),
    items: values.items.map((item) => ({
      image:
        validateNotEmpty(item.image) ??
        validateAspect(item.image!, IMAGE_ASPECT),
      timing:
        values.timingType === "separate"
          ? validatePositive(item.timing) ??
            validateTimingSum(calcTotalTiming(values), MAX_TIMING)
          : undefined,
    })),
  };
  return removeEmptyProps(errors);
}

function sumTimings(items: { timing: number }[]) {
  return items.reduce((acc, item) => acc + item.timing, 0);
}

function calcTotalTiming(values: Values) {
  return values.timingType === "total"
    ? values.timing
    : Math.max(
        sumTimings(values.items),
        sumTimings(values.textItems),
        sumTimings(values.titles),
        sumTimings(values.subtitles)
      );
}

function prepareData(values: Values) {
  return {
    ...pick(values, "clipName", "titleType", "source", "format"),
    titles: values.titles.map((item) =>
      values.timingType === "total" ? pick(item, "text", "interval") : item
    ),
    subtitles: values.subtitles.map((item) =>
      values.timingType === "total" ? pick(item, "text") : item
    ),
    textItems: values.textItems.map((item) =>
      values.timingType === "total"
        ? pick(item, "texts", "interval", "intervals", "editorsTexts")
        : item
    ),
    blocks: values.items.map((item) =>
      values.timingType === "total"
        ? pick(item, "image", "imageSource", "imageSourceColor")
        : item
    ),
    equalTimings: values.timingType === "total",
    totalTiming: calcTotalTiming(values),
  };
}

function ReferenceForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    isValid,
  } = formik;

  const buttonProps = {
    isSubmitting,
    isValid,
    prepareData,
    values,
    onSaveDraft,
    onDeleteDraft,
  };

  usePreview(channel, template, calcTotalTiming(values), values, prepareData);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="clipName"
          {...getValidationProps("clipName", touched, errors)}
        />
        <Field
          as={Select}
          name="format"
          label="Формат"
          options={[
            { id: "default", name: "По умолчанию" },
            { id: "premiere", name: "Premiere" },
          ]}
        />
        <Field
          as={Select}
          name="titleType"
          label="Тип заголовка"
          options={[
            { id: "news", name: "Новостной заголовок" },
            { id: "red", name: "Красный заголовок" },
          ]}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            const value = event.target.value;
            setFieldValue("titleType", value);

            if (value === "news") {
              const newTitles = values.titles.map((title) => ({
                ...title,
                interval: 0,
              }));
              setFieldValue("titles", newTitles);
            }
          }}
        />
        <Field as={TextField} label="Источник информации" name="source" />
        <Field
          as={Select}
          name="timingType"
          label="Хронометраж"
          options={[
            { id: "total", name: "Общий" },
            { id: "separate", name: "Для каждой страницы" },
          ]}
        />
        {values.timingType === "total" && (
          <Field
            as={TextField}
            name="timing"
            type="number"
            label="Общий хронометраж (сек.)"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue("timing", +event.target.value)
            }
            {...getValidationProps("timing", touched, errors)}
          />
        )}
        <br />

        <Blocks
          items={values.titles}
          defaultItemConstructor={() => ({ timing: 0, text: "", interval: 0 })}
          updateItems={(items) => setFieldValue("titles", items)}
          blockTitle="Заголовок"
          canChangeLength
          minBlocks={0}
          tooltip={TEXTEDIT_TOOLTIP}
        >
          {(item, index, updateItem) => (
            <>
              <Field
                as={TextArea}
                name={`titles.${index}.text`}
                rows={2}
                placeholder="Заголовок"
                isValid={
                  !touched.titles ||
                  !(errors.titles?.[index] as FormikErrors<Titles>)?.text
                }
                validationMessage={
                  (errors.titles?.[index] as FormikErrors<Titles>)?.text
                }
              />
              {values.timingType === "separate" && (
                <TimingField
                  blocksName="titles"
                  index={index}
                  updateItem={updateItem}
                  touched={touched}
                  errors={errors}
                />
              )}
              {values.titleType === "red" && (
                <Field
                  key={`interval_${index}`}
                  as={TextField}
                  name={`titles.${index}.interval`}
                  label="Интервал"
                  step={0.1}
                  type="number"
                  labelStyle={{ maxWidth: 180 }}
                />
              )}
            </>
          )}
        </Blocks>
        <br />
        {values.titleType === "news" && (
          <>
            <Blocks
              items={values.subtitles}
              defaultItemConstructor={() => ({ timing: 0, text: "" })}
              updateItems={(items) => setFieldValue("subtitles", items)}
              blockTitle="Подзаголовок"
              canChangeLength
              minBlocks={0}
              tooltip={TEXTEDIT_TOOLTIP}
            >
              {(item, index, updateItem) => (
                <>
                  <Field
                    as={TextArea}
                    rows={2}
                    name={`subtitles.${index}.text`}
                    placeholder="Подзаголовок"
                    isValid={
                      !touched.subtitles ||
                      !(errors.subtitles?.[index] as FormikErrors<Titles>)?.text
                    }
                    validationMessage={
                      (errors.subtitles?.[index] as FormikErrors<Titles>)?.text
                    }
                  />
                  {values.timingType === "separate" && (
                    <TimingField
                      blocksName="subtitles"
                      index={index}
                      updateItem={updateItem}
                      touched={touched}
                      errors={errors}
                    />
                  )}
                </>
              )}
            </Blocks>
            <br />
          </>
        )}

        <Blocks
          blockTitle="Блок текста"
          items={values.textItems}
          defaultItemConstructor={createTextItem}
          updateItems={(items) => setFieldValue("textItems", items)}
          canChangeLength
          tooltip={TEXTEDIT_TOOLTIP}
        >
          {(item, index, updateItem) => (
            <>
              {item.texts.map((text, innerIndex) => (
                <React.Fragment key={`fragment_${innerIndex}`}>
                  <HtmlEditor2
                    fontSize={FONT_SIZES}
                    key={item.key + innerIndex}
                    name={`textItems.${index}.texts.${innerIndex}`}
                    onChange={(value: string) => {
                      setFieldValue(
                        `textItems.${index}.texts.${innerIndex}`,
                        convertHtmlforUnity(value)
                      );
                      setFieldValue(
                        `textItems.${index}.editorsTexts.${innerIndex}`,
                        value
                      );
                    }}
                    defaultValue={item?.editorsTexts?.[innerIndex]}
                    isValid={
                      !(errors as any).textItems?.[index]?.texts?.[innerIndex]
                    }
                    validationMessage={
                      (errors as any).textItems?.[index]?.texts?.[innerIndex]
                    }
                  />
                  <Field
                    key={`interval_${innerIndex}`}
                    as={TextField}
                    name={`textItems.${index}.intervals.${innerIndex}`}
                    label="Интервал"
                    type="number"
                    step={0.1}
                    labelStyle={{ maxWidth: 180 }}
                  />
                </React.Fragment>
              ))}
              <hr />
              {values.timingType === "separate" && (
                <TimingField
                  blocksName="textItems"
                  index={index}
                  updateItem={updateItem}
                  touched={touched}
                  errors={errors}
                />
              )}
              <TextField
                label="Абзацы"
                type="number"
                value={item.texts.length.toString()}
                onChange={(event) => {
                  const val = +event.target.value;
                  const len = item.texts.length;
                  if (val === len || val < 1 || val > 6) return;
                  if (val > len) {
                    updateItem({
                      texts: item.texts.concat(Array(val - len).fill("")),
                      intervals: item.intervals.concat(
                        Array(val - len).fill(0)
                      ),
                    });
                  } else {
                    updateItem({
                      texts: item.texts.slice(0, val),
                      intervals: item.intervals.slice(0, val - 1),
                    });
                  }
                }}
                labelStyle={{ maxWidth: 180 }}
              />
              {item.texts.length > 1 && (
                <Field
                  key={`interval_${index}`}
                  as={TextField}
                  name={`textItems.${index}.interval`}
                  label="Интервал между абзацами"
                  type="number"
                  step={0.1}
                  labelStyle={{ maxWidth: 180 }}
                />
              )}
            </>
          )}
        </Blocks>
        <br />

        <Blocks
          items={values.items}
          defaultItemConstructor={createItem}
          updateItems={(items) => setFieldValue("items", items)}
          canChangeLength
          minBlocks={0}
        >
          {(item, index, updateItem) => (
            <>
              <ImageInput
                imageTypes={IMAGE_TYPES}
                aspect={IMAGE_ASPECT}
                image={item.image}
                updateImage={(image) => {
                  setFieldTouched(`items.${index}.image`);
                  updateItem({ image });
                }}
                isValid={
                  !touched.items ||
                  !(errors.items?.[index] as FormikErrors<ImageItem>)?.image
                }
                validationMessage={
                  (errors.items?.[index] as FormikErrors<ImageItem>)?.image
                }
              />
              <Field
                as={TextField}
                name={`items.${index}.imageSource`}
                placeholder="Источник фото"
              />
              <Field
                as={Select}
                placeholder="-- Цвет источника --"
                name={`items.${index}.imageSourceColor`}
                options={[
                  { id: "light", name: "Светлый" },
                  { id: "dark", name: "Тёмный" },
                ]}
                disabled={!item.imageSource}
              />
              {values.timingType === "separate" && (
                <TimingField
                  blocksName="items"
                  index={index}
                  updateItem={updateItem}
                  touched={touched}
                  errors={errors}
                />
              )}
            </>
          )}
        </Blocks>
        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(ReferenceForm);
