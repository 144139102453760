const routes = {
  signup: "/signup",
  notifications: "/dashboard",
  atmoDataSync: "/data",
  usersNew: "/users/new",
  usersUpdate: "/users/:id",
  users: "/users",
  personsNew: "/persons/new",
  personsUpdate: "/persons/:id",
  persons: "/persons",
  newsArticlesNew: "/articles/new",
  newsArticlesUpdate: "/articles/:id",
  newsArticles: "/articles",
  newsArticlesView: "/news",
  tasksNew: "/order",
  tasksView: "/tasks/:id",
  tasksList: "/tasks",
  index: "/",
  fallback: "*",
  draft: "/draft",
  drafts: "/drafts",
};

export default routes;
