import { convertToSelectOptions } from "../../common/utils";
import { Coords, CountryItem, Item, Point, PointItem } from "./types";

export const countTypes: Record<number, string> = {
  1: "Одна",
  2: "Две",
  3: "Три",
  4: "Четыре",
  5: "Пять",
};
export const countOptions = convertToSelectOptions(countTypes);

export const blockTypes = {
  country: "Страна",
  point: "Точка на карте",
};
export const blockOptions = convertToSelectOptions(blockTypes);

export function createCountry() {
  return {
    name: "",
    color: "#00cc00",
  };
}

export function createCountryItem(): CountryItem {
  const countries = Array.from(Array(1), createCountry);
  return { type: "country", timing: 10, countries };
}

export function createPoint(): Point {
  return {
    type: "city",
    name: "",
    coords: { lat: 0, long: 0 },
  };
}

export function createPointItem(): PointItem {
  const points: Point[] = Array.from(Array(1), createPoint);
  return {
    type: "point",
    connectLines: false,
    backgroundColor: "#0000cc",
    markerColor: "#00cc00",
    timing: 10,
    points,
  };
}

export function createItem(): Item {
  return createCountryItem();
}

export function initItems(count = 1) {
  return Array.from(Array(count), createItem);
}

export function getCoordsTuple(coords: Coords): [number, number] {
  return [coords.lat, coords.long];
}

export class CitySearcher {
  coordsByCity: Map<string, { lat: number; long: number }>;

  constructor() {
    this.coordsByCity = new Map();
    this.search = this.search.bind(this);
  }

  _getFeatureName(feature: any) {
    return `${feature.GeoObject.name}, ${feature.GeoObject.description}`;
  }

  async search(query: string) {
    if (!query) {
      return [];
    }
    const params = {
      geocode: query,
      apikey: process.env.REACT_APP_YANDEX_MAPS_KEY,
      format: "json",
      results: 30,
    };
    const queryString = Object.entries(params).reduce((acc, val, index) => {
      const [key, value] = val;
      const prefix = index ? "&" : "?";
      return acc + `${prefix}${key}=${value}`;
    }, "");

    const response = await fetch(
      `https://geocode-maps.yandex.ru/1.x${queryString}`
    );
    const results = await response.json();

    return results.response.GeoObjectCollection.featureMember
      .filter((feature: any) => {
        const isCityOrProvince = ["province", "locality"].includes(
          feature.GeoObject.metaDataProperty.GeocoderMetaData.kind
        );
        if (isCityOrProvince) {
          const [long, lat] = feature.GeoObject.Point.pos
            .split(" ")
            .map(Number);
          this.coordsByCity.set(this._getFeatureName(feature), { lat, long });
        }
        return isCityOrProvince;
      })
      .map((feature: any, index: number) => ({
        id: index,
        value: this._getFeatureName(feature),
      }));
  }
}
