import Button from "emg-ui-kit/components/Button";
import Checkbox from "emg-ui-kit/components/Checkbox";
import Radio from "emg-ui-kit/components/Radio";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import Title from "emg-ui-kit/components/Title";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { NotificationCategories } from "../common/ApiService";
import PageContainer from "../common/PageContainer";
import {
  getEmailParamsThunk,
  updateEmailParamsThunk,
} from "../redux/emailParams";
import { selectEmailParams } from "../redux/emailParams/selectors";
import {
  getNotificationThunk,
  updateNotificationThunk,
} from "../redux/notifications";
import { selectNotification } from "../redux/notifications/selectors";

export default function NotificationSettings() {
  const dispatch = useDispatch();

  const {
    emailParams,
    isLoading: isEmailParamsLoading,
    isUpdating: isEmailParamsUpdating,
  } = useSelector(selectEmailParams);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [intervalMinutes, setIntervalMinutes] = useState(10);

  const {
    notification,
    isLoading: isNotificationLoading,
    isUpdating: isNotificationUpdating,
  } = useSelector(selectNotification);
  const [message, setMessage] = useState("");
  const [category, setCategory] = useState<NotificationCategories>("none");

  const firstChangeMade = useRef(false);

  useEffect(() => {
    dispatch(getNotificationThunk());
    dispatch(getEmailParamsThunk());
  }, [dispatch]);

  useEffect(() => {
    if (notification && !firstChangeMade.current) {
      setMessage(notification.message);
      setCategory(notification.category);
      firstChangeMade.current = true;
    }
  }, [notification]);

  useEffect(() => {
    if (emailParams) {
      setNotificationsEnabled(emailParams.notificationsEnabled);
      setIntervalMinutes(emailParams.intervalMinutes);
    }
  }, [emailParams]);

  const handleClick = () => {
    dispatch(updateNotificationThunk({ message, category }));
    dispatch(updateEmailParamsThunk({ notificationsEnabled, intervalMinutes }));
  };

  const isLoading = isEmailParamsLoading || isNotificationLoading;
  const isUpdating = isEmailParamsUpdating || isNotificationUpdating;

  return (
    <PageContainer>
      <Title text="Настройка уведомлений" />
      <div>Интервал проверки текущих задач</div>
      <div style={{ display: "flex", alignItems: "baseline", marginTop: 7 }}>
        <TextField
          name="checkInterval"
          type="number"
          value={intervalMinutes.toString()}
          onChange={(event) => setIntervalMinutes(+event.target.value)}
          disabled={!notificationsEnabled}
          style={{ width: 100 }}
        />
        <div style={{ marginLeft: 9 }}>мин.</div>
      </div>

      <Checkbox
        label="Отправлять на почту администраторам"
        checked={notificationsEnabled}
        setChecked={setNotificationsEnabled}
      />

      <Title text="Сообщение" style={{ fontSize: 25 }} />
      <Radio
        name="messageType"
        currentValue={category}
        setCurrentValue={(value) =>
          setCategory(value as NotificationCategories)
        }
        value="none"
        title="Нет"
      />
      <Radio
        name="messageType"
        currentValue={category}
        setCurrentValue={(value) =>
          setCategory(value as NotificationCategories)
        }
        value="error"
        title="Ошибка"
      />
      <Radio
        name="messageType"
        currentValue={category}
        setCurrentValue={(value) =>
          setCategory(value as NotificationCategories)
        }
        value="warning"
        title="Предупреждение"
      />
      <Radio
        name="messageType"
        currentValue={category}
        setCurrentValue={(value) =>
          setCategory(value as NotificationCategories)
        }
        value="info"
        title="Информация"
      />
      {category !== "none" && (
        <TextArea
          placeholder="Текст сообщения"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
        />
      )}
      <br />

      <Button
        title="Сохранить"
        status={isLoading ? "disabled" : isUpdating ? "loading" : "enabled"}
        onClick={handleClick}
      />
    </PageContainer>
  );
}
