import SpinnerBase from "emg-ui-kit/components/Spinner";
import React from "react";

import { useIsDesktop } from "../../../common/utils";

export default function Spinner() {
  const isDesktop = useIsDesktop();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        ...(isDesktop && { marginLeft: 210 }),
      }}
    >
      <SpinnerBase />
    </div>
  );
}
