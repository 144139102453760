import Button from "emg-ui-kit/components/Button";
import TextField from "emg-ui-kit/components/TextField";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useIsDesktop } from "../../common/utils";
import {
  acceptTaskByIdThunk,
  cancelTaskByIdThunk,
  finishTaskByIdThunk,
} from "../../redux/tasks";
import { selectTaskById } from "../../redux/tasks/selectors";
import routes from "../../routes";

const linkRegexp = /^https?:\/\/(\w+\.)+\w+\/.+$/;

export default function MapButtonBlock({ id }: { id: string }) {
  const dispatch = useDispatch();
  const task = useSelector(selectTaskById(id));
  const [outURL, setOutURL] = useState("");
  const isDesktop = useIsDesktop();
  const history = useHistory();

  const accept = () => {
    if (task) {
      dispatch(acceptTaskByIdThunk(id));
    }
  };

  const cancel = () => {
    if (task) {
      dispatch(cancelTaskByIdThunk(id));
    }
  };

  const finish = () => {
    if (task) {
      dispatch(finishTaskByIdThunk({ id, outURL }));
    }
  };

  const redo = () => {
    history.push(`${routes.tasksNew}?redoId=${id}`);
  };

  const validationMessage = linkRegexp.test(outURL)
    ? undefined
    : "Некорректный URL";

  const containerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: isDesktop ? "row" : "column",
  };
  const buttonStyle = { marginBottom: 10 };

  if (!task) {
    return null;
  }
  return (
    <>
      {task.status === "inProgress" && (
        <TextField
          value={outURL}
          onChange={(event) => setOutURL(event.target.value)}
          isValid={!validationMessage}
          validationMessage={validationMessage}
        />
      )}
      <div style={containerStyle}>
        {task.status === "created" ? (
          <Button
            title="Принять заказ"
            onClick={accept}
            status={task.isAccepting ? "loading" : "enabled"}
            style={buttonStyle}
          />
        ) : task.status === "inProgress" ? (
          <Button
            title="Завершить"
            onClick={finish}
            style={buttonStyle}
            status={
              task.isFinishing
                ? "loading"
                : validationMessage
                ? "disabled"
                : "enabled"
            }
          />
        ) : (
          <Button title="Повторить заказ" onClick={redo} style={buttonStyle} />
        )}
        {["created", "inProgress"].includes(task.status) && (
          <Button
            title="Отменить"
            color="red"
            onClick={cancel}
            style={buttonStyle}
            status={task.isCanceling ? "loading" : "enabled"}
          />
        )}
      </div>
    </>
  );
}
