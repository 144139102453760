import React from "react";

import { Task } from "../../common/models";
import { channels, templates } from "../../common/texts";
import { dateFormatter } from "../../common/utils";
import StatusBadge from "../StatusBadge";
import styles from "./TaskList.module.css";

const TaskRow = React.forwardRef<HTMLDivElement, { task: Task }>(function (
  { task },
  ref
) {

  return (
    <article ref={ref} className={styles.task}>
      <div className={styles.taskHeader}>
        <div className={styles.project}>{channels[task.channel]}</div>
        <div className={styles.time}>
          {dateFormatter.format(Date.parse(task.creationDate))}
        </div>
      </div>
      <div className={styles.title}>
        {templates[task.channel]?.[task.template] ?? "-"}
      </div>
      <StatusBadge
        status={task.status}
        progress={task.progress}
        renderProgress={task.renderProgress}
        style={{ marginTop: 4 }}
      />
    </article>
  );
});

export default TaskRow;
