import Spinner from "emg-ui-kit/components/Spinner";
import React from "react";

import styles from "./TaskTable.module.css";

interface Props {
  isLoading: boolean;
  tasksLength: number;
}

export default function Status({ isLoading, tasksLength }: Props) {
  return (
    <>
      {isLoading && (
        <div className={styles.centerContainer}>
          <Spinner />
        </div>
      )}
      {!isLoading && !tasksLength && (
        <div className={styles.noOrders}>Нет заказов</div>
      )}
    </>
  );
}
