import Radio from "emg-ui-kit/components/Radio";
import Select from "emg-ui-kit/components/Select";
import TextField from "emg-ui-kit/components/TextField";
import { Field, useFormikContext } from "formik";
import React from "react";

import { AtmosphereFormParams } from "../../../../common/ApiService";
import FlexContainer from "../../../../common/FlexContainer";
import LegendInput from "../../../../common/LegendInput";
import { getItemFieldName, ValidationPropsUtils } from "../../../util";
import { iconOptions, threeLinesColors } from "../../constants";
import { ThreeLinesItem } from "../../types";
import MapBlock from "../MapBlock";
import SourceBlock from "../SourceBlock";
import TimingBlock from "../TimingBlock";

const firstLineColors = threeLinesColors;
const otherLinesColors = threeLinesColors.slice(0, -1);

interface Props {
  item: ThreeLinesItem;
  index: number;
  formParams?: AtmosphereFormParams;
}

export default function ThreeLinesBlock({ item, index, formParams }: Props) {
  const formik = useFormikContext();
  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors
  );

  const additionalName = getItemFieldName(index, "additionalField");
  const additionalRadioProps = {
    name: additionalName,
    currentValue: item.additionalField,
    setCurrentValue: (value: string) =>
      formik.setFieldValue(additionalName, value),
    style: { margin: "0.5rem 0 0 0" },
  };

  const iconName = getItemFieldName(index, "icon");
  const firstLineName = getItemFieldName(index, "firstLine");
  const firstLineColorName = getItemFieldName(index, "firstLineColor");
  const secondLineName = getItemFieldName(index, "secondLine");
  const secondLineColorName = getItemFieldName(index, "secondLineColor");
  const thirdLineName = getItemFieldName(index, "thirdLine");
  const thirdLineColorName = getItemFieldName(index, "thirdLineColor");
  const accentName = getItemFieldName(index, "accent");

  return (
    <>
      <div>Дополнительно</div>
      <FlexContainer style={{ flexDirection: "column" }}>
        <Radio {...additionalRadioProps} value="none" title="Ничего" />
        <Radio {...additionalRadioProps} value="icon-right" title="Иконка" />
        <Radio
          {...additionalRadioProps}
          value="accent"
          title="Акцент"
          style={{ margin: "0.5rem 0" }}
        />
      </FlexContainer>

      {item.additionalField === "icon-right" && (
        <Field
          as={Select}
          placeholder="Иконка"
          name={iconName}
          options={iconOptions}
          {...validationUtils.getDeepProps(iconName)}
        />
      )}
      <LegendInput
        placeholder="Первая строка"
        color={item.firstLineColor}
        title={item.firstLine}
        updateColor={(color) => formik.setFieldValue(firstLineColorName, color)}
        updateTitle={(title) => {
          formik.setFieldTouched(firstLineName);
          formik.setFieldValue(firstLineName, title);
        }}
        colors={firstLineColors}
        {...validationUtils.getDeepProps(firstLineName)}
      />
      <LegendInput
        placeholder="Вторая строка"
        color={item.secondLineColor}
        title={item.secondLine}
        updateColor={(color) =>
          formik.setFieldValue(secondLineColorName, color)
        }
        updateTitle={(title) => {
          formik.setFieldTouched(secondLineName);
          formik.setFieldValue(secondLineName, title);
        }}
        colors={otherLinesColors}
        {...validationUtils.getDeepProps(secondLineName)}
      />
      <LegendInput
        placeholder="Третья строка"
        color={item.thirdLineColor}
        title={item.thirdLine}
        updateColor={(color) => formik.setFieldValue(thirdLineColorName, color)}
        updateTitle={(title) => {
          formik.setFieldTouched(thirdLineName);
          formik.setFieldValue(thirdLineName, title);
        }}
        colors={otherLinesColors}
        {...validationUtils.getDeepProps(thirdLineName)}
      />

      {item.additionalField === "accent" && (
        <Field
          as={TextField}
          name={accentName}
          placeholder="Акцент"
          {...validationUtils.getDeepProps(accentName)}
        />
      )}

      <TimingBlock item={item} index={index} />
      {!!formParams && (
        <MapBlock item={item} index={index} formParams={formParams} />
      )}
      <SourceBlock item={item} index={index} />
    </>
  );
}
