import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getDownloadLink } from "../../common/ApiService";
import styles from "./VideoPreview.module.css";

export default function VideoPreview({ taskId }: { taskId: string }) {
  const [previewUrl, setPreviewUrl] = useState("");
  const [showFrame, setShowFrame] = useState(false);
  const dispatch = useDispatch();

  const switchShowFrame = () => {
    setShowFrame(!showFrame);
  };

  useEffect(() => {
    (async () => {
      try {
        const link = await getDownloadLink(taskId, true);
        setPreviewUrl(link);
      } catch (error) {
        dispatch({ type: "GET_PREVIEW_LINK_ERROR", error });
      }
    })();
  }, [dispatch, taskId]);

  return previewUrl ? (
    <div className={styles.container}>
      <button type="button" className={styles.button} onClick={switchShowFrame}>
        {showFrame ? "hide" : "show"} safe frame
      </button>
      {showFrame && (
        <div className={styles.overlay} onClick={switchShowFrame} />
      )}
      <video controls className={styles.video} src={previewUrl} />
    </div>
  ) : null;
}
