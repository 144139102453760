import createSSEMiddleware from "../sseMiddleware";
import {
  initTasksTracking,
  initTaskTrackingById,
  stopTasksTracking,
  stopTaskTrackingById,
} from "./actions";
import { getTaskByIdThunk, getTasksThunk } from "./index";

export const trackTasksMiddleware = createSSEMiddleware({
  urlFromPayload: (payload) => {
    const query = Object.entries(payload).reduce((acc, [key, value], idx) => {
      const prefix = idx === 0 ? "" : "&";
      return key === "showAll" && value
        ? acc + prefix + "loadAll"
        : acc + prefix + `${key}=${value}`;
    }, "");
    return `/track-orders?${query}`;
  },
  initActionType: initTasksTracking.type,
  stopActionType: stopTasksTracking.type,
  fulfilledActionType: getTasksThunk.fulfilled.toString(),
});

export const trackTaskByIdMiddleware = createSSEMiddleware({
  urlFromPayload: (payload) => `/track-order/${payload}`,
  initActionType: initTaskTrackingById.type,
  stopActionType: stopTaskTrackingById.type,
  fulfilledActionType: getTaskByIdThunk.fulfilled.toString(),
});
