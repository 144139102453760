import TextField from "emg-ui-kit/components/TextField";
import Title from "emg-ui-kit/components/Title";
import React from "react";

import FlexContainer from "../../common/FlexContainer";

interface Props {
  title: string;
  total: number;
  delta: number;
  isTotalValid: boolean;
  isDeltaValid: boolean;
  baseName: string;
  setFieldValue: (name: string, value: any) => void;
  handleBlur: (event: React.FocusEvent) => void;
  style?: React.CSSProperties;
}

export default function CountBlock({
  title,
  total,
  delta,
  isTotalValid,
  isDeltaValid,
  baseName,
  setFieldValue,
  handleBlur,
  style,
}: Props) {
  const totalName = `${baseName}.total`;
  const deltaName = `${baseName}.delta`;
  return (
    <div style={style}>
      <Title text={title} style={{ fontSize: 18, marginBottom: 5 }} />
      <FlexContainer>
        <TextField
          type="number"
          name={totalName}
          label="Всего"
          labelType="top"
          value={total.toString()}
          onChange={(event) => setFieldValue(totalName, +event.target.value)}
          onBlur={handleBlur}
          isValid={isTotalValid}
          required
          style={{ flex: 1 }}
        />
        &nbsp;
        <TextField
          type="number"
          name={deltaName}
          label="Динамика"
          labelType="top"
          value={delta.toString()}
          onChange={(event) => setFieldValue(deltaName, +event.target.value)}
          onBlur={handleBlur}
          isValid={isDeltaValid}
          required
          style={{ flex: 1 }}
        />
      </FlexContainer>
    </div>
  );
}
