import React from "react";
import { Map, Placemark, ZoomControl } from "react-yandex-maps";

const zoom = 7;
const defaultCenter = [55.75, 37.57];
const behaviors = ["disable('scrollZoom')"];

interface Props {
  center?: [number, number];
}

function YandexMap(props: Props) {
  const center = props.center ?? defaultCenter;
  return (
    <Map state={{ center, zoom, behaviors }} width="100%">
      <Placemark geometry={center} />
      <ZoomControl options={{ float: "right" }} />
    </Map>
  );
}

export default React.memo(YandexMap);
