import BaseHeader from "emg-ui-kit/components/Header";
import { MenuGroup } from "emg-ui-kit/components/Header/MenuGroup";
import { MenuItem } from "emg-ui-kit/components/Header/MenuItem";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import FlexContainer from "../common/FlexContainer";
import { logoutUserThunk } from "../redux/auth";
import { selectAuth, selectIsAdmin } from "../redux/auth/selectors";
import routes from "../routes";
import styles from "./Header.module.css";
import LogoButton from "./LogoButton";
import NewsButton from "./NewsButton";

interface ActionItem {
  kind: "action";
  title: string;
  action: () => void;
}

export default function Header() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userInfo } = useSelector(selectAuth);
  const isAdmin = useSelector(selectIsAdmin);

  const initRouteItem = (title: string, url: string): ActionItem => {
    return {
      kind: "action",
      title,
      action: () => history.push(url),
    };
  };

  const registerItem = initRouteItem("Регистрация", routes.signup);
  const loginItem = initRouteItem("Вход", routes.index);
  const dashboardItem = initRouteItem("Уведомления", routes.notifications);
  const dataItem = initRouteItem("Данные", routes.atmoDataSync);
  const usersItem = initRouteItem("Пользователи", routes.users);
  const personsItem = initRouteItem("Фотографии", routes.persons);
  const newsItem = initRouteItem("Новости", routes.newsArticles);
  const orderItem = initRouteItem("Сделать заказ", routes.tasksNew);
  const taskListItem = initRouteItem("Список заказов", routes.tasksList);
  const draftListItem = initRouteItem("Черновики", routes.drafts);
  const logoutItem: ActionItem = {
    kind: "action",
    title: "Выйти",
    action: () => dispatch(logoutUserThunk()),
  };

  const menuContents: (MenuGroup | MenuItem)[] = userInfo
    ? [orderItem, taskListItem, draftListItem, logoutItem]
    : [loginItem, registerItem];

  if (isAdmin) {
    menuContents.unshift({
      title: "Администрирование",
      items: [dashboardItem, dataItem, usersItem, personsItem, newsItem],
    });
  }

  return (
    <BaseHeader title={userInfo ? userInfo.email : ""} contents={menuContents}>
      <FlexContainer className={styles.container}>
        <LogoButton />
        {!!userInfo && <NewsButton userGroup={userInfo.groups[0]} />}
      </FlexContainer>
    </BaseHeader>
  );
}
