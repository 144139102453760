import Checkbox from "emg-ui-kit/components/Checkbox";
import ImageInput from "emg-ui-kit/components/ImageInput";
import { FormImage } from "emg-ui-kit/components/ImageInput";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { Field, FormikProvider, useFormik } from "formik";
import React from "react";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import { useIsDesktop } from "../../common/utils";
import yup from "../../common/yup";
import Form from "../Form";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  getValidationProps,
  IMAGE_TYPES,
  MAX_TIMING,
} from "../util";

const IMAGE_ASPECT = 520 / 540;
const MAX_LINES = 2;
const MAX_CHARS_IN_LINE = 55;

const validationSchema = yup.object().shape({
  clipName: yup.string().matches(CLIP_NAME_REGEXP),
  colorScheme: yup.string().required(),
  communicationType: yup.string().required(),
  name: yup.string().required(),
  timing: yup.number().positive().max(MAX_TIMING).required(),
  position: yup.string().maxLines(MAX_LINES).maxChars(MAX_CHARS_IN_LINE),
  image: yup.mixed().aspect(IMAGE_ASPECT).required(),
});

function WheezeForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const initialValues = {
    clipName: (initialFormData?.clipName ?? "") as string,
    colorScheme: (initialFormData?.colorScheme ?? "") as string,
    image: initialFormData?.image as FormImage | undefined,
    communicationType: (initialFormData?.communicationType ?? "") as string,
    name: (initialFormData?.name ?? "") as string,
    position: (initialFormData?.position ?? "") as string,
    timing: (initialFormData?.timing ?? 10) as number,
    hd: initialFormData?.hd ?? false,
    format: (initialFormData?.format ?? "default") as "default" | "premiere",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    onSubmit,
  });

  usePreview(channel, template, formik.values.timing, formik.values);

  const isDesktop = useIsDesktop();

  const buttonProps = {
    isSubmitting: formik.isSubmitting,
    isValid: formik.isValid,
    values: formik.values,
    onSubmit,
    onSaveDraft,
    onDeleteDraft,
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Checkbox
          label="Отсчитать в HD"
          checked={formik.values.hd}
          setChecked={(value) => formik.setFieldValue("hd", value)}
          style={{ ...(isDesktop && { marginLeft: 210 }) }}
        />
        <TextField
          label="Название ролика"
          name="clipName"
          value={formik.values.clipName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          {...getValidationProps("clipName", formik.touched, formik.errors)}
        />
        <Field
          as={Select}
          name="format"
          label="Формат"
          options={[
            { id: "default", name: "По умолчанию" },
            { id: "premiere", name: "Premiere" },
          ]}
        />
        <Select
          name="colorScheme"
          label="Цветовая схема"
          options={[
            { id: "news", name: "News" },
            { id: "format", name: "Format/Traur" },
            { id: "alert", name: "Alert" },
            { id: "night", name: "Night" },
          ]}
          value={formik.values.colorScheme}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isValid={!formik.touched.colorScheme || !formik.errors.colorScheme}
          validationMessage={formik.errors.colorScheme}
          required
        />
        <ImageInput
          imageTypes={IMAGE_TYPES}
          image={formik.values.image}
          updateImage={(image) => {
            formik.setFieldTouched("image");
            formik.setFieldValue("image", image);
          }}
          style={isDesktop ? { marginLeft: 210 } : {}}
          title="Изображение"
          isValid={!formik.touched.image || !formik.errors.image}
          validationMessage={formik.errors.image}
          aspect={IMAGE_ASPECT}
        />
        <TextField
          label="Вид связи"
          name="communicationType"
          value={formik.values.communicationType}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isValid={
            !formik.touched.communicationType ||
            !formik.errors.communicationType
          }
          validationMessage={formik.errors.communicationType}
          required
          dataList={["ПО ТЕЛЕФОНУ", "SKYPE"]}
        />
        <TextField
          label="Имя"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isValid={!formik.touched.name || !formik.errors.name}
          validationMessage={formik.errors.name}
          required
        />
        <TextArea
          label="Должность"
          name="position"
          value={formik.values.position}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          isValid={!formik.touched.position || !formik.errors.position}
          validationMessage={formik.errors.position}
        />
        <TextField
          name="timing"
          type="number"
          label="Хронометраж (сек.)"
          value={formik.values.timing.toString()}
          onChange={(event) => {
            formik.setFieldValue("timing", +event.target.value);
          }}
          onBlur={formik.handleBlur}
          isValid={!formik.touched.timing || !formik.errors.timing}
          validationMessage={formik.errors.timing}
          required
        />
        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(WheezeForm);
