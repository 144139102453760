import Checkbox from "emg-ui-kit/components/Checkbox";
import Select from "emg-ui-kit/components/Select";
import Title from "emg-ui-kit/components/Title";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

import { getOrder } from "../common/ApiService";
import FlexContainer from "../common/FlexContainer";
import PageContainer from "../common/PageContainer";
import PreviewImage from "../common/PreviewImage";
import UnityPlayer from "../common/UnityPlayer";
import { channels, templates as availableTemplates } from "../common/texts";
import { useIsDesktop } from "../common/utils";
import { selectUserInfo } from "../redux/auth/selectors";
import {
  clearFormData,
  selectFormDataBackup,
  setFormData,
} from "../redux/backup";
import {
  createDraftThunk,
  deleteDraftByIdThunk,
  updateDraftThunk,
} from "../redux/drafts";
import { createTaskThunk } from "../redux/tasks";
import routes from "../routes";
import { AppDispatch } from "../store";
import styles from "./OrderForm.module.css";
import PreviewProvider from "./PreviewProvider";
import Spinner from "./Spinner";
import Subform from "./Subform";
import { substituteImageUrlsWithFiles } from "./util";

function isPreviewAvailable(channel: string, template: string) {
  const allTemplates = new Set(
    Object.keys(availableTemplates.m24).filter(
      (template) => !/atmosphere/.test(template)
    )
  );
  return (channel === "m24" && allTemplates.has(template)) || channel === "r24";
}

export default function OrderForm() {
  const [channel, setChannel] = useState("");
  const [templates, setTemplates] = useState<Record<string, string>>({});
  const [template, setTemplate] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const userInfo = useSelector(selectUserInfo);
  const formData = useSelector(selectFormDataBackup);
  const [filesLoading, setFilesLoading] = useState(false);

  const isDesktopOrLaptop = useIsDesktop();
  const shouldSeparatePreview = useMediaQuery({ query: "(min-width: 1200px)" });

  const params = new URLSearchParams(window.location.search);
  const redoId = params.get("redoId");
  const isDraft = params.get("isDraft") === "true";

  const onSubmit = useCallback(
    (data: any) => {
      if (redoId) {
        data = { ...data, status: "draft", draftId: redoId };
      }

      const orderInfo = { channel, template, data };
      dispatch(setFormData(orderInfo));

      return (dispatch(createTaskThunk(orderInfo)) as any).then(
        (action: any) => {
          if (!action.error) {
            history.push("/");
            dispatch(clearFormData());
          }
        }
      );
    },
    [channel, dispatch, history, redoId, template]
  );

  const onSaveDraft = useCallback(
    (data: any) => {
      if (redoId) {
        data = { ...data, status: "draft", draftId: redoId };
      }

      const orderInfo = { channel, template, data };
      dispatch(setFormData(orderInfo));

      if (redoId && isDraft) {
        return (dispatch(updateDraftThunk(orderInfo)) as any).then(
          (action: any) => {
            if (!action.error) {
              history.push("/drafts");
              dispatch(clearFormData());
            }
          }
        );
      }
      return (dispatch(createDraftThunk(orderInfo)) as any).then(
        (action: any) => {
          if (!action.error) {
            history.push("/drafts");
            dispatch(clearFormData());
          }
        }
      );
    },
    [channel, dispatch, history, isDraft, redoId, template]
  );

  const onDeleteDraft = useCallback(() => {
    if (window.confirm("Вы уверены, что хотите удалить черновик?")) {
      if (redoId) {
        return (dispatch(deleteDraftByIdThunk(redoId)) as any).then(() => {
          history.push(routes.drafts);
        });
      }
    }
    return false;
  }, [dispatch, history, redoId]);

  const initialFormData = useRef<Record<string, any>>({});
  const subformProps = {
    initialFormData: initialFormData.current,
    onSubmit,
    onSaveDraft,
    onDeleteDraft: isDraft ? onDeleteDraft : undefined,
    channel,
    template,
  };

  const channelOptions = Object.entries(channels)
    .map(([id, name]) => ({ id, name }))
    .filter(({ id }) => {
      return userInfo && userInfo.groups.length
        ? userInfo.groups.includes(id)
        : true;
    });
  const templateOptions = Object.entries(templates).map(([id, name]) => ({
    id,
    name,
  }));
  const marginCorrection = { marginLeft: isDesktopOrLaptop ? 210 : 0 };

  const prevChannel = useRef("");

  useEffect(() => {
    if (channel) {
      setTemplates(availableTemplates[channel]);
      if (prevChannel.current) {
        setTemplate("");
      }
      prevChannel.current = channel;
    }
  }, [channel]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const channel = params.get("channel");
    const template = params.get("template");
    if (channel && channel in channels) {
      setChannel(channel);
      if (template && template in availableTemplates[channel]) {
        setTemplate(template);
      }
    }
  }, []);

  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      return;
    }
    const params = new URLSearchParams(window.location.search);
    const redoId = params.get("redoId");
    const isDraft = params.get("isDraft");

    if (formData) {
      initialFormData.current = { ...formData.data };
      setChannel(formData.channel);
      setTemplate(formData.template);
      dispatch(clearFormData());
    } else if (redoId) {
      getOrder(redoId)
        .then((order) => {
          setFilesLoading(true);
          return Promise.all([
            substituteImageUrlsWithFiles(order.data),
            Promise.resolve(order.channel),
            Promise.resolve(order.template),
          ]);
        })
        .then(([orderData, channel, template]) => {
          setFilesLoading(false);
          if (isDraft === "true") {
            initialFormData.current = { ...orderData, draftId: redoId };
          } else {
            initialFormData.current = orderData;
          }
          setChannel(channel);
          setTemplate(template);
        })
        .catch(() => {
          setFilesLoading(false);
        });
    }
  }, [history, formData, dispatch]);

  useEffect(() => {
    if (history.location.pathname === routes.tasksNew) {
      setChannel("");
      setTemplate("");
    }
  }, [history.location]);

  const initialRender = useRef(true);
  // useEffect fires AFTER render - it's safe to clean initialFormData up
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      initialFormData.current = {};
    }
  }, [template]);

  useEffect(() => {
    if (!isPreviewAvailable(channel, template)) {
      setShowPreview(false);
    }
  }, [channel, template]);

  useEffect(() => {
    if (template !== "reference") {
      setShowOverlay(false);
    }
  }, [template]);

  return (
    <FlexContainer>
      <PreviewProvider>
        {showPreview && shouldSeparatePreview && (
          <section className={styles.playerSection}>
            <UnityPlayer showOverlay={showOverlay} />
          </section>
        )}
        <section className={styles.formSection}>
          <PageContainer>
            <Title
              text={`Заявка на графику${isDraft ? " (черновик)" : ""}`}
              style={marginCorrection}
            />
            <Select
              label="Проект"
              name="channel"
              options={channelOptions}
              value={channel}
              onChange={(event) => setChannel(event.target.value)}
            />
            <Select
              label="Шаблон"
              name="template"
              options={templateOptions}
              value={template}
              onChange={(event) => setTemplate(event.target.value)}
            />

            {filesLoading && <Spinner />}

            {template && (
              <>
                {isPreviewAvailable(channel, template) && (
                  <Checkbox
                    label="Включить превью"
                    checked={showPreview}
                    setChecked={setShowPreview}
                    style={marginCorrection}
                  />
                )}

                {template === "reference" && showPreview && (
                  <Checkbox
                    label="Показывать оформление канала"
                    checked={showOverlay}
                    setChecked={() => {
                      setShowOverlay(!showOverlay);
                    }}
                    style={marginCorrection}
                  />
                )}

                {showPreview ? (
                  <section style={marginCorrection}>
                    {!shouldSeparatePreview && (
                      <UnityPlayer showOverlay={showOverlay} />
                    )}
                  </section>
                ) : (
                  <PreviewImage
                    src={`/images/${template}.jpeg`}
                    style={marginCorrection}
                    alt="Шаблон"
                  />
                )}
              </>
            )}
            <Subform {...subformProps} />
          </PageContainer>
        </section>
      </PreviewProvider>
    </FlexContainer>
  );
}
