import Button from "emg-ui-kit/components/Button";
import React, { useCallback, useState } from "react";

import FormWarning from "../order/FormWarning";
import FlexContainer from "./FlexContainer";
import { useIsDesktop } from "./utils";

export type ButtonStatus = "loading" | "enabled" | "disabled";

interface OrderSavingButtonsProps {
  isSubmitting?: boolean;
  isValid: boolean;
  prepareData?: (values: any, formParams?: any) => {};
  formParams?: any;
  status?: ButtonStatus;
  values: any;
  onSaveDraft: (values: any) => Promise<void>;
  onDeleteDraft?: () => Promise<void>;
}

export default function OrderSavingButtons({
  isSubmitting,
  isValid,
  prepareData,
  values,
  onSaveDraft,
  onDeleteDraft,
  status,
  formParams,
}: OrderSavingButtonsProps) {
  const isDesktop = useIsDesktop();

  const buttonStatus: ButtonStatus = !status
    ? isSubmitting
      ? "loading"
      : "enabled"
    : status;

  const [isSaveSubmitting, setIsSaveSubmitting] = useState(false);
  const [isDeleteSubmitting, setIsDeleteSubmitting] = useState(false);
  let data: any;
  if (prepareData && formParams) {
    data = { ...prepareData(values, formParams) };
  } else if (prepareData) {
    data = { ...prepareData(values) };
  } else {
    data = { ...values };
  }

  const handleSubmitDraft = useCallback(() => {
    setIsSaveSubmitting(true);
    onSaveDraft(data).catch(() => {
      setIsSaveSubmitting(false);
    });
  }, [data, onSaveDraft]);

  const handleDeleteDraft = useCallback(() => {
    let del;
    setIsDeleteSubmitting(true);
    if (onDeleteDraft) {
      del = onDeleteDraft();
      if (!del) {
        setIsDeleteSubmitting(false);
      }
    }
  }, [onDeleteDraft]);

  const buttonStyle: React.CSSProperties = {
    marginBottom: 10,
    // width: 50,
    // marginLeft: isDesktop ? 15 : "auto",
    paddingLeft: 20,
    paddingRight: 20,
  };
  return (
    <>
      <FlexContainer
        style={{
          flexDirection: isDesktop ? "row" : "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          title="Отправить"
          type="submit"
          status={buttonStatus}
          style={buttonStyle}
        />
        <Button
          type="button"
          title="Сохранить черновик"
          onClick={() => {
            handleSubmitDraft();
          }}
          status={isSaveSubmitting ? "loading" : "enabled"}
          style={buttonStyle}
        />
        {onDeleteDraft && (
          <Button
            title="Удалить черновик"
            onClick={() => {
              handleDeleteDraft();
            }}
            status={isDeleteSubmitting ? "loading" : "enabled"}
            style={buttonStyle}
            color={"red"}
          />
        )}
      </FlexContainer>
      {!isValid && <FormWarning />}
    </>
  );
}
