import React from "react";

import { FormProps } from "../common/models";
import AtmosphereForm from "./atmosphere/AtmosphereForm";
import AtmosphereInfoForm from "./atmosphere/AtmosphereInfoForm";
import CovidGlobeForm from "./m24/CovidGlobeForm";
import CovidInfoForm from "./m24/CovidInfoForm";
import ImportantHourForm from "./m24/ImportantHourForm";
import MemeForm from "./m24/MemeForm";
import NumberForm from "./m24/NumberForm";
import QuoteForm from "./m24/QuoteForm";
import ReferenceForm from "./m24/ReferenceForm";
import SlideShowForm from "./m24/SlideShowForm";
import WheezeForm from "./m24/WheezeForm";
import MapForm from "./map/MapForm";
import ChartColumnsForm from "./r24/ChartColumnsForm";
import ChartLinearDoubleForm from "./r24/ChartLinearDoubleForm";
import ChartLinearForm from "./r24/ChartLinearForm";
import ChartPieForm from "./r24/ChartPieForm";
import ChartPieLarge from "./r24/ChartPieLargeForm";
import ChartRowsForm from "./r24/ChartRowsForm";
import ChartTableForm from "./r24/ChartTableForm";
import GeoForm from "./r24/GeoForm";
import Miniwheeze from "./r24/Miniwheeze";
import PersonQuoteForm from "./r24/PersonQuoteForm";
import RSlideShowForm from "./r24/RSlideShowForm";
import SiteQuoteForm from "./r24/SiteQuoteForm";
import StatementForm from "./r24/StatementForm";
import TiterForm from "./r24/TiterForm";
import VipQuoteForm from "./r24/VipQuoteForm";
import AfficheForm from './m24/AfficheForm'
import WheezeSpecrepForm from "./m24/WheezeSpecrepForm";

export default function Subform(props: FormProps) {
  switch (props.template) {
    case "geo":
      return <GeoForm {...props} />;
    case "titer":
      return <TiterForm {...props} />;
    case "important_hour":
      return <ImportantHourForm {...props} />;
    case "affiche":
      return <AfficheForm {...props} />;
    case "reference":
      return <ReferenceForm {...props} />;
    case "wheeze":
      return <WheezeForm {...props} />;
    case "wheeze_specrep":
      return <WheezeSpecrepForm {...props} />;
    case "quote":
      return <QuoteForm {...props} />;
    case "slideshow":
      return <SlideShowForm {...props} />;
    case "covidinfo":
      return <CovidInfoForm {...props} />;
    case "covidglobe":
      return <CovidGlobeForm {...props} />;
    case "atmosphere":
      return <AtmosphereForm {...props} />;
    case "atmosphere_info":
      return <AtmosphereInfoForm {...props} />;
    case "meme":
      return <MemeForm {...props} />;
    case "number":
      return <NumberForm {...props} />;
    case "rslideshow":
      return <RSlideShowForm {...props} />;
    case "vipquote":
      return <VipQuoteForm {...props} />;
    case "statement":
      return <StatementForm {...props} />;
    case "miniwheeze":
      return <Miniwheeze {...props} />;
    case "chart_linear_double":
      return <ChartLinearDoubleForm {...props} />;
    case "chart_linear":
      return <ChartLinearForm {...props} />;
    case "chart_pie_large":
      return <ChartPieLarge {...props} />;
    case "chart_pie":
      return <ChartPieForm {...props} />;
    case "chart_columns":
      return <ChartColumnsForm {...props} />;
    case "chart_rows":
      return <ChartRowsForm {...props} />;
    case "chart_table":
      return <ChartTableForm {...props} />;
    case "person_quote":
      return <PersonQuoteForm {...props} />;
    case "site_quote":
      return <SiteQuoteForm {...props} />;
    case "map":
      return <MapForm {...props} />;
    default:
      return null;
  }
}
