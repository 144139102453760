import { Field } from "formik";
import TextField from "emg-ui-kit/components/TextField";

interface TimingFieldProps {
  blocksName: string;
  index: number;
  updateItem: (value: { timing: number }) => void;
  touched: any;
  errors: any;
}

export function TimingField({
  blocksName,
  index,
  updateItem,
  touched,
  errors,
}: TimingFieldProps) {
  return (
    <Field
      as={TextField}
      name={`${blocksName}.${index}.timing`}
      label="Хронометраж (сек.)"
      type="number"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        updateItem({ timing: +event.target.value })
      }
      isValid={!touched[blocksName] || !errors[blocksName]?.[index]?.timing}
      validationMessage={errors[blocksName]?.[index]?.timing}
      labelStyle={{ maxWidth: 180 }}
    />
  );
}
