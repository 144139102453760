import { createAsyncThunk, createReducer, isPending } from '@reduxjs/toolkit'
import { deleteDraftById, getDrafts } from '../../common/ApiService';
import { Task } from '../../common/models';
import { FilterParams } from '../../tasks/types';
import { addDraft, updateDraft } from "../../common/ApiService";

export interface TaskEntry extends Task {
    isAccepting?: boolean;
    isCanceling?: boolean;
    isFinishing?: boolean;
}

export interface TasksState {
    byId: Record<string, TaskEntry | undefined>;
    ids: string[];
    isLoading: boolean;
    totalPages: number;
}

const initialState: TasksState = {
    byId: {},
    ids: [],
    isLoading: false,
    totalPages: 1,
};

export const createDraftThunk = createAsyncThunk("draft/create", addDraft);

export const updateDraftThunk = createAsyncThunk("draft/update", updateDraft);

export const getDraftsThunk = createAsyncThunk(
    "drafts/get",
    (arg: FilterParams) => {
        return getDrafts(arg.order, arg.page, arg.perPage, arg.showAll);
    }
);

export const deleteDraftByIdThunk = createAsyncThunk(
    "drafts/delete",
    deleteDraftById
);

const draftsReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(getDraftsThunk.fulfilled, (state, action) => {
            const nextIds = action.payload.orders.map((draft) => draft._id);
            const { page, perPage } = action.meta.arg;
            const hasRequestedFirstPage = page === 1;
            state.totalPages = Math.ceil(action.payload.total / perPage);
            state.isLoading = false;

            if (hasRequestedFirstPage) {
                state.ids = nextIds;
                state.byId = action.payload.orders.reduce<Record<string, Task>>(
                    (acc, task) => {
                        acc[task._id] = task;
                        return acc;
                    },
                    {}
                );
            } else {
                const fromIndex = (page - 1) * perPage;
                action.payload.orders.forEach((draft, index) => {
                    state.byId[draft._id] = draft;
                    state.ids[fromIndex + index] = draft._id;
                });
            }
        })
        .addCase(deleteDraftByIdThunk.fulfilled, (state, action) => {
            state.ids = state.ids.filter(
                (id) => id !== action.payload.id
            );
        })
        .addMatcher(isPending(getDraftsThunk), (state) => {
            state.isLoading = true;
        })
});

export default draftsReducer;