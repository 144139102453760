import Checkbox from "emg-ui-kit/components/Checkbox";
import Radio from "emg-ui-kit/components/Radio";
import { SingleSearch } from "emg-ui-kit/components/Search";
import { Option } from "emg-ui-kit/components/Search/Search";
import Select from "emg-ui-kit/components/Select";
import TextField from "emg-ui-kit/components/TextField";
import { Field, useFormikContext } from "formik";
import React, { useMemo } from "react";

import ColorInput from "../../common/ColorInput";
import { ValidationPropsUtils } from "../util";
import YandexMap from "./YandexMap";
import { Point, PointItem } from "./types";
import {
  CitySearcher,
  countOptions,
  createPoint,
  getCoordsTuple,
} from "./util";

interface Props {
  item: PointItem;
  index: number;
}

export default function PointItemBlock({ index, item }: Props) {
  const formik = useFormikContext();
  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors
  );

  const citySearcher = useMemo(() => new CitySearcher(), []);

  const hasMoreThanOneItem = item.points.length > 1;

  const handleCountChange = (event: React.ChangeEvent<{ value: string }>) => {
    const nextLen = +event.target.value;
    const currentLen = item.points.length;
    const nextPoints =
      nextLen < currentLen
        ? item.points.slice(0, nextLen)
        : item.points.concat(
            Array.from(Array(nextLen - currentLen), createPoint)
          );
    formik.setFieldValue(`items.${index}.points`, nextPoints);
  };

  const handleConnectLinesChange = (value: boolean) => {
    formik.setFieldValue(`items.${index}.connectLines`, value);
  };

  const handleBackgroundColorChange = (color: string) => {
    formik.setFieldValue(`items.${index}.backgroundColor`, color);
  };

  const handleMarkerColorChange = (color: string) => {
    formik.setFieldValue(`items.${index}.markerColor`, color);
  };

  const getSelectedCity = (point: Point) => {
    return point.name ? { id: point.name, value: point.name } : undefined;
  };

  const isFirstPointInList = (pointIndex: number) => {
    return pointIndex === 0;
  };

  const getPointTypeChangeHandler = (pointIndex: number) => (value: string) => {
    return formik.setFieldValue(
      `items.${index}.points.${pointIndex}.type`,
      value
    );
  };

  const getPointTypeRadioProps = (point: Point, pointIndex: number) => {
    return {
      name: `items.${index}.points.${pointIndex}.type`,
      currentValue: point.type,
      setCurrentValue: getPointTypeChangeHandler(pointIndex),
    };
  };

  const getCitySelectHandler = (pointIndex: number) => (option?: Option) => {
    setTimeout(() => {
      formik.setFieldTouched(`items.${index}.points.${pointIndex}.name`);
      formik.setFieldTouched(`items.${index}.points.${pointIndex}.coords`);
    }, 10);
    formik.setFieldValue(
      `items.${index}.points.${pointIndex}.name`,
      option?.value ?? ""
    );
    formik.setFieldValue(
      `items.${index}.points.${pointIndex}.coords`,
      option?.value
        ? citySearcher.coordsByCity.get(option.value)
        : { lat: 0, long: 0 }
    );
  };

  return (
    <>
      <Select
        placeholder="Количество"
        options={countOptions}
        value={item.points.length.toString()}
        onChange={handleCountChange}
      />

      {item.points.map((point, pointIndex) => (
        <section key={pointIndex}>
          {isFirstPointInList(pointIndex) && <hr />}
          <YandexMap center={getCoordsTuple(point.coords)} />

          <Radio
            title="Город"
            value="city"
            {...getPointTypeRadioProps(point, pointIndex)}
          />
          <Radio
            value="coordinates"
            title="Координаты"
            {...getPointTypeRadioProps(point, pointIndex)}
          />

          {point.type === "coordinates" && (
            <>
              <Field
                as={TextField}
                type="number"
                name={`items.${index}.points.${pointIndex}.coords.lat`}
                label="Широта"
                labelStyle={{ flexBasis: 130 }}
                {...validationUtils.getDeepProps(
                  `items.${index}.points.${pointIndex}.coords.lat`
                )}
              />
              <Field
                as={TextField}
                type="number"
                name={`items.${index}.points.${pointIndex}.coords.long`}
                label="Долгота"
                labelStyle={{ flexBasis: 130 }}
                {...validationUtils.getDeepProps(
                  `items.${index}.points.${pointIndex}.coords.long`
                )}
              />
              <Field
                as={TextField}
                name={`items.${index}.points.${pointIndex}.name`}
                placeholder="Название точки"
                {...validationUtils.getDeepProps(
                  `items.${index}.points.${pointIndex}.name`
                )}
              />
            </>
          )}

          {point.type === "city" && (
            <SingleSearch
              placeholder="Город"
              options={citySearcher.search}
              selected={getSelectedCity(point)}
              updateSelected={getCitySelectHandler(pointIndex)}
              {...validationUtils.getDeepProps(
                `items.${index}.points.${pointIndex}.name`
              )}
            />
          )}

          <hr />
        </section>
      ))}
      {hasMoreThanOneItem && (
        <Checkbox
          label="Включить линии соединения точек"
          checked={item.connectLines}
          setChecked={handleConnectLinesChange}
        />
      )}
      <ColorInput
        label="Цвет плашки"
        value={item.backgroundColor}
        updateValue={handleBackgroundColorChange}
        labelStyle={{ flexBasis: 120 }}
      />
      <ColorInput
        label="Цвет маркера"
        value={item.markerColor}
        updateValue={handleMarkerColorChange}
        labelStyle={{ flexBasis: 120 }}
      />
    </>
  );
}
