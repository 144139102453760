import classNames from "classnames";
import React from "react";

import styles from "./PageContainer.module.css";

const PageContainer: React.FunctionComponent<{ className?: string }> = ({
  children,
  className,
}) => <main className={classNames(styles.main, className)}>{children}</main>;

export default PageContainer;
