import Title from "emg-ui-kit/components/Title";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PageContainer from "../../common/PageContainer";
import { selectUserInfo } from "../../redux/auth/selectors";
import { getTasksThunk } from "../../redux/tasks";
import { selectTasks } from "../../redux/tasks/selectors";
import { restoreFilterParams, saveFilterParams } from "../tasksUtils";
import { FilterParams } from "../types";
import { useTasksTracking } from "../useTasksTracking";
import CreateTaskButton from "./CreateTaskButton";
import FilterAndSort from "./FilterAndSort";
import TaskList from "./TaskList";

export default function TaskListPage() {
  const [params, setParams] = useState(restoreFilterParams);
  const { tasks, isLoading } = useSelector(selectTasks);
  const userInfo = useSelector(selectUserInfo)!;

  const loadNext = useCallback(() => {
    setParams((params) => ({ ...params, page: params.page + 1 }));
  }, []);

  const changeParams = useCallback((params: FilterParams) => {
    saveFilterParams(params);
    setParams(params);
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTasksThunk(params));
  }, [dispatch, params]);

  useTasksTracking(params);

  return (
    <PageContainer>
      <Title text="Список заказов" />
      <FilterAndSort
        userInfo={userInfo}
        params={params}
        changeParams={changeParams}
      />
      <TaskList tasks={tasks} isLoading={isLoading} loadNext={loadNext} />
      <CreateTaskButton />
    </PageContainer>
  );
}
