import ImageInput from "emg-ui-kit/components/ImageInput";
import { FormImage } from "emg-ui-kit/components/ImageInput";
import Modal from "emg-ui-kit/components/Modal";
import { SingleSearch } from "emg-ui-kit/components/Search";
import { Option } from "emg-ui-kit/components/Search/Search";
import Select from "emg-ui-kit/components/Select";
import Spinner from "emg-ui-kit/components/Spinner";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { FormikProvider, useFormik, Field } from "formik";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { removeBackgroundFrom } from "../../common/ApiService";
import { FormProps } from "../../common/models";
import OrderSavingButtons from "../../common/OrderSavingButtons";
import { findThemeName, themeByName } from "../../common/texts";
import { convertImageUrlToFile, useIsDesktop } from "../../common/utils";
import yup from "../../common/yup";
import Form from "../Form";
import usePersons from "../usePersons";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  IMAGE_TYPES,
  pick,
  ValidationPropsUtils,
} from "../util";
import styles from "./Miniwheeze.module.css";

const availableThemes = Object.keys(themeByName).filter((name) => {
  return !["Новости Утро", "Экономика Утро", "Спорт Утро", "Погода"].includes(
    name
  );
});

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    name: (initialFormData?.name ?? "") as string,
    communicationType: (initialFormData?.communicationType ?? "") as string,
    image: initialFormData?.image as FormImage | undefined,
    theme: ((initialFormData?.theme &&
      initialFormData?.background &&
      findThemeName(initialFormData.theme, initialFormData.background)) ??
      "") as string,
    author: (initialFormData?.author ?? "") as string,
    position: (initialFormData?.position ?? "") as string,
    location: (initialFormData?.location ?? "") as string,
  };
}

function prepareData(values: ReturnType<typeof getInitialValues>) {
  return {
    ...themeByName[values.theme],
    ...pick(
      values,
      "image",
      "name",
      "communicationType",
      "author",
      "position",
      "location"
    ),
  };
}

const IMAGE_ASPECT = 1;
const FIXED_TIMING = 1;

const validationSchema = yup.object().shape({
  name: yup.string().matches(CLIP_NAME_REGEXP),
  image: yup.mixed().aspect(IMAGE_ASPECT),
  theme: yup.string().required(),
  position: yup.string().required(),
});

function MiniwheezeForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validationSchema,
  });

  const { persons, person, handleUpdatePerson } = usePersons("photoAlpha");

  const personsOptions = useMemo(
    () => persons?.map((person, id) => ({ id, value: person.name })) ?? [],
    [persons]
  );

  const [modalVisible, setModalVisible] = useState(false);

  const handleImageUpdate = (image?: FormImage) => {
    formik.setFieldTouched("image");
    formik.setFieldValue("image", image);
  };

  const dispatch = useDispatch();

  const handleSelect = (option?: Option) => {
    handleUpdatePerson(option);
    const person = persons?.find((person) => person.name === option?.value);
    formik.setFieldValue("author", person?.name ?? "");
    if (person) {
      convertImageUrlToFile(person.photoAlpha)
        .then(handleImageUpdate)
        .catch((err) => {
          dispatch({ type: "CONVERSION_ERROR", error: err });
        });
    } else {
      handleImageUpdate(undefined);
    }
  };

  const removeBackground = () => {
    setModalVisible(true);
    const { image } = formik.values;
    if (image) {
      removeBackgroundFrom(image)
        .then(convertImageUrlToFile)
        .then(handleImageUpdate)
        .catch((err) => {
          dispatch({ type: "IMAGE_TRANSFORM_ERROR", error: err });
        })
        .finally(() => {
          setModalVisible(false);
        });
    }
  };

  usePreview(channel, template, FIXED_TIMING, formik.values, prepareData);

  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors
  );

  const isDesktop = useIsDesktop();
  const buttonProps = {
    isSubmitting: formik.isSubmitting,
    isValid: formik.isValid,
    prepareData,
    values: formik.values,
    onSubmit,
    onSaveDraft,
    onDeleteDraft
  }
  return (
    <FormikProvider value={formik}>
      <Form>
        <Modal
          className={styles.modal}
          visible={modalVisible}
          close={() => setModalVisible(false)}
        >
          <span className={styles.text}>
            Идет обработка изображения, пожалуйста подождите
          </span>
          <Spinner style={{ display: "block", margin: "20 auto" }} />
        </Modal>
        <Field
          as={TextField}
          label="Название ролика"
          name="name"
          {...validationUtils.getProps("name")}
        />
        <Field
          as={TextField}
          label="Вид связи"
          name="communicationType"
          dataList={["ПО ТЕЛЕФОНУ", "SKYPE"]}
        />
        <Field
          as={Select}
          name="theme"
          label="Тема оформления"
          options={availableThemes.map((name) => ({ id: name, name }))}
          {...validationUtils.getProps("theme")}
          required
        />
        <SingleSearch
          label="Выбрать из базы"
          selected={person}
          updateSelected={handleSelect}
          options={personsOptions}
        />
        <ImageInput
          aspect={IMAGE_ASPECT}
          imageTypes={IMAGE_TYPES}
          image={formik.values.image}
          updateImage={handleImageUpdate}
          {...validationUtils.getProps("image")}
          style={{ marginLeft: isDesktop ? 210 : 0 }}
          additionalActions={[{ name: "Убрать фон", action: removeBackground }]}
        />
        <Field as={TextField} label="Автор" name="author" />
        <Field
          as={TextArea}
          label="Должность"
          name="position"
          required
          {...validationUtils.getProps("position")}
        />
        <Field as={TextField} label="Локация" name="location" />
        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(MiniwheezeForm);
