import Blocks from "emg-ui-kit/components/Blocks";
import DateTime from "emg-ui-kit/components/DateTime";
import Select from "emg-ui-kit/components/Select";
import TextField from "emg-ui-kit/components/TextField";
import { Field, FormikErrors, FormikProvider, useFormik } from "formik";
import React from "react";

import { FormProps } from "../../common/models";
import OrderSavingButtons from "../../common/OrderSavingButtons";
import { useIsDesktop } from "../../common/utils";
import yup from "../../common/yup";
import Form from "../Form";
import usePreview from "../usePreview";
import { CLIP_NAME_REGEXP, getValidationProps } from "../util";
import CountBlock from "./CovidCountBlock";

const FIXED_TIMING = 60;

type CovidStats = {
  total: number;
  delta: number;
};

type WorldStats = {
  cases: CovidStats;
  deaths: CovidStats;
  recovered: CovidStats;
};

type CountryStats = { name: string } & WorldStats;

function getStats(): WorldStats {
  return {
    cases: { total: 0, delta: 0 },
    recovered: { total: 0, delta: 0 },
    deaths: { total: 0, delta: 0 },
  };
}

function getCountryStats(): CountryStats {
  return { ...getStats(), name: "" };
}

const videoPath = process.env.REACT_APP_COVID_GLOBE_VIDEO_PATH!;

const statSchema = yup.object().shape({
  total: yup.number().min(0),
  delta: yup.number().min(0),
});
const worldStatSchema = yup.object().shape({
  cases: statSchema,
  recovered: statSchema,
  deaths: statSchema,
});
const countryStatSchema = worldStatSchema.shape({
  name: yup.string().required(),
});
const validationSchema = yup.object().shape({
  clipName: yup.string().matches(CLIP_NAME_REGEXP),
  date: yup.date().required(),
  time: yup
    .string()
    .matches(/\d{2}:\d{2}/)
    .required(),
  world: worldStatSchema,
  countries: yup.array().of(countryStatSchema),
});

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    date: undefined as Date | undefined,
    time: "__:__",
    world: (initialFormData?.world ?? getStats()) as WorldStats,
    countries: (initialFormData?.countries ??
      Array.from(Array(9), getCountryStats)) as CountryStats[],
    format: (initialFormData?.format ?? "default") as "default" | "premiere",
  };
}

type Values = ReturnType<typeof getInitialValues>;

function prepareData(values: Values) {
  return {
    ...values,
    videoPath,
    date:
      values.date && /\d{2}:\d{2}/.test(values.time)
        ? getIsoStringWithTZ(values.date, values.time)
        : "",
  };
}

function CovidGlobeForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    validationSchema,
    onSubmit: (values) => onSubmit(prepareData(values)),
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    isValid,
    isSubmitting,
  } = formik;

  const buttonProps = {
    isSubmitting,
    isValid,
    prepareData,
    values,
    onSubmit,
    onSaveDraft,
    onDeleteDraft
  }

  usePreview(channel, template, FIXED_TIMING, values, prepareData);

  const isDesktopOrLaptop = useIsDesktop();

  return (
    <FormikProvider value={formik}>
      <Form>
        <TextField
          label="Название ролика"
          name="clipName"
          value={values.clipName}
          onChange={handleChange}
          {...getValidationProps("clipName", touched, errors)}
        />
        <Field
          as={Select}
          name="format"
          label="Формат"
          options={[
            { id: "default", name: "По умолчанию" },
            { id: "premiere", name: "Premiere" },
          ]}
        />
        <DateTime
          name="datetime"
          label="Дата и время"
          date={values.date}
          handleDateChange={(date) => {
            setFieldTouched("date");
            setFieldValue("date", date);
          }}
          time={values.time}
          handleTimeChange={(time) => {
            setFieldTouched("time");
            setFieldValue("time", time);
          }}
          isValid={!touched.date || (!errors.date && !errors.time)}
          required
        />
        <TextField
          name="videoPath"
          label="Путь к видео"
          value={videoPath}
          onChange={() => { }}
          disabled
        />

        <Blocks
          items={values.countries}
          updateItems={(countries) => setFieldValue("countries", countries)}
          blockTitle="Страна"
        >
          {(countryStat, index) => {
            const baseName = `countries[${index}]`;
            const countryName = `${baseName}.name`;
            const casesName = `${baseName}.cases`;
            const deathsName = `${baseName}.deaths`;
            const recoveredName = `${baseName}.recovered`;
            return (
              <section>
                <TextField
                  name={countryName}
                  placeholder="Название"
                  value={countryStat.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={
                    !touched.countries?.[index]?.name ||
                    !(errors.countries?.[index] as FormikErrors<CountryStats>)
                      ?.name
                  }
                  validationMessage={
                    (errors.countries?.[index] as FormikErrors<CountryStats>)
                      ?.name
                  }
                />
                <details>
                  <summary style={{ cursor: "pointer" }}>Данные</summary>
                  <CountBlock
                    title="Выявлены"
                    baseName={casesName}
                    total={values.countries[index].cases.total}
                    delta={values.countries[index].cases.delta}
                    isTotalValid={
                      !touched.countries?.[index]?.cases?.total ||
                      !(errors.countries?.[index] as FormikErrors<CountryStats>)
                        ?.cases?.total
                    }
                    isDeltaValid={
                      !touched.countries?.[index]?.cases?.delta ||
                      !(errors.countries?.[index] as FormikErrors<CountryStats>)
                        ?.cases?.delta
                    }
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    style={{ margin: 0 }}
                  />
                  <CountBlock
                    title="Умерли"
                    baseName={deathsName}
                    total={values.countries[index].deaths.total}
                    delta={values.countries[index].deaths.delta}
                    isTotalValid={
                      !touched.countries?.[index]?.deaths?.total ||
                      !(errors.countries?.[index] as FormikErrors<CountryStats>)
                        ?.deaths?.total
                    }
                    isDeltaValid={
                      !touched.countries?.[index]?.deaths?.delta ||
                      !(errors.countries?.[index] as FormikErrors<CountryStats>)
                        ?.deaths?.delta
                    }
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    style={{ margin: 0 }}
                  />
                  <CountBlock
                    title="Выздоровели"
                    baseName={recoveredName}
                    total={values.countries[index].recovered.total}
                    delta={values.countries[index].recovered.delta}
                    isTotalValid={
                      !touched.countries?.[index]?.recovered?.total ||
                      !(errors.countries?.[index] as FormikErrors<CountryStats>)
                        ?.recovered?.total
                    }
                    isDeltaValid={
                      !touched.countries?.[index]?.recovered?.delta ||
                      !(errors.countries?.[index] as FormikErrors<CountryStats>)
                        ?.recovered?.delta
                    }
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    style={{ margin: 0 }}
                  />
                </details>
              </section>
            );
          }}
        </Blocks>

        <br />

        <details style={{ marginLeft: isDesktopOrLaptop ? 210 : 0 }}>
          <summary style={{ cursor: "pointer", outline: "none" }}>
            Данные по миру
          </summary>
          <CountBlock
            title="Выявлены"
            baseName="world.cases"
            total={values.world.cases.total}
            delta={values.world.cases.delta}
            isTotalValid={
              !touched.world?.cases?.total || !errors.world?.cases?.total
            }
            isDeltaValid={
              !touched.world?.cases?.delta || !errors.world?.cases?.delta
            }
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
          />
          <CountBlock
            title="Умерли"
            baseName="world.deaths"
            total={values.world.deaths.total}
            delta={values.world.deaths.delta}
            isTotalValid={
              !touched.world?.deaths?.total || !errors.world?.deaths?.total
            }
            isDeltaValid={
              !touched.world?.deaths?.delta || !errors.world?.deaths?.delta
            }
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
          />
          <CountBlock
            title="Выздоровели"
            baseName="world.recovered"
            total={values.world.recovered.total}
            delta={values.world.recovered.delta}
            isTotalValid={
              !touched.world?.recovered?.total ||
              !errors.world?.recovered?.total
            }
            isDeltaValid={
              !touched.world?.recovered?.delta ||
              !errors.world?.recovered?.delta
            }
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
          />
        </details>

        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(CovidGlobeForm);

export function getIsoStringWithTZ(date: Date, time: string) {
  const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds

  const [hours, minutes] = time.split(":").map(Number);
  const dateCopy = new Date(date);
  dateCopy.setHours(hours);
  dateCopy.setMinutes(minutes);

  return new Date(dateCopy.getTime() - tzoffset).toISOString().slice(0, -1);
}
