import React from "react";

import styles from "./Table.module.css";
import arrowSort from "./arrow_sort.svg";
import arrowSortBlue from "./arrow_sort_blue.svg";

export type Order = "asc" | "desc";

interface SortArrowProps {
  order: Order;
  active: boolean;
}

function SortArrow({ order, active }: SortArrowProps) {
  return (
    <img
      style={{
        marginLeft: 5,
        transform: `rotate(${order === "asc" ? 180 : 0}deg)`,
      }}
      src={active ? arrowSortBlue : arrowSort}
      alt="Sort arrow"
    />
  );
}

function deepAccess(item: Record<string, any>, prop: string) {
  const keys = prop.split(".");
  let target: any = item;
  for (const key of keys) {
    target = target?.[key];
  }
  return target;
}

interface DisplayProp<T> {
  selector: string | ((item: T) => React.ReactNode);
  name: string;
  className?: string;
  headerClassName?: string;
  currentSort?: boolean;
}

export function Table<T extends Record<string, any>>({
  items,
  displayProps,
  onItemClick,
  order,
  changeOrder,
  idPropName,
}: {
  items: T[];
  displayProps: DisplayProp<T>[];
  onItemClick?: (item: T) => void;
  order: Order;
  changeOrder: (order: Order) => void;
  idPropName: string;
}) {
  const switchOrder = () => {
    order === "asc" ? changeOrder("desc") : changeOrder("asc");
  };

  return (
    <table className={styles.table}>
      <thead>
        <tr className={styles.header}>
          {displayProps.map(({ name, currentSort, headerClassName }) => (
            <th key={name} className={headerClassName ?? styles.headerTitle}>
              <button
                className={currentSort ? styles.currentSort : styles.sortButton}
                onClick={currentSort ? switchOrder : undefined}
                disabled={!currentSort}
              >
                {name}
                {currentSort && <SortArrow active order={order} />}
              </button>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <tr key={item[idPropName]} className={styles.rowLink}>
            {displayProps.map(({ selector, className, name }) => (
              <td
                onClick={() => {
                  if (onItemClick) {
                    onItemClick(item);
                  }
                }}
                key={name}
                className={className ?? styles.cell}
              >
                {typeof selector === "string"
                  ? deepAccess(item, selector)
                  : selector(item)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
