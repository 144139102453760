import Blocks from "emg-ui-kit/components/Blocks";
import Select from "emg-ui-kit/components/Select";
import TextField from "emg-ui-kit/components/TextField";
import { Field, FormikProvider, useFormik } from "formik";
import React from "react";
import { YMaps } from "react-yandex-maps";

import { FormProps } from "../../common/models";
import OrderSavingButtons from "../../common/OrderSavingButtons";
import Form from "../Form";

import {
  removeEmptyProps,
  validateInteger,
  validateMax,
  validateMin,
  validateNotEmpty,
  validateNumber,
  validatePositive,
  ValidationPropsUtils,
} from "../util";
import CountryItemBlock from "./CountryItemBlock";
import PointItemBlock from "./PointItemBlock";
import { Item } from "./types";
import {
  blockOptions,
  createCountryItem,
  createItem,
  createPointItem,
  initItems,
} from "./util";

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    items: (initialFormData?.blocks ?? initItems()) as Item[],
  };
}

export type Values = ReturnType<typeof getInitialValues>;

function validate(values: Values) {
  const errors = {
    items: values.items.map((item) => ({
      timing:
        validateNumber(item.timing) ??
        validatePositive(item.timing) ??
        validateInteger(item.timing) ??
        validateMin(item.timing, 5) ??
        validateMax(item.timing, 15),
      ...(item.type === "country" && {
        countries: item.countries.map((country) => ({
          name: validateNotEmpty(country.name),
        })),
      }),
      ...(item.type === "point" && {
        points: item.points.map((point) => ({
          name: validateNotEmpty(point.name),
          coords: {
            lat:
              validateNumber(point.coords.lat) ??
              validateMin(point.coords.lat, -90) ??
              validateMax(point.coords.lat, 90),
            long:
              validateNumber(point.coords.long) ??
              validateMin(point.coords.long, -180) ??
              validateMax(point.coords.long, 180),
          },
        })),
      }),
    })),
  };
  return removeEmptyProps(errors);
}

function prepareData(values: Values) {
  return {
    blocks: values.items,
  };
}

function MapForm({ initialFormData, onSubmit, onSaveDraft,
  onDeleteDraft }: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });
  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors
  );

  const handleUpdateItems = (items: Item[]) => {
    formik.setFieldValue("items", items);
  };

  const getBlockTypeChangeHandler =
    (index: number) => (event: React.ChangeEvent<{ value: string }>) => {
      const blockType = event.target.value;
      formik.setFieldValue(
        `items.${index}`,
        blockType === "country" ? createCountryItem() : createPointItem()
      );
    };

  const buttonProps = {
    isSubmitting: formik.isSubmitting,
    isValid: formik.isValid,
    prepareData,
    values: formik.values,
    onSubmit,
    onSaveDraft,
    onDeleteDraft
  }

  return (
    <YMaps query={{ apikey: process.env.REACT_APP_YANDEX_MAPS_KEY }}>
      <FormikProvider value={formik}>
        <Form>
          <Blocks
            items={formik.values.items}
            updateItems={handleUpdateItems}
            defaultItemConstructor={createItem}
            canChangeLength
            maxBlocks={3}
          >
            {(item, index) => (
              <>
                <Field
                  as={Select}
                  placeholder="Тип блока"
                  name={`items.${index}.type`}
                  options={blockOptions}
                  onChange={getBlockTypeChangeHandler(index)}
                />

                {item.type === "country" && (
                  <CountryItemBlock item={item} index={index} />
                )}
                {item.type === "point" && (
                  <PointItemBlock item={item} index={index} />
                )}

                <Field
                  as={TextField}
                  type="number"
                  name={`items.${index}.timing`}
                  placeholder="Хронометраж"
                  {...validationUtils.getDeepProps(`items.${index}.timing`)}
                />
              </>
            )}
          </Blocks>

          <br />
          <OrderSavingButtons {...buttonProps} />
        </Form>
      </FormikProvider>
    </YMaps>
  );
}

export default React.memo(MapForm);
