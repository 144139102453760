import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";

import { RootState } from "../store";
import styles from "./Alert.module.css";
import alertImage from "./alert.svg";

export default function Alert() {
  const error = useSelector<RootState, { message: string } | undefined>(
    (state) => state.errors.error
  );

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (error) {
      setVisible(true);
    }
  }, [error]);

  const timer = useRef<number>();

  useEffect(() => {
    window.clearTimeout(timer.current);
    if (error) {
      timer.current = window.setTimeout(() => setVisible(false), 10000);
    }
  }, [error]);

  return (
    <div className={styles.container}>
      <CSSTransition
        in={visible}
        timeout={300}
        unmountOnExit
        classNames={{
          enter: styles.alertEnter,
          enterActive: styles.alertEnterActive,
          exit: styles.alertExit,
          exitActive: styles.alertExitActive,
        }}
      >
        <div className={styles.alert} onClick={() => setVisible(false)}>
          <img src={alertImage} alt="Alert icon" style={{ marginRight: 10 }} />
          {error?.message}
        </div>
      </CSSTransition>
    </div>
  );
}
