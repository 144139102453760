import createSSEMiddleware from "../sseMiddleware";
import { initNotificationTracking, stopNotificationTracking } from "./actions";
import { getNotificationThunk } from "./index";

export const trackNotificationMiddleware = createSSEMiddleware({
  urlFromPayload: () => "/track-notification",
  initActionType: initNotificationTracking.type,
  stopActionType: stopNotificationTracking.type,
  fulfilledActionType: getNotificationThunk.fulfilled.toString(),
});
