import Spinner from "emg-ui-kit/components/Spinner";
import TrashButton from "emg-ui-kit/components/TrashButton";
import React from "react";
import { Link } from "react-router-dom";

import { Task } from "../../common/models";
import { channels, templates } from "../../common/texts";
import useCallbackOnVisible from "../../common/useCallbackOnVisible";
import { dateFormatter } from "../../common/utils";
import routes from "../../routes";
import styles from "../TaskListPage/TaskList.module.css";

interface Props {
  tasks: Task[];
  loadNext: () => void;
  isLoading: boolean;
  onDeleteDraftClick?: (id: string) => void;
  isDisabled?: boolean; 
}

const DraftRow = React.forwardRef<HTMLDivElement, { task: Task }>(function (
  { task },
  ref
) {
  return (
    <article ref={ref} className={styles.task}>
      <div className={styles.taskHeader}>
        <div className={styles.project}>{channels[task.channel]}</div>
        <div className={styles.time}>
          {dateFormatter.format(Date.parse(task.creationDate))}
        </div>
      </div>
      <div className={styles.title}>
        {templates[task.channel]?.[task.template] ?? "-"}
      </div>
    </article>
  );
});

export default function DraftList({
  tasks,
  loadNext,
  isLoading,
  onDeleteDraftClick,
  isDisabled,
}: Props) {
  const callbackRef = useCallbackOnVisible(loadNext);
  return (
    <>
      {tasks.map((task, idx) => (
        <div key={"row_" + task._id}>
          <Link
            to={`${routes.draft}?redoId=${task._id}&isDraft=true`}
            key={task._id}
            className={styles.taskLink}
          >
            <DraftRow
              task={task}
              ref={idx === tasks.length - 1 ? callbackRef : null}
            />
          </Link>
          <TrashButton
            key={"trashButton_" + task._id}
            onClick={(e) => {
              e.stopPropagation();
              if (onDeleteDraftClick) onDeleteDraftClick(task._id);
            }}
            status={isDisabled ? "disabled" : "enabled"}
          />
        </div>
      ))}
      <div className={styles.listStatusContainer}>
        {isLoading ? (
          <Spinner />
        ) : (
          !tasks.length && (
            <span className={styles.noOrders}>Нет черновиков</span>
          )
        )}
      </div>
    </>
  );
}
