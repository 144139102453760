import { createAction } from "@reduxjs/toolkit";

import { FilterParams } from "../../tasks/types";

export const initTasksTracking =
  createAction<FilterParams>("tasks/initTracking");

export const stopTasksTracking = createAction("tasks/stopTracking");

export const initTaskTrackingById = createAction<string>(
  "tasks/initTrackingById"
);

export const stopTaskTrackingById = createAction("tasks/stopTrackingById");
