import { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  initTasksTracking,
  initTaskTrackingById,
  stopTasksTracking,
  stopTaskTrackingById,
} from "../redux/tasks/actions";
import { FilterParams } from "./types";

export function useTasksTracking(params: FilterParams) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initTasksTracking(params));
    return () => {
      dispatch(stopTasksTracking());
    };
  }, [dispatch, params]);
}

export function useTaskTrackingById(taskId: string) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initTaskTrackingById(taskId));
    return () => {
      dispatch(stopTaskTrackingById());
    };
  }, [dispatch, taskId]);
}
