import {
  createAsyncThunk,
  createReducer,
  isFulfilled,
  isPending,
} from "@reduxjs/toolkit";

import {
  acceptOrder,
  addOrder,
  cancelOrder,
  finishOrder,
  getOrder,
  getOrders,
} from "../../common/ApiService";
import { Task } from "../../common/models";
import { FilterParams } from "../../tasks/types";

export interface TaskEntry extends Task {
  isAccepting?: boolean;
  isCanceling?: boolean;
  isFinishing?: boolean;
}

export interface TasksState {
  byId: Record<string, TaskEntry | undefined>;
  ids: string[];
  isLoading: boolean;
  totalPages: number;
}

const initialState: TasksState = {
  byId: {},
  ids: [],
  isLoading: false,
  totalPages: 1,
};

export const getTasksThunk = createAsyncThunk(
  "tasks/get",
  (arg: FilterParams) => {
    return getOrders(arg.filter, arg.order, arg.page, arg.perPage, arg.showAll);
  }
);

export const getTaskByIdThunk = createAsyncThunk(
  "tasks/getById",
  (id: string) => {
    return getOrder(id);
  }
);

export const cancelTaskByIdThunk = createAsyncThunk(
  "tasks/cancelById",
  (id: string) => {
    return cancelOrder(id);
  }
);

export const acceptTaskByIdThunk = createAsyncThunk(
  "tasks/acceptById",
  acceptOrder
);

export const finishTaskByIdThunk = createAsyncThunk(
  "tasks/finishById",
  ({ id, outURL }: { id: string; outURL?: string }) => finishOrder(id, outURL)
);

export const createTaskThunk = createAsyncThunk("tasks/create", addOrder);

const tasksReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getTaskByIdThunk.fulfilled, (state) => {
      state.isLoading = false;
    })
    .addCase(cancelTaskByIdThunk.pending, (state, action) => {
      if (state.byId[action.meta.arg]) {
        state.byId[action.meta.arg]!.isCanceling = true;
      }
    })
    .addCase(cancelTaskByIdThunk.fulfilled, (state, action) => {
      if (state.byId[action.meta.arg]) {
        state.byId[action.meta.arg] = action.payload;
      }
    })
    .addCase(getTasksThunk.fulfilled, (state, action) => {
      const nextIds = action.payload.orders.map((task) => task._id);
      const { page, perPage } = action.meta.arg;
      const hasRequestedFirstPage = page === 1;
      state.totalPages = Math.ceil(action.payload.total / perPage);
      state.isLoading = false;

      if (hasRequestedFirstPage) {
        state.ids = nextIds;
        state.byId = action.payload.orders.reduce<Record<string, Task>>(
          (acc, task) => {
            acc[task._id] = task;
            return acc;
          },
          {}
        );
      } else {
        const fromIndex = (page - 1) * perPage;
        action.payload.orders.forEach((task, index) => {
          state.byId[task._id] = task;
          state.ids[fromIndex + index] = task._id;
        });
      }
    })
    .addCase(acceptTaskByIdThunk.pending, (state, action) => {
      if (state.byId[action.meta.arg]) {
        state.byId[action.meta.arg]!.isAccepting = true;
      }
    })
    .addCase(acceptTaskByIdThunk.fulfilled, (state, action) => {
      if (state.byId[action.meta.arg]) {
        state.byId[action.meta.arg] = action.payload;
      }
    })
    .addCase(finishTaskByIdThunk.pending, (state, action) => {
      if (state.byId[action.meta.arg.id]) {
        state.byId[action.meta.arg.id]!.isFinishing = true;
      }
    })
    .addCase(finishTaskByIdThunk.fulfilled, (state, action) => {
      if (state.byId[action.meta.arg.id]) {
        state.byId[action.meta.arg.id] = action.payload;
      }
    })
    .addMatcher(
      isFulfilled(getTaskByIdThunk, cancelTaskByIdThunk),
      (state, action) => {
        state.byId[action.payload._id] = action.payload;
        state.isLoading = false;
      }
    )
    .addMatcher(isPending(getTaskByIdThunk, getTasksThunk), (state) => {
      state.isLoading = true;
    });
});

export default tasksReducer;
