import Blocks from "emg-ui-kit/components/Blocks";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { FormikProvider, useFormik, Field } from "formik";
import React from "react";

import LegendInput from "../../common/LegendInput";
import { FormProps } from "../../common/models";
import OrderSavingButtons from "../../common/OrderSavingButtons";
import { chartColors } from "../../common/texts";
import { convertToSelectOptions } from "../../common/utils";
import yup from "../../common/yup";
import Form from "../Form";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  getDeepValidationProps,
  getValidationProps,
  MAX_TIMING,
} from "../util";
import { THEMES, THEMES_FULL } from "./constants";

interface Item {
  title: string;
  values: string[];
}

function createItem(valuesCount = 1): Item {
  return {
    title: "",
    values: Array(valuesCount).fill(""),
  };
}

function initItems(count = 1) {
  return Array.from(Array(count), createItem);
}

interface Legend {
  title: string;
  color: string;
}

function createLegendItem(index: number): Legend {
  const colorIndex = index % chartColors.length;
  return { title: "", color: chartColors[colorIndex] };
}

function initLegendItems(count = 1, startIndex = 0) {
  return Array.from(Array(count), (_, idx) =>
    createLegendItem(startIndex + idx)
  );
}

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .matches(CLIP_NAME_REGEXP, "Может содержать только буквы и цифры"),
  title: yup.string().required(),
  theme: yup.string().required(),
  timing: yup.number().positive().max(MAX_TIMING),
  items: yup.array().of(
    yup.object().shape({
      values: yup.array().of(
        yup
          .string()
          .required()
          .matches(/^[+-]?\d+(\.\d+)?$/)
      ),
    })
  ),
});

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    name: (initialFormData?.name ?? "") as string,
    theme: (initialFormData?.theme?.id in THEMES
      ? initialFormData?.theme?.id
      : "") as string,
    title: (initialFormData?.title ?? "") as string,
    subtitle: (initialFormData?.subtitle ?? "") as string,
    source: (initialFormData?.source ?? "") as string,
    timing: (initialFormData?.timing ?? 30) as number,
    columnCount: (initialFormData?.legend?.length ?? 1) as number,
    legend: (initialFormData?.legend ?? initLegendItems()) as Legend[],
    items: (initialFormData?.blocks ?? initItems()) as Item[],
  };
}

function prepareData(values: ReturnType<typeof getInitialValues>) {
  return {
    theme: THEMES_FULL[values.theme],
    name: values.name,
    title: values.title,
    subtitle: values.subtitle,
    source: values.source,
    timing: values.timing,
    legend: values.legend,
    blocks: values.items.map((item) => ({
      ...item,
      values: item.values.map((value) => (isNaN(+value) ? 0 : +value)),
    })),
  };
}

function ChartRowsForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    validationSchema,
    onSubmit: (values) => onSubmit(prepareData(values)),
  });
  const {
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    setFieldTouched,
    setFieldValue,
  } = formik;

  usePreview(channel, template, values.timing, values, prepareData);

  const changeColumnCount = (cols: number) => {
    const legendLength = values.legend.length;
    const legendValue =
      cols < legendLength
        ? values.legend.slice(0, cols)
        : [
          ...values.legend,
          ...initLegendItems(cols - legendLength, legendLength),
        ];

    const itemsValue = values.items.map((item) => {
      const values =
        cols < item.values.length
          ? item.values.slice(0, cols)
          : [...item.values, ...Array(cols - item.values.length).fill("")];
      return { ...item, values };
    });

    setFieldValue("columnCount", cols);
    setFieldValue("legend", legendValue);
    setFieldValue("items", itemsValue);
  };

  const buttonProps = {
    isSubmitting,
    isValid,
    prepareData,
    values,
    onSubmit,
    onSaveDraft,
    onDeleteDraft
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          name="name"
          label="Название ролика"
          {...getValidationProps("name", touched, errors)}
        />
        <Field
          as={Select}
          name="theme"
          label="Тема оформления"
          options={convertToSelectOptions(THEMES)}
          required
          {...getValidationProps("theme", touched, errors)}
        />
        <Field
          as={TextArea}
          label="Заголовок"
          name="title"
          rows={2}
          required
          {...getValidationProps("title", touched, errors)}
        />
        <Field as={TextField} label="Подзаголовок" name="subtitle" />
        <Field as={TextField} label="Источник" name="source" />
        <Field
          as={TextField}
          label="Хронометраж (сек)"
          name="timing"
          type="number"
          onChange={(event: any) =>
            setFieldValue("timing", +event.target.value)
          }
          required
          {...getValidationProps("timing", touched, errors)}
        />
        <Field
          as={Select}
          name="columnCount"
          label="Столбцов в блоке"
          options={[1, 2, 3, 4].map((num) => ({
            id: num.toString(),
            name: num.toString(),
          }))}
          onChange={(event: any) => changeColumnCount(+event.target.value)}
        />
        {values.legend.map((item, idx) => (
          <LegendInput
            key={idx}
            label={`Легенда ${idx + 1}`}
            color={item.color}
            title={item.title}
            colors={chartColors}
            updateColor={(color) =>
              setFieldValue(`legend[${idx}].color`, color)
            }
            updateTitle={(title) =>
              setFieldValue(`legend[${idx}].title`, title)
            }
            maxLines={2}
          />
        ))}
        <Blocks
          items={values.items}
          updateItems={(items) => setFieldValue("items", items)}
          defaultItemConstructor={() => createItem(values.columnCount)}
          blockTitle="Элемент"
          canChangeLength
        >
          {(item, index) => (
            <>
              <Field
                as={TextField}
                placeholder="Название"
                name={`items[${index}].title`}
              />
              {item.values.map((val, idx) => (
                <Field
                  as={TextField}
                  key={idx}
                  placeholder={`Значение ${idx + 1}`}
                  name={`items[${index}].values[${idx}]`}
                  onChange={(event: any) => {
                    const value = event.target.value;
                    if (/[^\-.0-9]/.test(value)) return;
                    const key = `items[${index}].values[${idx}]`;
                    setFieldTouched(key);
                    setFieldValue(key, value);
                  }}
                  {...getDeepValidationProps(
                    `items[${index}].values[${idx}]`,
                    touched,
                    errors
                  )}
                />
              ))}
            </>
          )}
        </Blocks>
        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(ChartRowsForm);
