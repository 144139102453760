import { useCallback, useContext, useEffect, useRef } from "react";

import { PreviewContext } from "./PreviewContext";

export default function usePreview(
  channel: string,
  template: string,
  timing: number,
  rawData: Record<string, any>,
  prepareData?: (data: any) => any
) {
  const { setData, setTiming } = useContext(PreviewContext);

  const prevImages = useRef<
    Record<string, File | undefined | (File | undefined)[]>
  >({});
  const imageCache = useRef<Record<string, string | string[]>>({});

  const clearImages = useCallback(() => {
    for (const value of Object.values(imageCache.current)) {
      if (Array.isArray(value)) {
        value.forEach((val) => URL.revokeObjectURL(val));
      } else {
        URL.revokeObjectURL(value);
      }
    }
  }, []);

  useEffect(() => clearImages, [clearImages]);

  useEffect(() => {
    const imageKeys = ["image", "mainImage", "photo"];
    for (const key of imageKeys) {
      if (key in rawData) {
        if (prevImages.current[key] !== rawData[key]) {
          URL.revokeObjectURL(imageCache.current[key] as string);
          imageCache.current[key] =
            rawData[key] instanceof File
              ? URL.createObjectURL(rawData[key])
              : "";
          prevImages.current[key] = rawData[key];
        }
      }
    }

    if (Array.isArray(rawData.items)) {
      if (!Array.isArray(prevImages.current.images)) {
        prevImages.current.images = Array(rawData.items.length).fill(undefined);
      }
      if (!Array.isArray(imageCache.current.images)) {
        imageCache.current.images = Array(rawData.items.length).fill("");
      }

      for (const [idx, item] of rawData.items.entries()) {
        if ("image" in item) {
          if (prevImages.current.images[idx] !== item.image) {
            URL.revokeObjectURL(imageCache.current.images[idx]);
            imageCache.current.images[idx] =
              item.image instanceof File ? URL.createObjectURL(item.image) : "";
            prevImages.current.images[idx] = item.image;
          }
        }
      }
    }

    // update preview
    const previewData = { ...rawData };
    for (const imageKey of imageKeys) {
      if (imageKey in previewData) {
        previewData[imageKey] = imageCache.current[imageKey];
      }
    }
    if ("items" in previewData) {
      previewData.items = previewData.items.slice();
      for (const [idx, item] of previewData.items.entries()) {
        if ("image" in item) {
          previewData.items[idx] = {
            ...item,
            image: imageCache.current.images[idx],
          };
        }
      }
    }

    setData({
      channel,
      template,
      data: prepareData?.(previewData) ?? previewData,
    });
  }, [channel, template, rawData, prepareData, setData]);

  useEffect(() => {
    setTiming(timing);
  }, [timing, setTiming]);
}
