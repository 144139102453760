import { useEffect, useRef } from "react";

import { filters } from "../common/texts";
import { FilterParams } from "./types";

export function restoreFilter() {
  const filter = sessionStorage.getItem("filter");
  return filter && filter in filters ? filter : "all";
}

export function restoreShowAll() {
  const showAll = sessionStorage.getItem("showAll");
  return !!showAll;
}

export function restoreOrder() {
  const order = sessionStorage.getItem("order");
  return order && (order === "desc" || order === "asc") ? order : "desc";
}

export function restoreFilterParams(): FilterParams {
  return {
    filter: restoreFilter(),
    order: restoreOrder(),
    page: parseInt(sessionStorage.getItem("page") || "1"),
    perPage: 20,
    showAll: restoreShowAll(),
  };
}

export function saveFilterParams(params: FilterParams) {
  sessionStorage.setItem("filter", params.filter);
  sessionStorage.setItem("order", params.order);
  sessionStorage.setItem("page", params.page.toString());
  if (params.showAll) {
    sessionStorage.setItem("showAll", "true");
  } else {
    sessionStorage.removeItem("showAll");
  }
}

type TrackingOptions = {
  filter: string;
  order: "asc" | "desc";
  page: number;
  perPage: number;
  showAll: boolean;
};

export function useOrdersTracking<T>(
  options: TrackingOptions,
  setOrders: (orders: T) => void
) {
  const eventSource = useRef<EventSource>();
  const checkInterval = useRef<number>();

  const query = Object.entries(options).reduce((acc, [key, value], idx) => {
    const prefix = idx === 0 ? "" : "&";
    return key === "showAll" && value
      ? acc + prefix + "loadAll"
      : acc + prefix + `${key}=${value}`;
  }, "");

  useEffect(() => {
    const initEventSource = () => {
      const eventSource = new EventSource(`/track-orders?${query}`, {
        withCredentials: true,
      });
      eventSource.onerror = (error) => {
        console.error(error);
        eventSource.close();
      };
      eventSource.onmessage = (event) => {
        const { orders } = JSON.parse(event.data);
        setOrders(orders);
      };
      return eventSource;
    };

    eventSource.current = initEventSource();
    checkInterval.current = window.setInterval(() => {
      if (
        !eventSource.current ||
        eventSource.current.readyState === eventSource.current.CLOSED
      ) {
        eventSource.current?.close();
        eventSource.current = initEventSource();
      }
    }, 60000);

    return () => {
      eventSource.current?.close();
      window.clearInterval(checkInterval.current);
    };
  }, [query, setOrders]);
}

export function useOrderTracking<T>(
  orderId: string,
  currentStatus: string | undefined,
  setOrder: (order: T) => void
) {
  const eventSource = useRef<EventSource>();
  const checkInterval = useRef<number>();

  useEffect(() => {
    const initEventSource = () => {
      const eventSource = new EventSource(`/track-order/${orderId}`, {
        withCredentials: true,
      });
      eventSource.onerror = (error) => {
        console.error(error);
        eventSource.close();
      };
      eventSource.onmessage = (event) => {
        const order = JSON.parse(event.data);
        if (typeof order === "object") {
          setOrder(order);
        }
      };
      return eventSource;
    };

    if (
      currentStatus &&
      currentStatus !== "done" &&
      currentStatus !== "failed"
    ) {
      eventSource.current = initEventSource();
      checkInterval.current = window.setInterval(() => {
        if (
          !eventSource.current ||
          eventSource.current.readyState === eventSource.current.CLOSED
        ) {
          eventSource.current?.close();
          eventSource.current = initEventSource();
        }
      }, 60000);
    }

    return () => {
      eventSource.current?.close();
      window.clearInterval(checkInterval.current);
    };
  }, [orderId, currentStatus, setOrder]);
}
