import classNames from "classnames";
import React, { useRef, useState } from "react";
import { GithubPicker } from "react-color";

import styles from "./ColorPicker.module.css";
import nocolorIcon from "./nocolor.svg";
import whiteIcon from "./white.svg";

interface ColorButtonProps {
  color: string;
  updateColor: (color: string) => void;
  colors: string[];
  pickerPosition: "left" | "right";
  className?: string;
}

export function ColorButton(props: ColorButtonProps) {
  const [pickerVisible, setPickerVisible] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null!);

  const handleClick = () => {
    buttonRef.current.focus();
    setPickerVisible(!pickerVisible);
  };

  const handleBlur = (event: React.FocusEvent) => {
    if (!buttonRef.current.contains(event.relatedTarget as Node | null)) {
      setPickerVisible(false);
    }
  };

  return (
    <button
      type="button"
      ref={buttonRef}
      onClick={handleClick}
      onBlur={handleBlur}
      className={classNames(styles.button, props.className)}
    >
      <div
        className={styles.colorBox}
        style={
          props.color === "transparent"
            ? { backgroundImage: `url(${nocolorIcon})` }
            : props.color === "#ffffff"
            ? { backgroundImage: `url(${whiteIcon})` }
            : { backgroundColor: props.color }
        }
      />
      {pickerVisible && (
        <div
          className={classNames(
            styles.pickerContainer,
            styles[`pickerContainer--${props.pickerPosition}`]
          )}
        >
          <GithubPicker
            triangle={
              props.pickerPosition === "left" ? "top-left" : "top-right"
            }
            colors={props.colors}
            color={props.color}
            onChange={(color, event) => {
              event.stopPropagation();
              props.updateColor(color.hex);
              setPickerVisible(false);
            }}
          />
        </div>
      )}
    </button>
  );
}

interface Props {
  color: string;
  updateColor: (color: string) => void;
  colors: string[];
  label?: string;
  isValid?: boolean;
  validationMessage?: string;
  style?: React.CSSProperties;
}

export default function ColorPicker(props: Props) {
  return (
    <div className={styles.container} style={props.style}>
      <label className={styles.label}>{props.label}</label>
      <ColorButton
        color={props.color}
        updateColor={props.updateColor}
        colors={props.colors}
        pickerPosition="left"
        className={styles.styledButton}
      />
      {props.isValid === false && props.validationMessage && (
        <div className={styles.error}>{props.validationMessage}</div>
      )}
    </div>
  );
}
