import classNames from "classnames";
import Button from "emg-ui-kit/components/Button";
import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import routes from "../../routes";
import styles from "./TaskTable.module.css";

type Filters = Record<string, string>;

type FilterGroup = {
  name?: string;
  filters: Filters;
};

interface Props {
  filter: string | undefined;
  setFilter: (filter: string) => void;
  filterGroups: FilterGroup[];
}

export default function SideMenu({ filter, setFilter, filterGroups }: Props) {
  const firstRender = useRef(true);
  const detailsRef = useRef<Record<string, HTMLElement | null>>({});

  useEffect(() => {
    if (firstRender.current) {
      // find appropriate detail element and set it's open prop
      const group = filterGroups.find(
        (group) => filter && filter in group.filters
      );
      if (group && group.name) {
        detailsRef.current[group.name]?.setAttribute("open", "");
      }
    }
    firstRender.current = false;
  }, [filter, filterGroups]);

  const history = useHistory();

  const handleFilterChange = (filterId: string) => {
    setFilter(filterId);
  };

  return (
    <aside className={styles.sidebar}>
      <Button
        title="Сделать заказ"
        onClick={() => {
          history.push(routes.tasksNew);
        }}
      />
      {filterGroups.map((group) => {
        const filterList = Object.entries(group.filters).map(([key, name]) => (
          <button
            key={key}
            onClick={() => handleFilterChange(key)}
            className={classNames(styles.filterLink, {
              [styles.filterLinkCurrent]: filter === key,
            })}
          >
            {name}
          </button>
        ));
        return group.name ? (
          <details
            key={group.name}
            className={styles.filterGroup}
            ref={(node) => {
              detailsRef.current = {
                ...detailsRef.current,
                [group.name!]: node,
              };
            }}
          >
            <summary className={styles.filterGroupName}>{group.name}</summary>
            <div className={styles.filterGroupList}>{filterList}</div>
          </details>
        ) : (
          filterList
        );
      })}
    </aside>
  );
}
