import { FormImage } from "emg-ui-kit/components/ImageInput";
import { isAspectWithinRange } from "emg-ui-kit/components/utils";

import { convertImageUrlToFile } from "../common/utils";
import messages from "./messages";

export function validateNotEmpty(value: any) {
  if (!value) {
    return messages.empty;
  }
}

export function validateNumber(value: any) {
  if (typeof value !== "number") {
    return messages.number;
  }
}

export function validateInteger(value: number) {
  if (!Number.isInteger(value)) {
    return messages.integer;
  }
}

export function validatePositive(value: number) {
  if (value <= 0) {
    return messages.positive;
  }
}

export function validateZeroOrMoreZero(value: number) {
  if (value < 0) {
    return messages.positive;
  }
}

export function validateNonNegative(value: number) {
  if (value < 0) {
    return messages.negative;
  }
}

export function validateMin(value: number, min: number) {
  if (value < min) {
    return messages.min(min);
  }
}

export function validateDelaysSum(value: number, max: number) {
  if (value > max) {
    return messages.delaysSum(value, max);
  }
}

export function validateMax(value: number, max: number) {
  if (value > max) {
    return messages.max(max);
  }
}

export function validateAspect(value: FormImage, aspect: number) {
  if (!isAspectWithinRange(aspect, value.aspect)) {
    return messages.aspect;
  }
}

export function validateMaxChars(value: string, limit: number) {
  if (value.length > limit) {
    return messages.maxChars(limit);
  }
}

export function validateMaxLines(value: string, limit: number) {
  if (!hasLessLines(value, limit)) {
    return messages.maxLines(limit);
  }
}

export function validateMaxCharsInLine(
  value: string,
  limit: number,
  charsToIgnore: RegExp[]
) {
  const str = charsToIgnore.reduce<string>((acc, char) => {
    return acc.replace(new RegExp(char, "g"), "");
  }, value);
  if (!hasLessCharsInLine(str, limit)) {
    return messages.maxCharsInLine(limit);
  }
}

export function validateText(
  value: string,
  lineLimit: number,
  charLimit: number,
  charsToIgnore: RegExp[] = []
) {
  return (
    validateMaxLines(value, lineLimit) ??
    validateMaxCharsInLine(value, charLimit, charsToIgnore)
  );
}

export function validateIncorrectFormat(value: string, regexp: RegExp) {
  if (!regexp.test(value)) {
    return messages.incorrectFormat;
  }
}

export function validateTimingSum(value: number, limit: number) {
  if (value > limit) {
    return messages.timingSum(limit);
  }
}

export function hasLessLines(str: string, limit: number) {
  return str.split("\n").length <= limit;
}

export function hasLessCharsInLine(str: string, limit: number) {
  return str.split("\n").every((line) => line.length <= limit);
}

export function removeEmptyProps(obj: Record<string, any>) {
  for (const [key, value] of Object.entries(obj)) {
    if (value === undefined) {
      delete obj[key];
    } else if (Array.isArray(value)) {
      if (!value.length) {
        delete obj[key];
      } else if (typeof value[0] === "object") {
        value.forEach(removeEmptyProps);
        if (value.every(isEmptyObject)) {
          delete obj[key];
        }
      } else {
        if (value.every((val) => typeof val === "undefined")) {
          delete obj[key];
        }
      }
    } else if (value && typeof value === "object") {
      removeEmptyProps(value);
      if (isEmptyObject(value)) {
        delete obj[key];
      }
    }
  }
  return obj;
}

function isEmptyObject(obj: Record<string, any>) {
  return Object.keys(obj).length === 0;
}

export const MAX_TIMING = 300;

export const IMAGE_TYPES = ["jpeg", "png"];

export const CLIP_NAME_REGEXP = /^[а-яА-Яa-zA-Z0-9 _.,-]+$/;

export async function substituteImageUrlsWithFiles(
  orderData: Record<string, any>
) {
  const isUrl = (value: any) => typeof value === "string" && value;

  const imageKeys = ["image", "mainImage", "photo"];
  for (const key of imageKeys) {
    if (isUrl(orderData[key])) {
      try {
        orderData[key] = await convertImageUrlToFile(orderData[key]);
      } catch (error) {
        orderData[key] = undefined;
      }
    }
  }

  if (Array.isArray(orderData.blocks)) {
    for (const block of orderData.blocks) {
      if (isUrl(block.image)) {
        try {
          block.image = await convertImageUrlToFile(block.image);
        } catch (error) {
          block.image = undefined;
        }
      }
    }
  }

  return orderData;
}

export function pick<T, K extends keyof T>(obj: T, ...keys: K[]) {
  const picked = {} as Pick<T, K>;
  for (const key of keys) {
    picked[key] = obj[key];
  }
  return picked;
}

export const getValidationProps = <T>(
  name: keyof T,
  touched: { [P in keyof T]?: any },
  errors: { [P in keyof T]?: any }
) => ({
  isValid: !touched[name] || !errors[name],
  validationMessage: errors[name],
});

const getDeepProp = (key: string, obj: any) => {
  const props = key.replace(/\[/g, ".").replace(/]/g, "").split(".");
  return props.reduce((acc, key) => acc?.[key], obj);
};

export const getDeepValidationProps = <T>(
  name: string,
  touched: { [P in keyof T]?: any },
  errors: { [P in keyof T]?: any }
) => ({
  isValid: !getDeepProp(name, touched) || !getDeepProp(name, errors),
  validationMessage: getDeepProp(name, errors),
});

type WithKeys<T> = { [P in keyof T]?: any };

export class ValidationPropsUtils<T> {
  touched: WithKeys<T>;
  errors: WithKeys<T>;

  constructor(touched: WithKeys<T>, errors: WithKeys<T>) {
    this.touched = touched;
    this.errors = errors;
  }

  getProps = (name: keyof T) => ({
    isValid: !this.touched[name] || !this.errors[name],
    validationMessage: this.errors[name],
  });

  getDeepProps = (name: string) => ({
    isValid:
      !getDeepProp(name, this.touched) || !getDeepProp(name, this.errors),
    validationMessage: getDeepProp(name, this.errors),
  });
}

export function getItemFieldName(index: number, name: string) {
  return `items.${index}.${name}`;
}
