import Radio from "emg-ui-kit/components/Radio";
import Select from "emg-ui-kit/components/Select";
import { Field, useFormikContext } from "formik";
import React from "react";

import { AtmosphereFormParams } from "../../../common/ApiService";
import FlexContainer from "../../../common/FlexContainer";
import { convertToSelectOptions } from "../../../common/utils";
import { getItemFieldName, ValidationPropsUtils } from "../../util";
import { windDirections, timeshiftChoice } from "../constants";
import { ExtendedItemBase } from "../types";
import { findLocation } from "../utils";

interface Props {
  item: ExtendedItemBase;
  index: number;
  formParams: AtmosphereFormParams;
  disabled?: boolean;
}

const interactiveMapTypes = [
  { id: "thermo", name: "Термокарта" },
  { id: "cyclone", name: "Циклоны-Антициклоны" },
  { id: "fronts", name: "Фронты ЕТР" },
  { id: "wind", name: "Карты с направлением ветра" },
  { id: "gismeteo", name: "Гисметео" },
];

const interactiveDayTimes = [
  { id: "day", name: "День" },
  { id: "evening", name: "Вечер" },
  { id: "night", name: "Ночь" },
];

const windOptions = convertToSelectOptions(windDirections);
const timeshiftOptions = convertToSelectOptions(timeshiftChoice)

export default function MapBlock({ item, index, formParams, disabled }: Props) {
  const formik = useFormikContext();
  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors
  );
  const mapVariantName = getItemFieldName(index, "mapVariant");
  const mapName = getItemFieldName(index, "map");
  const locationName = getItemFieldName(index, "location");
  const weatherName = getItemFieldName(index, "weather");
  const timeOfDayName = getItemFieldName(index, "timeOfDay");
  const interactiveVariantName = getItemFieldName(index, "interactiveVariant");
  const windDirectionName = getItemFieldName(index, "interactiveWindDirection");
  const timeshiftName = getItemFieldName(index, "timeshift")

  const videoEffectOptions = [
    { id: "empty", name: "Без видеоэффекта" },
    ...formParams.videoEffects.map(({ name }) => {
      return { id: name, name };
    }),
  ];

  const mapOptions = formParams.mapTypes.map(({ name }) => {
    return { id: name, name };
  });

  const locationOptions = Object.entries(formParams.locationTypes).map(
    ([label, options]) => {
      return {
        label,
        options: options.map(({ name }, idx) => ({
          id: `${label}_${idx}`,
          name,
        })),
      };
    }
  );

  const timeOfDayOptions =
    item.location && item.weather
      ? findLocation(item.location, formParams)
        .availableVariants[item.weather].filter(
          (variant) => variant in formParams.timesOfDay
        )
        .map((type) => ({
          id: type,
          name: formParams.timesOfDay[type],
        }))
      : [];

  const weatherOptions = item.location
    ? Object.keys(
      findLocation(item.location, formParams).availableVariants
    ).map((type) => ({ id: type, name: type }))
    : [];

  const updateWeather = (weather: string) => {
    if (formParams) {
      const location = findLocation(item.location!, formParams);
      const { timeOfDay } = item;
      let nextItem: ExtendedItemBase;

      if (
        timeOfDay &&
        location.availableVariants[weather].includes(timeOfDay)
      ) {
        nextItem = { ...item, weather };
      } else {
        nextItem = { ...item, weather, timeOfDay: "" };
      }

      formik.setFieldValue(`items.${index}`, nextItem);
    }
  };

  const updateLocation = (value: string) => {
    if (formParams) {
      const location = findLocation(value, formParams);
      let nextItem: ExtendedItemBase;
      if (item.weather && item.weather in location.availableVariants) {
        if (
          item.timeOfDay &&
          location.availableVariants[item.weather].includes(item.timeOfDay)
        ) {
          nextItem = { ...item, location: value };
        }
        nextItem = { ...item, location: value, timeOfDay: "" };
      } else {
        nextItem = { ...item, location: value, weather: "", timeOfDay: "" };
      }
      formik.setFieldValue(`items.${index}`, nextItem);
    }
  };

  const updateMapVariant = (value: string) => {
    const nextItem = { ...item, mapVariant: value };
    if (value === "2D") {
      delete nextItem.location;
      delete nextItem.weather;
      delete nextItem.timeOfDay;
      delete nextItem.videoEffect;
      delete nextItem.interactiveVariant;
      delete nextItem.interactiveWindDirection;
      nextItem.map = "";
    } else if (value === "3D") {
      delete nextItem.map;
      delete nextItem.interactiveVariant;
      delete nextItem.interactiveWindDirection;
      nextItem.location = "";
      nextItem.weather = "";
      nextItem.timeOfDay = "";
      nextItem.videoEffect = "empty";
    } else if (value === "interactive") {
      delete nextItem.map;
      delete nextItem.location;
      delete nextItem.weather;
      delete nextItem.videoEffect;
      nextItem.source = "";
      nextItem.interactiveVariant = interactiveMapTypes[0].id;
      nextItem.interactiveWindDirection = windOptions[0].id;
      nextItem.timeOfDay = interactiveDayTimes[0].id;
    }
    formik.setFieldValue(`items.${index}`, nextItem);
  };

  const updateInteractiveMapVariant = (value: string) => {
    const nextItem = { ...item, interactiveVariant: value };
    if (value === 'gismeteo') {
      nextItem.timeshift = timeshiftOptions[0].id
    } else {
      delete nextItem.timeshift
    }
    formik.setFieldValue(`items.${index}`, nextItem);
  };

  const mapRadioProps = {
    name: mapVariantName,
    currentValue: item.mapVariant,
    setCurrentValue: updateMapVariant,
    style: { marginTop: "0.5rem", flexBasis: 100 },
    disabled,
  };

  return (
    <>
      <div>Вариант карты</div>
      <FlexContainer>
        <Radio {...mapRadioProps} value="2D" title="2D" />
        <Radio {...mapRadioProps} value="3D" title="3D" />
        <Radio
          {...mapRadioProps}
          style={{ marginTop: "0.5rem", flexBasis: "auto" }}
          value="interactive"
          title="Интерактивная"
        />
      </FlexContainer>

      {item.mapVariant === "2D" && (
        <Field
          as={Select}
          placeholder="Карта"
          name={mapName}
          options={mapOptions}
          {...validationUtils.getDeepProps(mapName)}
        />
      )}

      {item.mapVariant === "3D" && (
        <>
          <Field
            as={Select}
            placeholder="Локация"
            name={locationName}
            options={locationOptions}
            onChange={(event: any) => updateLocation(event.target.value)}
            {...validationUtils.getDeepProps(locationName)}
            disabled={disabled}
          />
          <Field
            as={Select}
            placeholder="Погода"
            name={weatherName}
            options={weatherOptions}
            onChange={(event: any) => updateWeather(event.target.value)}
            {...validationUtils.getDeepProps(weatherName)}
            disabled={disabled ?? !item.location}
          />
          <Field
            as={Select}
            placeholder="Время суток"
            name={timeOfDayName}
            options={timeOfDayOptions}
            {...validationUtils.getDeepProps(timeOfDayName)}
            disabled={disabled ?? !item.weather}
          />

          <Field
            as={Select}
            placeholder="Видеоэффект"
            name={getItemFieldName(index, "videoEffect")}
            options={videoEffectOptions}
          />
        </>
      )}

      {item.mapVariant === "interactive" && (
        <>
          <Field
            as={Select}
            name={interactiveVariantName}
            options={interactiveMapTypes}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => { updateInteractiveMapVariant(event.target.value) }}
          />
          <Field
            as={Select}
            placeholder="Время суток"
            name={timeOfDayName}
            options={interactiveDayTimes}
          />
          {item.interactiveVariant === "wind" && (
            <Field
              as={Select}
              placeholder="Направление ветра"
              name={windDirectionName}
              options={windOptions}
            />
          )}
          {item.timing === '5' && item.interactiveVariant === 'gismeteo' &&
            < Field
              as={Select}
              placeholder="5 секунд"
              name={timeshiftName}
              options={timeshiftOptions}
            />}
        </>
      )}
    </>
  );
}
