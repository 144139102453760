import Button from "emg-ui-kit/components/Button";
import Select from "emg-ui-kit/components/Select";
import TextField from "emg-ui-kit/components/TextField";
import Title from "emg-ui-kit/components/Title";
import { Field, Form, FormikProvider, useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import BackButton from "../common/BackButton";
import PageContainer from "../common/PageContainer";
import { convertToSelectOptions } from "../common/utils";
import yup from "../common/yup";
import { ValidationPropsUtils } from "../order/util";
import { createUserThunk } from "../redux/users";
import routes from "../routes";
import { AppDispatch } from "../store";
import { groups, userTypes } from "./userConstants";

const userTypeOptions = convertToSelectOptions(userTypes);
const groupOptions = convertToSelectOptions(groups);

const initialValues = {
  email: "",
  password: "",
  type: "",
  group: "",
  catalogUrl: "",
};

const validationSchema = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().required().min(6),
  type: yup.string().required(),
  catalogUrl: yup.string().url(),
  group: yup.string().required(),
});

export default function UserNewForm() {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(createUserThunk(values)).then((result) => {
        if ("error" in result) return;
        history.push(routes.users);
      });
    },
  });

  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors
  );

  return (
    <PageContainer>
      <BackButton to={routes.users} />
      <Title text="Новый пользователь" />
      <FormikProvider value={formik}>
        <Form>
          <Field
            as={TextField}
            type="email"
            label="Email"
            name="email"
            required
            {...validationUtils.getProps("email")}
          />
          <Field
            as={TextField}
            type="password"
            label="Пароль"
            name="password"
            required
            {...validationUtils.getProps("password")}
          />
          <Field
            as={Select}
            name="type"
            label="Тип пользователя"
            options={userTypeOptions}
            required
            {...validationUtils.getProps("type")}
          />
          <Field
            as={Select}
            name="group"
            label="Группа"
            options={groupOptions}
            required
            {...validationUtils.getProps("group")}
          />
          <Field
            as={TextField}
            name="catalogUrl"
            label="Каталог графики"
            {...validationUtils.getProps("catalogUrl")}
          />
          <Button
            title="Добавить"
            type="submit"
            status={formik.isSubmitting ? "loading" : "enabled"}
          />
        </Form>
      </FormikProvider>
    </PageContainer>
  );
}
