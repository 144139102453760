import Checkbox from "emg-ui-kit/components/Checkbox";
import Select from "emg-ui-kit/components/Select";
import React from "react";

import { UserInfo } from "../../common/ApiService";
import { channels, filterGroups } from "../../common/texts";
import { convertToSelectOptions } from "../../common/utils";
import { FilterParams } from "../types";
import styles from "./TaskList.module.css";

function getAvailableFilterGroups(userInfo: UserInfo) {
  const groupNames = userInfo.groups.map((group) => channels[group]);
  return userInfo.groups.length
    ? filterGroups.filter((filterGroup) => {
        if (!filterGroup.name) {
          return true;
        } else if (groupNames.includes(filterGroup.name)) {
          return true;
        }
        return false;
      })
    : filterGroups;
}

const orderTypes = [
  {
    order: "desc",
    name: "По дате изменения: сначала новые",
  },
  {
    order: "asc",
    name: "По дате изменения: сначала старые",
  },
];

interface Props {
  userInfo: UserInfo;
  params: FilterParams;
  changeParams: (params: FilterParams) => void;
}

export default function FilterAndSort({
  userInfo,
  params,
  changeParams,
}: Props) {
  const availableFilterGroups = getAvailableFilterGroups(userInfo);

  const changeParam = <T extends keyof FilterParams>(
    param: T,
    value: FilterParams[T]
  ) => {
    const nextParams =
      param === "page"
        ? { ...params, [param]: value }
        : { ...params, [param]: value, page: 1 };
    changeParams(nextParams);
  };

  return (
    <>
      <div className={styles.filtersContainer}>
        <Select
          name="taskType"
          options={availableFilterGroups.map((group) => ({
            label: group.name ?? "",
            options: convertToSelectOptions(group.filters),
          }))}
          value={params.filter}
          onChange={({ target: { value } }) => changeParam("filter", value)}
          className={styles.filter}
        />

        <select
          value={params.order}
          onChange={({ target: { value } }) => changeParam("order", value)}
          className={styles.imgSelect}
        >
          <option value="" disabled>
            -- Сортировать --
          </option>
          {orderTypes.map((sort) => (
            <option key={sort.order} value={sort.order}>
              {sort.name}
            </option>
          ))}
        </select>
      </div>

      {userInfo.type === "admin" && (
        <Checkbox
          label="Показывать все заказы"
          checked={params.showAll}
          setChecked={(value) => changeParam("showAll", value)}
        />
      )}
    </>
  );
}
