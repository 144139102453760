import Select from "emg-ui-kit/components/Select";
import TextField from "emg-ui-kit/components/TextField";
import { Field, FormikProvider, useFormik } from "formik";
import React from "react";

import { FormProps } from "../../common/models";
import OrderSavingButtons from "../../common/OrderSavingButtons";
import { covidInfoTypes } from "../../common/texts";
import { useIsDesktop } from "../../common/utils";
import yup from "../../common/yup";
import Form from "../Form";
import usePreview from "../usePreview";
import { CLIP_NAME_REGEXP, getValidationProps } from "../util";
import CountBlock from "./CovidCountBlock";

const FIXED_TIMING = 10;

const getDefaultStats = () => ({ total: 0, delta: 0 });

type Stats = ReturnType<typeof getDefaultStats>;

const statSchema = yup.object().shape({
  total: yup.number().min(0),
  delta: yup.number().min(0),
});
const validationSchema = yup.object().shape({
  clipName: yup.string().matches(CLIP_NAME_REGEXP),
  type: yup.string().required(),
  source: yup.string().required(),
  cases: statSchema,
  recovered: statSchema,
  deaths: statSchema,
});

function CovidInfoForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const initialValues = {
    clipName: (initialFormData?.clipName ?? "") as string,
    type: (initialFormData?.type ?? "") as string,
    source: (initialFormData?.source ?? "") as string,
    cases: (initialFormData?.cases ?? getDefaultStats()) as Stats,
    recovered: (initialFormData?.recovered ?? getDefaultStats()) as Stats,
    deaths: (initialFormData?.deaths ?? getDefaultStats()) as Stats,
    format: (initialFormData?.format ?? "default") as "default" | "premiere",
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    isValid,
    setFieldValue,
  } = formik;

  usePreview(channel, template, FIXED_TIMING, values);

  const isDesktopOrLaptop = useIsDesktop();
  const covidBlockStyle = { marginLeft: isDesktopOrLaptop ? 210 : 0 };
  const buttonProps = {
    isSubmitting,
    isValid,
    values,
    onSaveDraft,
    onDeleteDraft
  }
  return (
    <FormikProvider value={formik}>
      <Form>
        <TextField
          label="Название ролика"
          name="clipName"
          value={values.clipName}
          onChange={handleChange}
          onBlur={handleBlur}
          {...getValidationProps("clipName", touched, errors)}
        />
        <Field
          as={Select}
          name="format"
          label="Формат"
          options={[
            { id: "default", name: "По умолчанию" },
            { id: "premiere", name: "Premiere" },
          ]}
        />
        <Select
          label="Тип"
          name="type"
          options={Object.entries(covidInfoTypes).map(([id, name]) => ({
            id,
            name,
          }))}
          value={values.type}
          onChange={handleChange}
          onBlur={handleBlur}
          isValid={!touched.type || !errors.type}
          validationMessage={errors.type}
          required
        />
        <TextField
          name="source"
          label="Источник"
          value={values.source}
          onChange={handleChange}
          onBlur={handleBlur}
          isValid={!touched.source || !errors.source}
          validationMessage={errors.source}
          required
        />
        <CountBlock
          title="Выявлены"
          baseName="cases"
          total={values.cases.total}
          delta={values.cases.delta}
          isTotalValid={!touched.cases?.total || !errors.cases?.total}
          isDeltaValid={!touched.cases?.delta || !errors.cases?.delta}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          style={covidBlockStyle}
        />
        <CountBlock
          title="Выздоровели"
          baseName="recovered"
          total={values.recovered.total}
          delta={values.recovered.delta}
          isTotalValid={!touched.recovered?.total || !errors.recovered?.total}
          isDeltaValid={!touched.recovered?.delta || !errors.recovered?.delta}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          style={covidBlockStyle}
        />
        <CountBlock
          title="Умерли"
          baseName="deaths"
          total={values.deaths.total}
          delta={values.deaths.delta}
          isTotalValid={!touched.deaths?.total || !errors.deaths?.total}
          isDeltaValid={!touched.deaths?.delta || !errors.deaths?.delta}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          style={covidBlockStyle}
        />
        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(CovidInfoForm);
