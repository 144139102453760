import React, { useCallback } from "react";

import { Task } from "../../common/models";
import styles from "./TaskTable.module.css";

interface Props {
  tasks: Task[];
  page: number;
  perPage: number;
  totalPages: number;
  changePage: (page: number) => void;
}

export default function Pagination({
  tasks,
  page,
  perPage,
  totalPages,
  changePage,
}: Props) {
  const nextPage = useCallback(() => {
    changePage(page + 1);
  }, [changePage, page]);

  const prevPage = useCallback(() => {
    changePage(page - 1);
  }, [changePage, page]);

  const from = (page - 1) * perPage + 1;
  const to = (page - 1) * perPage + tasks.length;

  if (!tasks.length) return null;
  return (
    <div className={styles.paginationBlock}>
      <span className={styles.stepCounterTitle}>
        Заказов на странице:{" "}
        <select disabled className={styles.stepCounter}>
          <option>{perPage}</option>
        </select>
      </span>
      <button
        disabled={page === 1}
        className={styles.prevButton}
        onClick={prevPage}
      />
      <span>
        {from}-{to}
      </span>
      <button
        disabled={totalPages === page}
        className={styles.nextButton}
        onClick={nextPage}
      />
    </div>
  );
}
