import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import Unity, { UnityContent } from "react-unity-webgl";

import { PreviewContext } from "../order/PreviewContext";
import styles from "./UnityPlayer.module.css";

const unityContent = new UnityContent(
  window.location.origin + "/unity/RenderPreview.json",
  window.location.origin + "/unity/UnityLoader.js"
);

function send(gameObj: string, method: string, param: any) {
  if (process.env.REACT_APP_IS_STAGING) {
    console.log(
      "%cRENDER PREVIEW",
      "background: slateblue; padding: 2px",
      `| ${method} | ${param}`
    );
  }
  unityContent.send(gameObj, method, param);
}

function sendTemplateData(data: string) {
  send("WebAgent", "ShowTemplate", data);
}

function sendFrame(frame: number) {
  send("WebAgent", "SetFrame", frame);
}

function convertToFrames(timing: number) {
  return timing * 25;
}

function initFrame(totalTiming: number) {
  return Math.floor(convertToFrames(totalTiming) / 2);
}

export default function UnityPlayer({ showOverlay }: { showOverlay: boolean }) {
  const { data: templateData, timing: totalTiming } =
    useContext(PreviewContext);

  templateData.data.showOverlay = showOverlay;

  const prevTemplate = useRef<string>(templateData.template ?? "");

  const [currentFrame, setCurrentFrame] = useState(() =>
    initFrame(totalTiming)
  );
  const [ready, setReady] = useState(false);

  const templateDataString = useMemo(
    () =>
      JSON.stringify({
        ...templateData,
        data: { ...templateData.data, showOverlay },
      }),

    [templateData, showOverlay]
  );

  useEffect(() => {
    if (ready) {
      sendFrame(currentFrame);
    }
  }, [ready, currentFrame]);

  useEffect(() => {
    if (ready) {
      sendTemplateData(templateDataString);
    }
  }, [ready, templateDataString]);

  //JUST FOR SHOWING IN CONSOLE
  useEffect(() => {
    console.log(templateDataString);
  }, [templateDataString]);

  useEffect(() => {
    unityContent.on("loaded", () => {
      setReady(true);
    });
  }, []);

  useEffect(() => {
    unityContent.on("SceneReady", () => {
      sendFrame(initFrame(totalTiming));
    });
  }, [totalTiming]);

  useEffect(() => {
    if (prevTemplate.current !== templateData.template) {
      const frame = initFrame(totalTiming);
      setCurrentFrame(frame);
      sendFrame(frame);
      prevTemplate.current = templateData.template;
    }
  }, [templateData.template, totalTiming, setCurrentFrame]);

  if (!Object.values(templateData).length) return null;
  return (
    <>
      <div className={styles.container}>
        <Unity unityContent={unityContent} className={styles.unityWrapper} />

        <input
          type="range"
          name="frame"
          value={currentFrame}
          onChange={(event) => setCurrentFrame(event.target.valueAsNumber)}
          min={0}
          max={convertToFrames(totalTiming)}
          className={styles.slider}
        />
      </div>
    </>
  );
}
