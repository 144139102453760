import Button from "emg-ui-kit/components/Button";
import TextField from "emg-ui-kit/components/TextField";
import Title from "emg-ui-kit/components/Title";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import PageContainer from "../common/PageContainer";
import { useIsDesktop } from "../common/utils";
import { loginUserThunk } from "../redux/auth";
import routes from "../routes";
import styles from "./LoginForm.module.css";

export default function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!email || !password) return;
    dispatch(loginUserThunk({ email, password }));
  };

  const isDesktop = useIsDesktop();

  return (
    <PageContainer>
      <Title
        text="Авторизация"
        style={{ ...(isDesktop && { marginLeft: 210 }) }}
      />
      <form onSubmit={handleSubmit}>
        <TextField
          type="email"
          label="Логин"
          name="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <TextField
          type="password"
          label="Пароль"
          name="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
        <Button
          title="Войти"
          type="submit"
          status={email && password ? "enabled" : "disabled"}
        />
        <Link to={routes.signup} className={styles.link}>
          Зарегистрироваться
        </Link>
      </form>
    </PageContainer>
  );
}
