import Select from "emg-ui-kit/components/Select";
import { useFormikContext } from "formik";
import React from "react";

import { AtmosphereFormParams } from "../../../../common/ApiService";
import PreviewImage from "../../../../common/PreviewImage";
import { getItemFieldName, ValidationPropsUtils } from "../../../util";
import Details from "../../components/Details";
import { atmoNumberBlockOptions } from "../../constants";
import { AtmoItem, ExtendedItemBase } from "../../types";
import ExtendedBlock from "./ExtendedBlock";
import FeelsLikeBlock from "./FeelsLikeBlock";
import ForecastBlock from "./ForecastBlock";
import PressureBlock from "./PressureBlock";
import TextBlock from "./TextBlock";
import WindBlock from "./WindBlock";

interface Props {
  item: AtmoItem;
  index: number;
  formParams?: AtmosphereFormParams;
}

export default function AtmoBlock({ item, index, formParams }: Props) {
  const formik = useFormikContext();

  const changeBlockType = (blockType: string) => {
    const base = { timing: item.timing, source: item.source };
    let extendedBase: ExtendedItemBase = {
      ...base,
      mapVariant: "3D",
      location: "",
      weather: "",
      timeOfDay: "",
      videoEffect: "empty",
    };
    if ("mapVariant" in item && item.mapVariant === "2D") {
      extendedBase = {
        ...extendedBase,
        mapVariant: item.mapVariant,
        map: item.map,
      };
    } else if ("mapVariant" in item && item.mapVariant === "3D") {
      extendedBase = {
        ...extendedBase,
        mapVariant: item.mapVariant,
        location: item.location,
        weather: item.weather,
        timeOfDay: item.timeOfDay,
      };
    }

    if ("videoEffect" in item) {
      extendedBase.videoEffect = item.videoEffect;
    }

    let nextItem: AtmoItem;
    switch (blockType) {
      case "text":
        nextItem = {
          ...extendedBase,
          blockType: "text",
          firstLine: "",
          secondLine: "",
          thirdLine: "",
          thirdLineColor: "transparent",
        };
        break;
      case "feelsLike":
        nextItem = {
          ...extendedBase,
          blockType: "feelsLike",
          temperature: "",
        };
        break;
      case "wind":
        nextItem = {
          ...extendedBase,
          blockType: "wind",
          windDirection: "",
          windSpeed: "",
        };
        break;
      case "pressure":
        nextItem = {
          ...extendedBase,
          blockType: "pressure",
          pressure: 0,
        };
        break;
      case "forecast":
        nextItem = {
          ...extendedBase,
          blockType: "forecast",
          forecastTime: "",
          temperature: "",
          forecastDescription: "",
        };
        break;
      default:
        nextItem = item;
    }

    formik.setFieldValue(`items.${index}`, nextItem);
  };

  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors
  );

  const blockTypeName = getItemFieldName(index, "blockType");

  return (
    <>
      <Select
        placeholder="Тип блока"
        name={blockTypeName}
        options={atmoNumberBlockOptions}
        value={item.blockType}
        onChange={(event) => changeBlockType(event.target.value)}
        onBlur={formik.handleBlur}
        {...validationUtils.getDeepProps(blockTypeName)}
      />
      {item.blockType !== "" && (
        <>
          <PreviewImage src={`/images/atmosphere/${item.blockType}.png`} />
          <Details>
            {(() => {
              switch (item.blockType) {
                case "text":
                  return <TextBlock item={item} index={index} />;
                case "feelsLike":
                  return <FeelsLikeBlock item={item} index={index} />;
                case "wind":
                  return <WindBlock item={item} index={index} />;
                case "pressure":
                  return <PressureBlock item={item} index={index} />;
                case "forecast":
                  return <ForecastBlock item={item} index={index} />;
              }
            })()}
            {formParams !== undefined && (
              <ExtendedBlock
                item={item}
                index={index}
                formParams={formParams}
              />
            )}
          </Details>
        </>
      )}
    </>
  );
}
