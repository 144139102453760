import Checkbox from "emg-ui-kit/components/Checkbox";
import Title from "emg-ui-kit/components/Title";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import PageContainer from "../../common/PageContainer";
import { Order, Table } from "../../common/Table";
import { Task } from "../../common/models";
import {
  channels,
  filterGroups,
  findThemeName,
  templates,
} from "../../common/texts";
import { dateFormatter } from "../../common/utils";
import { selectUserInfo } from "../../redux/auth/selectors";
import { getTasksThunk } from "../../redux/tasks";
import { selectTasksByPage } from "../../redux/tasks/selectors";
import routes from "../../routes";
import StatusBadge from "../StatusBadge";
import { restoreFilterParams, saveFilterParams } from "../tasksUtils";
import { FilterParams } from "../types";
import { useTasksTracking } from "../useTasksTracking";
import Pagination from "./Pagination";
import SideMenu from "./SideMenu";
import Status from "./Status";
import styles from "./TaskTable.module.css";

const getTemplate = (task: Task) =>
  templates[task.channel]?.[task.template] ?? "-";
const getTheme = (task: Task) =>
  task.data.theme?.name ??
  findThemeName(task.data.theme, task.data.background) ??
  "-";

const getDate = (task: Task) =>
  dateFormatter.format(Date.parse(task.creationDate));
const getStatus = (task: Task) => (
  <StatusBadge status={task.status} progress={task.progress} renderProgress={task.renderProgress} />
);
const getClipName = (task: Task) => task.data.clipName || task.data.name || "-";

export default function TaskTablePage() {
  const [params, setParams] = useState(restoreFilterParams);
  const { tasks, isLoading, totalPages } = useSelector(
    selectTasksByPage(params.page, params.perPage)
  );
  const userInfo = useSelector(selectUserInfo)!;
  const history = useHistory();

  const changeParams = useCallback((params: FilterParams) => {
    saveFilterParams(params);
    setParams(params);
  }, []);

  const changeParam = <T extends keyof FilterParams>(
    param: T,
    value: FilterParams[T]
  ) => {
    const nextParams =
      param === "page"
        ? { ...params, [param]: value }
        : { ...params, [param]: value, page: 1 };
    changeParams(nextParams);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTasksThunk(params));
  }, [dispatch, params]);

  useTasksTracking(params);

  const isR24 = userInfo.groups.includes("r24");

  return (
    <PageContainer className={styles.pageContainer}>
      <SideMenu
        filter={params.filter}
        setFilter={(value) => changeParam("filter", value)}
        filterGroups={
          userInfo.groups.length
            ? filterGroups.filter((group) => {
              const groupNames = userInfo.groups.map(
                (group) => channels[group]
              );
              if (!group.name) return true;
              else return groupNames.includes(group.name);
            })
            : filterGroups
        }
      />
      <section className={styles.workspace}>
        <Title text="Список заказов" />
        {userInfo.type === "admin" && (
          <Checkbox
            label="Показывать все заказы"
            checked={params.showAll}
            setChecked={(value) => changeParam("showAll", value)}
          />
        )}
        <Table
          items={tasks}
          displayProps={[
            {
              name: "Шаблон",
              selector: getTemplate,
              className: styles.taskTitle,
            },
            ...(params.showAll
              ? [{ name: "Постановщик задачи", selector: "email" }]
              : []),
            ...(isR24
              ? [
                { name: "Оформление", selector: getTheme },
                { name: "Название", selector: "data.name" },
              ]
              : [{ name: "Название ролика", selector: getClipName }]),
            { name: "Статус", selector: getStatus },
            {
              name: "Время заказа",
              selector: getDate,
              className: styles.dateCell,
              currentSort: true,
            },
          ]}
          onItemClick={(task) =>
            history.push(`${routes.tasksList}/${task._id}`)
          }
          order={params.order as Order}
          changeOrder={(value) => changeParam("order", value)}
          idPropName="_id"
        />
        <Status isLoading={isLoading} tasksLength={tasks.length} />
        <Pagination
          tasks={tasks}
          page={params.page}
          perPage={params.perPage}
          totalPages={totalPages}
          changePage={(value) => changeParam("page", value)}
        />
      </section>
    </PageContainer>
  );
}
