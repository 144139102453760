import TimeInput from "emg-ui-kit/components/DateTime/TimeInput";
import Radio from "emg-ui-kit/components/Radio";
import TextField from "emg-ui-kit/components/TextField";
import { useFormikContext, Field } from "formik";
import React from "react";

import { AtmosphereFormParams } from "../../../../common/ApiService";
import FlexContainer from "../../../../common/FlexContainer";
import { ValidationPropsUtils, getItemFieldName } from "../../../util";
import { SunriseSunsetItem } from "../../types";
import { getSunriseSunsetLocations } from "../../utils";
import MapBlock from "../MapBlock";
import SourceBlock from "../SourceBlock";
import TimingBlock from "../TimingBlock";

interface Props {
  item: SunriseSunsetItem;
  index: number;
  formParams?: AtmosphereFormParams;
}

export default function SunriseSunsetBlock({ item, index, formParams }: Props) {
  const formik = useFormikContext();
  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors
  );

  const handleTypeChange = (type: string) => {
    const locations = getSunriseSunsetLocations(formParams!);
    const location = locations[type][item.timing];
    const [weather] = Object.keys(location.availableVariants);
    const [timeOfDay] = location.availableVariants[weather];
    const nextItem = {
      ...item,
      type,
      location: location.id,
      weather,
      timeOfDay,
    };
    formik.setFieldValue(`items.${index}`, nextItem);
  };

  const handleTimingChange = (timing: string) => {
    const locations = getSunriseSunsetLocations(formParams!);
    const location = locations[item.type][timing];
    const [weather] = Object.keys(location.availableVariants);
    const [timeOfDay] = location.availableVariants[weather];
    const nextItem = {
      ...item,
      timing,
      location: location.id,
      weather,
      timeOfDay,
    };
    formik.setFieldValue(`items.${index}`, nextItem);
  };

  const typeRadioProps = {
    name: getItemFieldName(index, "type"),
    currentValue: item.type,
    setCurrentValue: handleTypeChange,
    style: { flexBasis: 100, marginTop: 0 },
  };

  const timeName = getItemFieldName(index, "time");

  const additionalFieldName = getItemFieldName(index, "additionalField");
  const additionalRadioProps = {
    name: additionalFieldName,
    currentValue: item.additionalField,
    setCurrentValue: (value: string) =>
      formik.setFieldValue(additionalFieldName, value),
    value: "none",
    style: { margin: "0.5rem 0 0 0" },
  };

  const firstLineName = getItemFieldName(index, "firstLine");
  const secondLineName = getItemFieldName(index, "secondLine");
  const thirdLineName = getItemFieldName(index, "thirdLine");

  return (
    <>
      <FlexContainer>
        <Radio {...typeRadioProps} value="sunrise" title="Восход" />
        <Radio {...typeRadioProps} value="sunset" title="Заход" />
      </FlexContainer>

      <FlexContainer
        style={{
          alignItems: "baseline",
          marginBottom: "1rem",
        }}
      >
        <div style={{ flexBasis: 90 }}>Время</div>
        <TimeInput
          name={timeName}
          time={item.time}
          onChange={(value) => formik.setFieldValue(timeName, value)}
          isValid={validationUtils.getDeepProps(timeName).isValid}
        />
      </FlexContainer>

      <div>Дополнительно</div>
      <FlexContainer style={{ flexDirection: "column" }}>
        <Radio {...additionalRadioProps} value="none" title="Без текста" />
        <Radio {...additionalRadioProps} value="one-line" title="Одна строка" />
        <Radio
          {...additionalRadioProps}
          value="change"
          title="Со сменой"
          style={{ margin: "0.5rem 0" }}
        />
      </FlexContainer>

      {item.additionalField !== "none" && (
        <Field
          as={TextField}
          placeholder="Первая строка"
          name={firstLineName}
        />
      )}
      {item.additionalField === "change" && (
        <>
          <Field
            as={TextField}
            placeholder="Вторая строка"
            name={secondLineName}
          />
          <Field
            as={TextField}
            placeholder="Третья строка"
            name={thirdLineName}
          />
        </>
      )}

      <TimingBlock item={item} index={index} onChange={handleTimingChange} />
      {!!formParams && (
        <MapBlock item={item} index={index} formParams={formParams} disabled />
      )}
      <SourceBlock item={item} index={index} />
    </>
  );
}
