import Spinner from "emg-ui-kit/components/Spinner";
import React from "react";

import { useIsDesktop } from "../common/utils";
import styles from "./Spinner.module.css";

export default function CenteredSpinner() {
  const isDesktopOrLaptop = useIsDesktop();
  return (
    <div
      className={styles.container}
      style={{ ...(isDesktopOrLaptop && { marginLeft: 210 }) }}
    >
      <Spinner />
    </div>
  );
}
