import Blocks from "emg-ui-kit/components/Blocks";
import ImageInput from "emg-ui-kit/components/ImageInput";
import { FormImage } from "emg-ui-kit/components/ImageInput";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { Field, FormikErrors, FormikProvider, useFormik } from "formik";
import React, { useMemo } from "react";

import { FormProps } from "../../common/models";
import OrderSavingButtons from "../../common/OrderSavingButtons";
import Form from "../Form";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  getValidationProps,
  IMAGE_TYPES,
  MAX_TIMING,
  removeEmptyProps,
  validateIncorrectFormat,
  validateMax,
  validateNotEmpty,
  validatePositive,
  validateText,
  validateTimingSum,
} from "../util";
import { designOptions } from "./constants";

type Item = {
  title: string;
  comment: string;
  source: string;
  sourceColor: string;
  timing: number;
  image?: FormImage;
};

function createItem(): Item {
  return {
    title: "",
    comment: "",
    source: "",
    sourceColor: "gray",
    timing: 0,
    image: undefined,
  };
}

function initItems(count = 1) {
  return Array.from(Array(count), createItem);
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    timing: (initialFormData?.totalTiming ?? 0) as number,
    timingType: (initialFormData?.equalTimings
      ? "total"
      : "separate") as string,
    title: (initialFormData?.title ?? "") as string,
    titleType: (initialFormData?.title !== undefined
      ? "total"
      : "separate") as string,
    comment: (initialFormData?.comment ?? "") as string,
    commentType: (initialFormData?.comment !== undefined
      ? "total"
      : "separate") as string,
    source: (initialFormData?.source ?? "") as string,
    sourceColor: (initialFormData?.sourceColor ?? "gray") as string,
    sourceType: (initialFormData?.source !== undefined
      ? "total"
      : "separate") as string,
    items: (initialFormData?.blocks?.map((block: any) => {
      return block.timing ? block : { ...block, timing: 0 };
    }) ?? initItems()) as Item[],
    format: (initialFormData?.format ?? "default") as "default" | "premiere",
    design: (initialFormData?.design ?? "news") as "specrep" | "news",
  };
}

const selectOptions = [
  { id: "total", name: "Общий" },
  { id: "separate", name: "Для каждого слайда" },
];

type Values = ReturnType<typeof getInitialValues>;

function validate(values: Values) {
  const errors = {
    clipName: values.clipName
      ? validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP)
      : undefined,
    comment:
      values.commentType === "total" && values.comment
        ? validateText(values.comment, 2, 50)
        : undefined,
    timing:
      values.timingType === "total"
        ? validatePositive(values.timing) ??
        validateMax(values.timing, MAX_TIMING)
        : undefined,
    items: values.items.map((item) => ({
      image: validateNotEmpty(item.image),
      timing:
        values.timingType === "separate"
          ? validatePositive(item.timing) ??
          validateTimingSum(calcTotalTiming(values), MAX_TIMING)
          : undefined,
      comment: item.comment ? validateText(item.comment, 2, 50) : undefined,
    })),
  };
  return removeEmptyProps(errors);
}

function calcTotalTiming(values: Values) {
  return values.timingType === "total"
    ? values.timing
    : values.items.reduce((acc, item) => acc + item.timing, 0);
}

function prepareData(values: Values) {
  return {
    clipName: values.clipName,
    format: values.format,
    ...(values.titleType === "total" && { title: values.title }),
    ...(values.commentType === "total" && { comment: values.comment }),
    ...(values.sourceType === "total" && {
      source: values.source,
      sourceColor: values.sourceColor,
    }),
    equalTimings: values.timingType === "total",
    totalTiming: calcTotalTiming(values),
    design: values.design,
    blocks: values.items.map((block) => ({
      ...(values.titleType === "separate" && { title: block.title }),
      ...(values.commentType === "separate" && { comment: block.comment }),
      ...(values.sourceType === "separate" && {
        source: block.source,
        sourceColor: block.sourceColor,
      }),
      ...(values.timingType === "separate" && { timing: block.timing }),
      image: block.image,
    })),
  };
}

function SlideShowForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    isValid,
    isSubmitting,
  } = formik;

  const totalTiming = useMemo(
    () =>
      values.timingType === "total"
        ? values.timing
        : values.items.reduce((acc, item) => acc + item.timing, 0),
    [values.items, values.timing, values.timingType]
  );

  usePreview(channel, template, totalTiming, values, prepareData);


  const buttonProps = {
    isValid,
    isSubmitting,
    prepareData,
    values,
    onSaveDraft,
    onDeleteDraft
  }
  return (
    <FormikProvider value={formik}>
      <Form>
        <TextField
          label="Название ролика"
          name="clipName"
          value={values.clipName}
          onChange={handleChange}
          onBlur={handleBlur}
          {...getValidationProps("clipName", touched, errors)}
        />
        <Field
          as={Select}
          label="Оформление"
          name="design"
          options={designOptions}
        />
        <Field
          as={Select}
          name="format"
          label="Формат"
          options={[
            { id: "default", name: "По умолчанию" },
            { id: "premiere", name: "Premiere" },
          ]}
        />
        <Select
          label="Хронометраж"
          name="timingType"
          options={selectOptions}
          value={values.timingType}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {values.timingType === "total" && (
          <TextField
            label="Общий хронометраж (сек.)"
            name="timing"
            type="number"
            value={values.timing.toString()}
            onChange={(event) => setFieldValue("timing", +event.target.value)}
            onBlur={handleBlur}
            isValid={!touched.timing || !errors.timing}
            validationMessage={errors.timing}
          />
        )}

        <Select
          label="Заголовок"
          name="titleType"
          options={selectOptions}
          value={values.titleType}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {values.titleType === "total" && (
          <TextArea
            label="Общий заголовок"
            name="title"
            value={values.title}
            onChange={(event) =>
              setFieldValue("title", event.target.value.toUpperCase())
            }
            onBlur={handleBlur}
            rows={2}
          />
        )}

        <Select
          label="Комментарий"
          name="commentType"
          options={selectOptions}
          value={values.commentType}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {values.commentType === "total" && (
          <TextArea
            label="Общий комментарий"
            name="comment"
            value={values.comment}
            onChange={(event) =>
              setFieldValue("comment", event.target.value.toUpperCase())
            }
            onBlur={handleBlur}
            isValid={!touched.comment || !errors.comment}
            validationMessage={errors.comment}
          />
        )}

        <Select
          label="Источник"
          name="sourceType"
          options={selectOptions}
          value={values.sourceType}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {values.sourceType === "total" && (
          <>
            <TextField
              label="Общий источник"
              name="source"
              value={values.source}
              onChange={(event) =>
                setFieldValue("source", event.target.value.toUpperCase())
              }
              onBlur={handleBlur}
            />
            <Select
              label="Цвет источника"
              name="sourceColor"
              options={[
                { id: "gray", name: "Серый" },
                { id: "white", name: "Белый" },
              ]}
              value={values.sourceColor}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </>
        )}

        <Blocks
          items={values.items}
          updateItems={(items) => setFieldValue("items", items)}
          defaultItemConstructor={createItem}
          canChangeLength
        >
          {(item, index, updateItem) => (
            <>
              <ImageInput
                imageTypes={IMAGE_TYPES}
                image={item.image}
                updateImage={(image) => {
                  setFieldTouched(`items.${index}.image`);
                  updateItem({ image });
                }}
                isValid={
                  !touched.items?.[index]?.image ||
                  !(errors.items?.[index] as FormikErrors<Item>)?.image
                }
                validationMessage={
                  (errors.items?.[index] as FormikErrors<Item>)?.image
                }
              />
              {values.titleType === "separate" && (
                <TextArea
                  placeholder="Заголовок"
                  name="title"
                  value={item.title}
                  onChange={(event) =>
                    updateItem({ title: event.target.value.toUpperCase() })
                  }
                  onBlur={handleBlur}
                  rows={2}
                />
              )}
              {values.commentType === "separate" && (
                <TextArea
                  name="comment"
                  placeholder="Комментарий"
                  value={item.comment}
                  onChange={(event) =>
                    updateItem({ comment: event.target.value.toUpperCase() })
                  }
                  onBlur={handleBlur}
                  isValid={
                    !touched.items?.[index]?.comment ||
                    !(errors.items?.[index] as FormikErrors<Item>)?.comment
                  }
                  validationMessage={
                    (errors.items?.[index] as FormikErrors<Item>)?.comment
                  }
                />
              )}
              {values.sourceType === "separate" && (
                <>
                  <TextArea
                    name="source"
                    placeholder="Источник"
                    value={item.source}
                    onChange={(event) =>
                      updateItem({ source: event.target.value.toUpperCase() })
                    }
                    onBlur={handleBlur}
                    rows={2}
                  />
                  <Select
                    name="sourceColor"
                    options={[
                      { id: "gray", name: "Серый" },
                      { id: "white", name: "Белый" },
                    ]}
                    value={item.sourceColor}
                    onChange={(event) =>
                      updateItem({ sourceColor: event.target.value })
                    }
                    onBlur={handleBlur}
                    placeholder="Цвет источника"
                  />
                </>
              )}
              {values.timingType === "separate" && (
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <div style={{ marginRight: 20 }}>Хронометраж (сек.)</div>
                  <TextField
                    name="timing"
                    type="number"
                    value={item.timing.toString()}
                    onChange={(event) => {
                      updateItem({ timing: +event.target.value });
                    }}
                    onBlur={handleBlur}
                    isValid={
                      !touched.items?.[index]?.timing ||
                      !(errors.items?.[index] as FormikErrors<Item>)?.timing
                    }
                    validationMessage={
                      (errors.items?.[index] as FormikErrors<Item>)?.timing
                    }
                    style={{ width: "100%" }}
                  />
                </div>
              )}
            </>
          )}
        </Blocks>

        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(SlideShowForm);
