import Button from "emg-ui-kit/components/Button";
import Title from "emg-ui-kit/components/Title";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import List from "../common/List";
import PageContainer from "../common/PageContainer";
import { Table } from "../common/Table";
import { useIsDesktop } from "../common/utils";
import Spinner from "../order/atmosphere/components/Spinner";
import { getPersonsThunk } from "../redux/persons";
import { selectPersons } from "../redux/persons/selectors";
import routes from "../routes";
import styles from "./UserList.module.css";

export default function PersonList() {
  const { persons, isLoading } = useSelector(selectPersons);
  const dispatch = useDispatch();
  const history = useHistory();
  const isDesktop = useIsDesktop();

  useEffect(() => {
    dispatch(getPersonsThunk());
  }, [dispatch]);

  if (isLoading || !persons) return <Spinner />;
  return (
    <PageContainer className={styles.pageContainer}>
      <Title text="Фотографии" />
      <Button
        title="Новая запись"
        style={{ marginLeft: 0, marginBottom: 20 }}
        onClick={() => history.push(routes.personsNew)}
      />
      {isDesktop ? (
        <Table
          items={persons}
          displayProps={[
            {
              name: "ID",
              selector: "id",
            },
            {
              name: "Имя",
              selector: "name",
            },
            {
              name: "Должность",
              selector: (person) => person.position || "-",
            },
          ]}
          order="asc"
          changeOrder={() => {}}
          idPropName="id"
          onItemClick={(person) =>
            history.push(`${routes.persons}/${person.id}`)
          }
        />
      ) : (
        <List
          items={persons.map((person) => ({
            id: person.id,
            title: person.name,
          }))}
          urlBase={routes.persons}
        />
      )}
    </PageContainer>
  );
}
