import ImageInput, { FormImage } from "emg-ui-kit/components/ImageInput";
import { SingleSearch } from "emg-ui-kit/components/Search";
import { Option } from "emg-ui-kit/components/Search/Search";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { Field, FormikProvider, useFormik, Form } from "formik";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import { convertImageUrlToFile, useIsDesktop } from "../../common/utils";
import yup from "../../common/yup";
import usePersons from "../usePersons";
import usePreview from "../usePreview";
import { CLIP_NAME_REGEXP, IMAGE_TYPES, ValidationPropsUtils } from "../util";

export default function WheezeSpecrepForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const isDesktop = useIsDesktop();

  const { persons, person, handleUpdatePerson } = usePersons("photoM24");

  const personsOptions = useMemo(
    () => persons?.map((person, id) => ({ id, value: person.name })) ?? [],
    [persons]
  );

  const dispatch = useDispatch();

  const IMAGE_ASPECT = 1;

  const initialValues = {
    clipName: (initialFormData?.clipName ?? "") as string,
    design: (initialFormData?.design ?? "green") as "green" | "red",
    communicationType: (initialFormData?.communicationType ?? "") as string,
    image: initialFormData?.image as FormImage | undefined,
    name: (initialFormData?.name ?? "") as string,
    position: (initialFormData?.position ?? "") as string,
    timing: (initialFormData?.timing ?? 30) as number,
  };

  const validationSchema = yup.object().shape({
    clipName: yup.string().matches(CLIP_NAME_REGEXP),
    name: yup.mixed().required(),
    communicationType: yup.mixed().required(),
    image: yup.mixed().aspect(IMAGE_ASPECT),
    timing: yup.number().positive(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    onSubmit,
  });

  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors
  );

  const handleImageUpdate = (image?: FormImage) => {
    formik.setFieldTouched("image");
    formik.setFieldValue("image", image);
  };

  const handleSelect = (option?: Option) => {
    handleUpdatePerson(option);
    const person = persons?.find((person) => person.name === option?.value);
    formik.setFieldValue("name", person?.name ?? "");
    formik.setFieldValue("position", person?.position ?? "");
    if (person) {
      convertImageUrlToFile(person.photoM24)
        .then(handleImageUpdate)
        .catch((err) => {
          dispatch({ type: "CONVERSION_ERROR", error: err });
        });
    } else {
      handleImageUpdate(undefined);
    }
  };

  const buttonProps = {
    isSubmitting: formik.isSubmitting,
    isValid: formik.isValid,
    values: formik.values,
    onSubmit,
    onSaveDraft,
    onDeleteDraft,
  };

  usePreview(channel, template, formik.values.timing, formik.values);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          name="clipName"
          type="text"
          label="Название ролика"
          {...validationUtils.getProps("clipName")}
        />
        <Field
          as={Select}
          name="design"
          label="Оформление"
          options={[
            { id: "green", name: "Зеленое" },
            { id: "red", name: "Красное" },
          ]}
        />
        <TextField
          label="Вид связи"
          name="communicationType"
          value={formik.values.communicationType}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          {...validationUtils.getProps("communicationType")}
          dataList={["ПО ТЕЛЕФОНУ", "SKYPE"]}
          required
        />
        <ImageInput
          imageTypes={IMAGE_TYPES}
          image={formik.values.image}
          updateImage={(image) => {
            formik.setFieldTouched("image");
            formik.setFieldValue("image", image);
          }}
          style={isDesktop ? { marginLeft: 210 } : {}}
          title="Изображение"
          {...validationUtils.getProps("image")}
          aspect={IMAGE_ASPECT}
        />
        <SingleSearch
          label="Выбрать из базы"
          selected={person}
          updateSelected={handleSelect}
          options={personsOptions}
        />
        <Field
          as={TextArea}
          name="name"
          type="text"
          label="Имя"
          {...validationUtils.getProps("name")}
          rows={2}
          required
        />
        <Field
          as={TextArea}
          name="position"
          type="text"
          label="Должность"
          rows={2}
        />
        <Field
          as={TextField}
          name="timing"
          label="Хронометраж (сек.)"
          type="number"
          {...validationUtils.getProps("timing")}
        />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}
