import Blocks from "emg-ui-kit/components/Blocks";
import Button from "emg-ui-kit/components/Button";
import FileInput from "emg-ui-kit/components/FileInput";
import ImageInput from "emg-ui-kit/components/ImageInput";
import { FormImage } from "emg-ui-kit/components/ImageInput";
import { SingleSearch } from "emg-ui-kit/components/Search";
import { Option } from "emg-ui-kit/components/Search/Search";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { Field, FormikErrors, FormikProvider, useFormik } from "formik";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import {
  convertImageUrlToFile,
  convertUrlToFile,
  useIsDesktop,
} from "../../common/utils";
import Form from "../Form";
import usePersons from "../usePersons";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  getValidationProps,
  IMAGE_TYPES,
  MAX_TIMING,
  pick,
  removeEmptyProps,
  validateAspect,
  validateIncorrectFormat,
  validateMax,
  validateNotEmpty,
  validatePositive,
  validateText,
  validateTimingSum,
} from "../util";
import { designOptions } from "./constants";

const MAX_LINES = 7;
const MAX_CHARS = 25;
const MAX_CHARS_NO_PHOTO = 45;
const IMAGE_ASPECT = 512 / 740;

type Item = { quote: string; timing: number };

function createItem(): Item {
  return { quote: "", timing: 0 };
}

function initItems(count = 1) {
  return Array.from(Array(count), createItem);
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    image: initialFormData?.image as FormImage | undefined,
    audio: undefined as File | undefined,
    name: (initialFormData?.name ?? "") as string,
    position: (initialFormData?.position ?? "") as string,
    source: (initialFormData?.source ?? "") as string,
    timingType: initialFormData?.equalTimings ? "total" : "separate",
    timing: (initialFormData?.equalTimings
      ? initialFormData.totalTiming
      : 0) as number,
    items: (initialFormData?.blocks ?? initItems()) as Item[],
    format: (initialFormData?.format ?? "default") as "default" | "premiere",
    design: (initialFormData?.design ?? "news") as "specrep" | "news",
  };
}

type Values = ReturnType<typeof getInitialValues>;

function validate(values: Values) {
  const errors = {
    clipName: values.clipName
      ? validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP)
      : undefined,
    timing:
      values.timingType === "total"
        ? validatePositive(values.timing) ??
          validateMax(values.timing, MAX_TIMING)
        : undefined,
    items: values.items.map((item) => ({
      timing:
        values.timingType === "separate"
          ? validatePositive(item.timing) ??
            validateTimingSum(calcTotalTiming(values), MAX_TIMING)
          : undefined,
    })),
    image: values.image
      ? validateAspect(values.image, IMAGE_ASPECT)
      : undefined,
  };
  return removeEmptyProps(errors);
}

function calcTotalTiming(values: Values) {
  return values.timingType === "total"
    ? values.timing
    : values.items.reduce((acc, item) => acc + item.timing, 0);
}

function prepareData(values: Values) {
  return {
    ...pick(
      values,
      "clipName",
      "image",
      "audio",
      "name",
      "position",
      "source",
      "format"
    ),
    equalTimings: values.timingType === "total",
    totalTiming: calcTotalTiming(values),
    blocks: values.items,
    design: values.design,
  };
}

function prepareDataForPreview(values: Values) {
  const previewData = prepareData(values);
  delete previewData.audio;
  return previewData;
}

function QuoteForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    isValid,
    isSubmitting,
  } = formik;

  usePreview(
    channel,
    template,
    calcTotalTiming(values),
    values,
    prepareDataForPreview
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (initialFormData?.audio) {
      convertUrlToFile(initialFormData?.audio)
        .then((file) => {
          setFieldValue("audio", file);
        })
        .catch((err) => {
          dispatch({ type: "CONVERSION_ERROR", error: err });
        });
    }
  }, [setFieldValue, initialFormData?.audio, dispatch]);

  const { persons, person, handleUpdatePerson } = usePersons("photoM24");

  const personsOptions = useMemo(
    () => persons?.map((person, id) => ({ id, value: person.name })) ?? [],
    [persons]
  );

  const handleImageUpdate = (image?: FormImage) => {
    formik.setFieldTouched("image");
    formik.setFieldValue("image", image);
  };

  const handleSelect = (option?: Option) => {
    handleUpdatePerson(option);
    const person = persons?.find((person) => person.name === option?.value);
    formik.setFieldValue("name", person?.name ?? "");
    formik.setFieldValue("position", person?.position ?? "");
    if (person) {
      convertImageUrlToFile(person.photoM24)
        .then(handleImageUpdate)
        .catch((err) => {
          dispatch({ type: "CONVERSION_ERROR", error: err });
        });
    } else {
      handleImageUpdate(undefined);
    }
  };

  const breakLines = () => {
    const lineLimit = values.image ? 25 : 45;
    const selectionRegExp = /<\||\|>/g;
    const nextItems = values.items.map((item) => {
      const lines = [""];
      let lastLineIndex = 0;
      const words = item.quote.replace(/\n/g, " ").split(" ");
      for (const word of words) {
        const lastLine = lines[lastLineIndex];
        const lastLineLength = lastLine.replace(selectionRegExp, "").length;
        const wordLength = word.replace(selectionRegExp, "").length;
        if (lastLineLength + wordLength + 1 <= lineLimit) {
          lines[lastLineIndex] = lastLine + (lastLine ? " " : "") + word;
        } else {
          lines.push(word);
          lastLineIndex++;
        }
      }
      const quote = lines.join("\n");
      return { ...item, quote };
    });
    setFieldValue("items", nextItems);
  };

  const getQuoteRecommendation = (quote: string): string | undefined => {
    const ignore = [/<\|/, /\|>/];
    return (
      validateNotEmpty(quote) ??
      (values.image
        ? validateText(quote, MAX_LINES, MAX_CHARS, ignore)
        : validateText(quote, MAX_LINES, MAX_CHARS_NO_PHOTO, ignore))
    );
  };

  const positionRecommendation = values.position
    ? values.image
      ? values.name.length >= 18
        ? validateText(values.position, 2, 30)
        : validateText(values.position, 4, 30)
      : validateText(values.position, 2, 55)
    : undefined;

  const isDesktopOrLaptop = useIsDesktop();

  const buttonProps = {
    isValid,
    isSubmitting,
    prepareData,
    values,
    onSaveDraft,
    onDeleteDraft,
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <TextField
          label="Название ролика"
          name="clipName"
          value={values.clipName}
          onChange={handleChange}
          onBlur={handleBlur}
          {...getValidationProps("clipName", touched, errors)}
        />

        <Field
          as={Select}
          label="Оформление"
          name="design"
          options={designOptions}
        />
        <Field
          as={Select}
          name="format"
          label="Формат"
          options={[
            { id: "default", name: "По умолчанию" },
            { id: "premiere", name: "Premiere" },
          ]}
        />
        <SingleSearch
          label="Выбрать из базы"
          selected={person}
          updateSelected={handleSelect}
          options={personsOptions}
        />
        <ImageInput
          imageTypes={IMAGE_TYPES}
          image={values.image}
          updateImage={(image) => {
            setFieldTouched("image");
            setFieldValue("image", image);
          }}
          style={isDesktopOrLaptop ? { marginLeft: 210 } : {}}
          title="Изображение"
          aspect={IMAGE_ASPECT}
          isValid={!touched.image || !errors.image}
          validationMessage={errors.image}
        />
        <TextField
          label="Имя"
          name="name"
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <TextArea
          rows={2}
          label="Должность"
          name="position"
          value={values.position}
          onChange={handleChange}
          onBlur={handleBlur}
          recommendationMessage={positionRecommendation}
        />
        <FileInput
          title="Аудиодорожка"
          accept="audio/*"
          files={values.audio ? [values.audio] : []}
          handleAcceptedFiles={(files) => {
            setFieldValue("audio", files[files.length - 1]);
          }}
          showAlert={() => {}}
          style={isDesktopOrLaptop ? { marginLeft: 210 } : {}}
        />
        <TextField
          label="Источник"
          name="source"
          value={values.source}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <Select
          name="timingType"
          label="Хронометраж"
          options={[
            { id: "total", name: "Общий" },
            { id: "separate", name: "Для каждой страницы" },
          ]}
          value={values.timingType}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {values.timingType === "total" && (
          <TextField
            name="timing"
            type="number"
            label="Общий хронометраж (сек.)"
            value={values.timing.toString()}
            onChange={(event) => setFieldValue("timing", +event.target.value)}
            onBlur={handleBlur}
            isValid={!touched.timing || !errors.timing}
            validationMessage={errors.timing}
          />
        )}

        <Button title="Разбить на строки" onClick={breakLines} />
        <Blocks
          items={values.items}
          updateItems={(items) => setFieldValue("items", items)}
          canChangeLength
          defaultItemConstructor={createItem}
        >
          {(item, index, updateItem) => (
            <>
              <TextArea
                name="quote"
                placeholder="Цитата *"
                value={item.quote}
                onChange={(event) => updateItem({ quote: event.target.value })}
                recommendationMessage={getQuoteRecommendation(item.quote)}
              />
              {values.timingType === "separate" && (
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <div style={{ marginRight: 20 }}>Хронометраж (сек.)</div>
                  <TextField
                    name="timing"
                    type="number"
                    value={item.timing.toString()}
                    onChange={(event) =>
                      updateItem({ timing: +event.target.value })
                    }
                    isValid={
                      !touched.items ||
                      !(errors.items?.[index] as FormikErrors<Item>)?.timing
                    }
                    validationMessage={
                      (errors.items?.[index] as FormikErrors<Item>)?.timing
                    }
                    style={{ width: "100%" }}
                  />
                </div>
              )}
            </>
          )}
        </Blocks>

        <br />

        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(QuoteForm);
