import AlertBar from "emg-ui-kit/components/Alert";
import Spinner from "emg-ui-kit/components/Spinner";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import AtmoDataSync from "../admin/AtmoDataSync";
import NewsCreate from "../admin/NewsCreate";
import NewsEdit from "../admin/NewsEdit";
import NewsList from "../admin/NewsList";
import NotificationSettings from "../admin/NotificationSettings";
import PersonChangeForm from "../admin/PersonChangeForm";
import PersonList from "../admin/PersonList";
import PersonNewForm from "../admin/PersonNewForm";
import UserChangeForm from "../admin/UserChangeForm";
import UserList from "../admin/UserList";
import UserNewForm from "../admin/UserNewForm";
import Alert from "../common/Alert";
import FlexContainer from "../common/FlexContainer";
import { useShouldRenderTable } from "../common/utils";
import LoginForm from "../login/LoginForm";
import RegisterForm from "../login/RegisterForm";
import OrderForm from "../order/OrderForm";
import { checkAuthenticationThunk } from "../redux/auth";
import { selectAuth } from "../redux/auth/selectors";
import { getNotificationThunk } from "../redux/notifications";
import {
  initNotificationTracking,
  stopNotificationTracking,
} from "../redux/notifications/actions";
import { selectNotification } from "../redux/notifications/selectors";
import routes from "../routes";
import DraftListPage from "../tasks/DraftListPage/DraftListPage";
import DraftTablePage from "../tasks/DraftTablePage/DraftTablePage";
import TaskListPage from "../tasks/TaskListPage";
import Task from "../tasks/TaskPage";
import TaskTablePage from "../tasks/TaskTablePage";
import AdmRoute from "./AdmRoute";
import "./App.css";
import Header from "./Header";
import News from "./News";

export default function App() {
  const dispatch = useDispatch();
  const { userInfo, isLoading } = useSelector(selectAuth);
  const { notification } = useSelector(selectNotification);
  const shouldRenderTable = useShouldRenderTable();

  useEffect(() => {
    dispatch(checkAuthenticationThunk());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getNotificationThunk());
    dispatch(initNotificationTracking());
    return () => {
      dispatch(stopNotificationTracking());
    };
  }, [dispatch]);

  return (
    <div className="app">
      <Header />
      {notification && notification.category !== "none" && (
        <AlertBar
          message={notification.message}
          level={notification.category}
        />
      )}
      {userInfo ? (
        <Switch>
          <Redirect from={routes.signup} to={routes.tasksList} />
          <AdmRoute
            path={routes.notifications}
            component={NotificationSettings}
          />
          <AdmRoute path={routes.atmoDataSync} component={AtmoDataSync} />
          <AdmRoute path={routes.usersNew} component={UserNewForm} />
          <AdmRoute path={routes.usersUpdate} component={UserChangeForm} />
          <AdmRoute path={routes.users} component={UserList} />
          <AdmRoute path={routes.personsNew} component={PersonNewForm} />
          <AdmRoute path={routes.personsUpdate} component={PersonChangeForm} />
          <AdmRoute path={routes.persons} component={PersonList} />
          <AdmRoute path={routes.newsArticlesNew} component={NewsCreate} />
          <AdmRoute path={routes.newsArticlesUpdate} component={NewsEdit} />
          <AdmRoute path={routes.newsArticles} component={NewsList} />
          <Route path={routes.newsArticlesView} component={News} />
          <Route path={routes.tasksNew} component={OrderForm} />
          <Route path={routes.tasksView} component={Task} />
          <Route path={routes.draft} component={OrderForm} />

          <Route
            path={routes.drafts}
            component={shouldRenderTable ? DraftTablePage : DraftListPage}
          />
          <Route
            path={[routes.tasksList, routes.index]}
            component={shouldRenderTable ? TaskTablePage : TaskListPage}
          />
        </Switch>
      ) : isLoading ? (
        <FlexContainer center>
          <Spinner />
        </FlexContainer>
      ) : (
        <Switch>
          <Route path={routes.signup} component={RegisterForm} />
          <Route path={routes.fallback} component={LoginForm} />
        </Switch>
      )}
      <Alert />
    </div>
  );
}
