import TextField from "emg-ui-kit/components/TextField";
import { useFormikContext, Field } from "formik";
import React from "react";

import { getItemFieldName, ValidationPropsUtils } from "../../../util";
import { PressureItem } from "../../types";

interface Props {
  item: PressureItem;
  index: number;
}

export default function PressureBlock({ index }: Props) {
  const formik = useFormikContext();
  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors
  );
  const pressureName = getItemFieldName(index, "pressure");
  return (
    <Field
      as={TextField}
      type="number"
      placeholder="Данные"
      name={pressureName}
      {...validationUtils.getDeepProps(pressureName)}
    />
  );
}
