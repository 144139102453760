import Title from "emg-ui-kit/components/Title";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PageContainer from "../../common/PageContainer";
import { restoreFilterParams } from "../tasksUtils";

import { useTasksTracking } from "../useTasksTracking";
import CreateTaskButton from "../TaskListPage/CreateTaskButton";

import { selectDrafts } from "../../redux/drafts/selectors";
import DraftList from "./DraftList";
import { getDraftsThunk } from "../../redux/drafts";
import useDeleteDraft from '../useDeleteDraft'
import { AppDispatch } from "../../store";

export default function DraftListPage() {
    const [params, setParams] = useState(restoreFilterParams);
    const { drafts, isLoading } = useSelector(selectDrafts);

    const loadNext = useCallback(() => {
        setParams((params) => ({ ...params, page: params.page + 1 }));
    }, []);

    const dispatch = useDispatch<AppDispatch>();

    const { handleDeleteDraft, isDisabled } = useDeleteDraft(() => dispatch(getDraftsThunk(params)))

    useEffect(() => {
        dispatch(getDraftsThunk(params));
    }, [dispatch, params]);

    useTasksTracking(params);

    return (
        <PageContainer>
            <Title text="Черновики" />
            <DraftList tasks={drafts} isLoading={isLoading} loadNext={loadNext} onDeleteDraftClick={handleDeleteDraft} isDisabled={isDisabled} />
            <CreateTaskButton />
        </PageContainer>
    );
}
