import Spinner from "emg-ui-kit/components/Spinner";
import React from "react";
import { Link } from "react-router-dom";

import { Task } from "../../common/models";
import useCallbackOnVisible from "../../common/useCallbackOnVisible";
import routes from "../../routes";
import styles from "./TaskList.module.css";
import TaskRow from "./TaskRow";

interface Props {
  tasks: Task[];
  loadNext: () => void;
  isLoading: boolean;
}

export default function TaskList({ tasks, loadNext, isLoading }: Props) {
  const callbackRef = useCallbackOnVisible(loadNext);
  return (
    <>
      {tasks.map((task, idx) => (
        <Link
          to={`${routes.tasksList}/${task._id}`}
          key={task._id}
          className={styles.taskLink}
        >
          <TaskRow
            task={task}
            ref={idx === tasks.length - 1 ? callbackRef : null}
          />
        </Link>
      ))}
      <div className={styles.listStatusContainer}>
        {isLoading ? (
          <Spinner />
        ) : (
          !tasks.length && <span className={styles.noOrders}>Нет заказов</span>
        )}
      </div>
    </>
  );
}
