import { SingleSearch } from "emg-ui-kit/components/Search";
import { Option } from "emg-ui-kit/components/Search/Search";
import Select from "emg-ui-kit/components/Select";
import { useFormikContext } from "formik";
import React from "react";

import ColorInput from "../../common/ColorInput";
import { ValidationPropsUtils } from "../util";
import { Values } from "./MapForm";
import { countries } from "./countries";
import { CountryItem } from "./types";
import { countOptions, createCountry } from "./util";

const countryOptions = countries.map((value) => ({ id: value, value }));

interface Props {
  item: CountryItem;
  index: number;
}

export default function CountryItemBlock({ item, index }: Props) {
  const formik = useFormikContext<Values>();
  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors
  );

  const handleCountChange = (event: React.ChangeEvent<{ value: string }>) => {
    const nextLen = +event.target.value;
    const currentLen = item.countries.length;
    const nextCountries =
      nextLen < currentLen
        ? item.countries.slice(0, nextLen)
        : item.countries.concat(
            Array.from(Array(nextLen - currentLen), createCountry)
          );
    formik.setFieldValue(`items.${index}.countries`, nextCountries);
  };

  const isFirstCountryInList = (countryIndex: number) => countryIndex === 0;

  const getSelectedCountryOption = (countryIndex: number) => {
    const countryCurrent = (formik.values.items[index] as CountryItem)
      .countries[countryIndex].name;
    return countryCurrent
      ? { id: countryCurrent, value: countryCurrent }
      : undefined;
  };

  const getColorChangeHandler = (countryIndex: number) => (color: string) => {
    formik.setFieldValue(
      `items.${index}.countries.${countryIndex}.color`,
      color
    );
  };

  const getCountryChangeHandler =
    (countryIndex: number) => (option?: Option) => {
      formik.setFieldValue(
        `items.${index}.countries.${countryIndex}.name`,
        option?.value ?? ""
      );
    };

  return (
    <>
      <Select
        placeholder="Количество"
        options={countOptions}
        value={item.countries.length.toString()}
        onChange={handleCountChange}
      />
      {item.countries.map((country, countryIndex) => (
        <section key={countryIndex}>
          {isFirstCountryInList(countryIndex) && <hr />}
          <SingleSearch
            placeholder="Название страны"
            options={countryOptions}
            selected={getSelectedCountryOption(countryIndex)}
            updateSelected={getCountryChangeHandler(countryIndex)}
            {...validationUtils.getDeepProps(
              `items.${index}.countries.${countryIndex}.name`
            )}
          />
          <ColorInput
            label="Цвет"
            value={country.color}
            updateValue={getColorChangeHandler(countryIndex)}
            labelStyle={{ flexBasis: 70 }}
          />
          <hr />
        </section>
      ))}
    </>
  );
}
