import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";

import {
  getNotification,
  Notification,
  updateNotification,
} from "../../common/ApiService";

export interface NotificationState {
  notification?: Notification;
  isLoading: boolean;
  isUpdating: boolean;
}

const initialState: NotificationState = {
  isLoading: false,
  isUpdating: false,
};

export const getNotificationThunk = createAsyncThunk(
  "notification/get",
  getNotification
);

export const updateNotificationThunk = createAsyncThunk(
  "notification/update",
  updateNotification
);

const notificationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getNotificationThunk.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getNotificationThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notification = action.payload;
    })
    .addCase(getNotificationThunk.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(updateNotificationThunk.pending, (state) => {
      state.isUpdating = true;
    })
    .addCase(updateNotificationThunk.fulfilled, (state, action) => {
      state.isUpdating = false;
      state.notification = action.payload;
    })
    .addCase(updateNotificationThunk.rejected, (state) => {
      state.isUpdating = false;
    });
});

export default notificationReducer;
