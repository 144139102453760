import Button from "emg-ui-kit/components/Button";
import Select from "emg-ui-kit/components/Select";
import Title from "emg-ui-kit/components/Title";

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCsv } from "../common/ApiService";

import PageContainer from "../common/PageContainer";
import { getAtmoParamsThunk, updateAtmoParamsThunk } from "../redux/atmoParams";
import { selectAtmoParams } from "../redux/atmoParams/selectors";

export default function AtmoDataSync() {
  const dispatch = useDispatch();
  const { atmoParams, isLoading, isUpdating } = useSelector(selectAtmoParams);
  const [isCsvLoading, setCsvIsLoading] = useState(false)
  const [months, setMonths] = useState('1')

  const handleDownload = useCallback(async () => {
    setCsvIsLoading(true)
    const url = await getCsv(months);
    setCsvIsLoading(false)
    
    const a = document.createElement("a");
    a.href = url
    a.target = "_blank";
    a.download = url.split('/').pop()!
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, [months])

  useEffect(() => {
    dispatch(getAtmoParamsThunk());
  }, [dispatch]);

  const handleSync = () => {
    dispatch(updateAtmoParamsThunk());
  };

  return (
    <PageContainer>
      <Title text="Синхронизация данных Атмосферы" />
      <div>Дата последней синхронизации:</div>
      <div>
        {atmoParams
          ? new Date(atmoParams.updated).toLocaleString()
          : "Загрузка..."}
      </div>
      <br />
      <Button
        title="Обновить"
        status={isLoading ? "disabled" : isUpdating ? "loading" : "enabled"}
        onClick={handleSync}
      />
      <br />

      <Title text="Скачивание CSV с заказами Атмосферы" />
      <div>Для скачивания выберите период и нажмите кнопку скачать:</div>
      <br />
      <Select
        name="period"
        label="Период"
        options={[
          { id: "1", name: "1 месяц" },
          { id: "3", name: "3 месяца" },
          { id: "6", name: "6 месяцев" },
        ]}
        value={months}
        onChange={(event) => setMonths(event.target.value)}
      />

      <Button
        title="Скачать"
        status={isCsvLoading ? "loading" : "enabled"}
        onClick={handleDownload}
      />
    </PageContainer>
  );
}
