import { configureStore } from "@reduxjs/toolkit";

import atmoParamsReducer from "./redux/atmoParams";
import authReducer from "./redux/auth";
import authMiddleware from "./redux/auth/middleware";
import backupReducer from "./redux/backup";
import draftReducer from "./redux/drafts";
import emailParamsReducer from "./redux/emailParams";
import errorReducer from "./redux/error/reducer";
import newsReducer from "./redux/news";
import notificationReducer from "./redux/notifications";
import { trackNotificationMiddleware } from "./redux/notifications/middleware";
import personsReducer from "./redux/persons";
import tasksReducer from "./redux/tasks";
import {
  trackTaskByIdMiddleware,
  trackTasksMiddleware,
} from "./redux/tasks/middleware";
import usersReducer from "./redux/users";

const store = configureStore({
  reducer: {
    auth: authReducer,
    errors: errorReducer,
    tasks: tasksReducer,
    users: usersReducer,
    atmoParams: atmoParamsReducer,
    emailParams: emailParamsReducer,
    notifications: notificationReducer,
    persons: personsReducer,
    backup: backupReducer,
    news: newsReducer,
    drafts: draftReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      authMiddleware,
      trackTasksMiddleware,
      trackTaskByIdMiddleware,
      trackNotificationMiddleware,
    ]),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
