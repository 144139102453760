import Button from "emg-ui-kit/components/Button";
import Title from "emg-ui-kit/components/Title";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import List from "../common/List";
import PageContainer from "../common/PageContainer";
import { Table } from "../common/Table";
import { useIsDesktop } from "../common/utils";
import Spinner from "../order/atmosphere/components/Spinner";
import { getUsersThunk } from "../redux/users";
import { selectUsers } from "../redux/users/selectors";
import routes from "../routes";
import styles from "./UserList.module.css";
import { groups, userTypes } from "./userConstants";

export default function UserList() {
  const { users, isLoading } = useSelector(selectUsers);
  const dispatch = useDispatch();
  const history = useHistory();
  const isDesktop = useIsDesktop();

  useEffect(() => {
    dispatch(getUsersThunk());
  }, [dispatch]);

  if (isLoading && !users.length) return <Spinner />;
  return (
    <PageContainer className={styles.pageContainer}>
      <Title text="Пользователи" />
      <Button
        title="Новый пользователь"
        style={{ marginLeft: 0, marginBottom: 20 }}
        onClick={() => history.push(routes.usersNew)}
      />
      {isDesktop ? (
        <Table
          items={users}
          displayProps={[
            {
              name: "ID",
              selector: "id",
            },
            {
              name: "Email",
              selector: "email",
            },
            {
              name: "Тип",
              selector: (user) => userTypes[user.type],
            },
            {
              name: "Группы",
              selector: (user) =>
                user.groups.length ? groups[user.groups[0]] : "Все",
            },
          ]}
          order="asc"
          changeOrder={() => {}}
          idPropName="id"
          onItemClick={(user) => history.push(`${routes.users}/${user.id}`)}
        />
      ) : (
        <List
          items={users.map((user) => ({ id: user.id, title: user.email }))}
          urlBase={routes.users}
        />
      )}
    </PageContainer>
  );
}
