import Title from "emg-ui-kit/components/Title";
import React from "react";

import PageContainer from "../common/PageContainer";
import { useNewsList } from "../redux/news/useNews";
import styles from "./News.module.css";

const dateFormatter = new Intl.DateTimeFormat("ru");

export default function News() {
  const news = useNewsList();
  return (
    <PageContainer>
      <Title text="Новости" />
      {news.map((article) => (
        <article id={article._id} className={styles.article}>
          <div className={styles.date}>
            {dateFormatter.format(new Date(article.date))}
          </div>
          <div dangerouslySetInnerHTML={{ __html: article.content }} />
        </article>
      ))}
      {!news.length && <span>Новостей еще нет</span>}
    </PageContainer>
  );
}
