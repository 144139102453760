import React from "react";
import { Link } from "react-router-dom";

import routes from "../../routes";
import styles from "./TaskList.module.css";

export default function CreateTaskButton() {
  return (
    <Link to={routes.tasksNew}>
      <button className={styles.plusButton} />
    </Link>
  );
}
